import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import localforage from 'localforage';
import * as he from 'he';

import * as SkuSelectors from '../../selectors/sku';
import * as FurnitureSelectors from '../../selectors/furniture';

import { APIContext } from '../../context/APIContext';
import { actions as skuActions } from '../../reducers/sku';
import { useGetDimensions } from '../../hooks/useGetDimensions';

import {
  TRANSLATE_MODE,
  IMPORT_OPTIONS,
  ASSET_STORE_NAME,
} from '../../constants';

const OptionSelector = ({
  option,
  skus,
  setSelectionUiPos,
  selectedFurnitureRef,
  setFurnitureSize,
  isShortCeiling,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const skuOptions = useSelector(SkuSelectors.getSkuOptions);
  const furniture = useSelector(FurnitureSelectors.getById);
  const selectedFurnitureId = useSelector(
    FurnitureSelectors.getSelectedFurniture,
  );

  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const { getDimension } = useGetDimensions();

  const handleOptionClick = useCallback(
    (selectedValue) => {
      if (option.values.length <= 1) return;
      let tempSkusOpts = cloneDeep(skuOptions);

      // Adjust Selected Options Array
      tempSkusOpts.forEach((o) => {
        if (o.type.toUpperCase() === option.type) {
          o.value = selectedValue.text;
          o.id = selectedValue.id;
          setSelectedOption(selectedValue.id);
        }
      });

      // Find the selected SKU based on this change
      let thisSkuArr = skus.reduce((filtered, sku) => {
        let isMatch = true;
        tempSkusOpts.forEach((o) => {
          if (isMatch) {
            isMatch = sku.options.some((skuOpt) => skuOpt.id === o.id);
          }
        });
        if (isMatch) {
          filtered.push(sku);
        }
        return filtered;
      }, []);

      // If there isn't a SKU then find the first sku with the changed option
      if (thisSkuArr.length === 0) {
        const foundSku = skus.find((s) =>
          s.options.find((o) => o.id === selectedValue.id),
        );
        tempSkusOpts = foundSku.options;
        thisSkuArr.push(foundSku);
      }

      dispatch(skuActions.setSkuOptions(tempSkusOpts));
      const skuToSwap = thisSkuArr[0];
      if (api.selectedObject) {
        const selectObject = api.selectedObject;
        const rootObject = api.getRootObject(selectObject);
        const assetStore = localforage.createInstance({
          name: ASSET_STORE_NAME,
        });

        if (rootObject) {
          const searchGlbKey = rootObject.userData.isCube
            ? `BLANK_BLOB_FLOOR`
            : `glb-${skuToSwap.keyVal}`;
          const twoDLabel = skuToSwap.twoDLabel || skuToSwap.label;

          assetStore
            .getItem(searchGlbKey)
            .then((value) => {
              const url = URL.createObjectURL(value);
              api.loadModel(url, IMPORT_OPTIONS).then((object) => {
                //copy data out of existing model
                object.position.copy(rootObject.position);
                object.constraint = rootObject.constraint;
                object.rotation.copy(rootObject.rotation);
                object.userData = {
                  ...object.userData,
                  ...rootObject.userData,
                  sku: skuToSwap.skuNum,
                  skuKey: skuToSwap.keyVal,
                  map2dURL: skuToSwap.png,
                  twoDLabel,
                };

                const newRootObject = api.getRootObject(object);
                const p2d = api.getObjectBoundaryPoint(newRootObject, 'right');
                const selectionParams = {
                  transformControlParams: {
                    mode: TRANSLATE_MODE,
                    constraint: object.constraint,
                  },
                };

                if (rootObject.userData.isCube) {
                  const skuSize = skuToSwap.options.find(
                    (o) => o.type === 'SIZE',
                  );
                  if (skuSize) {
                    // Parse the string into vector3
                    const splitSizes = skuSize.value
                      .split(' x ')
                      .map((dim) => dim.slice(0, -1)); // Chop off the 'W', 'H', 'L' characters

                    if (splitSizes.length === 3)
                      object.scale.set(
                        Number(splitSizes[1]),
                        Number(splitSizes[2]),
                        Number(splitSizes[0]),
                      );
                  }
                }

                // add new model
                api.addObject(object);
                // remove old model
                api.removeObject(rootObject);
                // select new model
                api.selectObject(object, selectionParams);
                setSelectionUiPos({
                  top: p2d.y,
                  left: p2d.x,
                });
                selectedFurnitureRef.current = object;

                const thisFurniture =
                  furniture[rootObject.userData.furnitureId];

                const dimensions = getDimension(
                  api,
                  object,
                  skuToSwap,
                  thisFurniture.placementType,
                );
                setFurnitureSize(dimensions);
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    [
      dispatch,
      option,
      skuOptions,
      skus,
      api,
      setSelectionUiPos,
      selectedFurnitureRef,
      furniture,
      getDimension,
      setFurnitureSize,
    ],
  );

  useEffect(() => {
    const selectedType = skuOptions.find(
      (o) => o.type.toUpperCase() === option.type,
    );
    if (selectedType) {
      setSelectedOption(selectedType.id);
    }
  }, [skuOptions, option]);

  return (
    <div className="sidebar__subMenuWrapper--option" key={option.type}>
      <div>{he.decode(option.type)}</div>
      <div className="optionContainer">
        {option.values.map((selectVal) => {
          let label = selectVal.text;

          if (
            furniture[selectedFurnitureId]?.placementType?.includes(
              'CEILING',
            ) &&
            isShortCeiling &&
            selectVal.text === '60"'
          ) {
            return null;
          } else if (
            furniture[selectedFurnitureId]?.placementType?.includes(
              'CEILING',
            ) &&
            isShortCeiling
          ) {
            if (selectVal.text === '100"') {
              label = '80"';
            } else if (selectVal.text === '80"') {
              label = '60"';
            }
          } else if (
            furniture[selectedFurnitureId]?.placementType?.includes(
              'CEILING',
            ) &&
            !isShortCeiling &&
            selectVal.text === '100"'
          ) {
            return null;
          }

          return (
            <div
              key={`${selectVal.id}-div`}
              className={'item'}
              title={he.decode(selectVal.text)}
            >
              <button
                key={`${selectVal.id}-btn`}
                className={classNames({
                  active: selectedOption === selectVal.id,
                })}
                onClick={() => handleOptionClick(selectVal)}
              >
                <span>{he.decode(label)}</span>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OptionSelector;
