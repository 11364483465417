import { Auth } from 'aws-amplify';

export const login = (payload) => {
  return Auth.signIn(payload.email, payload.password);
};

export const completeNewPassword = (payload) => {
  return Auth.completeNewPassword(payload.user, payload.password, {
    name: payload.name,
  });
};

export const resetPassword = (payload) => {
  return Auth.forgotPasswordSubmit(
    payload.email,
    payload.code,
    payload.password,
  );
};

export const userChangePassword = (payload) => {
  return Auth.changePassword(
    payload.user,
    payload.oldPassword,
    payload.password,
  );
};

export const getSession = () => Auth.currentSession();

export const forgotPassword = (payload) => Auth.forgotPassword(payload.email);

export const updateName = (payload) =>
  Auth.updateUserAttributes(payload.user, { name: payload.name });

export const refreshAuth = () => Auth.currentUserPoolUser();

export const logout = () => Auth.signOut();

export const jsonAPIBody = (email, password) => ({
  email,
  password,
});

export const jsonPassBody = (user, password, name) => ({
  user,
  password,
  name,
});

export const jsonSetNameBody = (user, name) => ({
  user,
  name,
});

export const jsonChangePassBody = (user, oldPassword, password) => ({
  user,
  oldPassword,
  password,
});
