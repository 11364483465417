import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal';

const MapNotLoadedModal = ({ showModal }) => {
  const MapNotLoadedModalContent = () => (
    <div className="overwriteModal">
      Your map has not been saved. Please save your map first.
    </div>
  );

  const MapNotLoadedModalButtons = () => {
    const hideModal = () => {
      showModal(false);
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="OK"
            onClick={hideModal}
          >
            OK
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<MapNotLoadedModalContent />}
      buttons={<MapNotLoadedModalButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

MapNotLoadedModal.propTypes = {
  showModal: PropTypes.func.isRequired,
};
export default MapNotLoadedModal;
