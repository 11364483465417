const NAME = 'SKU';

export const types = {
  GET_REQUEST: `${NAME}/GET_REQUEST`,
  SET_SKUS: `${NAME}/SET_SKUS`,
  PUT_REQUEST: `${NAME}/PUT_REQUEST`,
  SET_SKU: `${NAME}/SET_SKU`,
  SET_SKU_OPTIONS: `${NAME}/SET_SKU_OPTIONS`,
  SET_ALLOWED_OPTIONS: `${NAME}/SET_ALLOWED_OPTIONS`,
  ERROR: `${NAME}/ERROR`,
};

export const actions = {
  getRequest: ({ page = '', search = '', category = '' }) => ({
    type: types.GET_REQUEST,
    payload: { page, search, category },
  }),
  setSKUs: (skus) => ({ type: types.SET_SKUS, payload: skus }),
  putRequest: (id, payload) => ({
    type: types.PUT_REQUEST,
    payload: { id, ...payload },
  }),
  setSKU: (id, payload) => ({
    type: types.SET_SKU,
    payload: { id, ...payload },
  }),
  setSkuOptions: (skuOptions) => ({
    type: types.SET_SKU_OPTIONS,
    payload: skuOptions,
  }),
  setAllowedOptions: (allowedOptions) => ({
    type: types.SET_ALLOWED_OPTIONS,
    payload: allowedOptions,
  }),
  error: (error) => ({ type: types.ERROR, error }),
};

const initialState = {
  byId: {},
  skuOptions: {},
  allowedOptions: [],
  allIds: [],
  lastPage: 0,
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_SKUS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case types.SET_SKU_OPTIONS:
      return {
        ...state,
        skuOptions: payload,
      };
    case types.SET_ALLOWED_OPTIONS:
      return {
        ...state,
        allowedOptions: payload,
      };
    case types.PUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_SKU: {
      const { id } = payload;
      const currentSku = state.byId[id] || {};
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...currentSku,
            ...payload,
          },
        },
        isFetching: false,
      };
    }
    case types.ERROR:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
