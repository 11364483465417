import React from 'react';
import { CircularProgress } from '@material-ui/core';

import Modal from '../../components/Modal';

const LoadingModal = ({ text }) => {
  const LoadingModalContent = () => (
    <>
      <div className="loadingModal">{text}</div>
      <CircularProgress size={30} />
    </>
  );

  const LoadingModalButtons = () => {
    return <div className="frm__generic"></div>;
  };

  return (
    <Modal
      content={<LoadingModalContent />}
      buttons={<LoadingModalButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
    />
  );
};

export default LoadingModal;
