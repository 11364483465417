import { call, put, takeEvery, select } from 'redux-saga/effects';
import localforage from 'localforage';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { types, actions as furnitureActions } from '../../reducers/furniture';
import { actions as userActions } from '../../reducers/user';
import { getSession } from '../../auth/user';
import { get } from '../../api/furniture';
import { getById, getAllIds } from '../../utils/responseData';
import { getUploadUrl, uploadFile } from '../../api/s3';
import { post, patch, remove } from '../../api/furniture';
import * as FurnitureSelector from '../../selectors/furniture';
import { ASSET_STORE_NAME, THUMBNAIL_SCALE } from '../../constants';

async function updateCache(updated) {
  const assetStore = localforage.createInstance({
    name: ASSET_STORE_NAME,
  });

  const preCache = Object.values(updated).map(async (furn) => {
    try {
      const furnFile = await fetch(
        `${
          process.env.REACT_APP_THUMBNAIL_BASE_URL
            ? process.env.REACT_APP_THUMBNAIL_BASE_URL
            : ''
        }${furn.image}/${THUMBNAIL_SCALE}?v=${Date.now()}`,
      );
      const furnBlob = await furnFile.blob();
      await assetStore.setItem(`furnitureImage-${furn.id}`, furnBlob);
    } catch (err) {
      console.log('error downloading furniture image =>', err);
    }

    const cacheAdditional = Object.values(furn.skus).map(async (item) => {
      try {
        if (item.file || item.file.length > 0) {
          const glbFile = await fetch(
            `${
              process.env.REACT_APP_ASSET_BASE_URL
                ? process.env.REACT_APP_ASSET_BASE_URL
                : ''
            }${item.file}?v=${Date.now()}`,
          );
          const glbBlob = await glbFile.blob();
          await assetStore.setItem(`glb-${item.keyVal}`, glbBlob);
        }
      } catch (err) {
        console.log('error downloading glb =>', err);
      }
      try {
        const pngFile = await fetch(
          `${
            process.env.REACT_APP_ASSET_BASE_URL
              ? process.env.REACT_APP_ASSET_BASE_URL
              : ''
          }${item.png}?v=${Date.now()}`,
        );
        const pngBlob = await pngFile.blob();
        await assetStore.setItem(`png-${item.keyVal}`, pngBlob);
      } catch (err) {
        console.log('error downloading png =>', err);
      }
    });

    await Promise.all(cacheAdditional);
  });

  await cacheShowRoom();
  return await Promise.all(preCache);
}

const cacheShowRoom = async () => {
  const baseUrl = process.env.REACT_APP_ASSET_BASE_URL
    ? process.env.REACT_APP_ASSET_BASE_URL
    : '';
  const assetStore = localforage.createInstance({
    name: ASSET_STORE_NAME,
  });

  const currShowRoom = await assetStore.getItem('glb-SHOWROOM');
  const currCeiling = await assetStore.getItem('glb-SHOWROOM_ROOF');
  const blankCube = await assetStore.getItem('BLANK_BLOB_FLOOR');

  if (!currShowRoom) {
    const showRoomGlb = await fetch(
      `${baseUrl}/data/room/Showroom.glb?v=${Date.now()}`,
    );
    const blob = await showRoomGlb.blob();
    await assetStore.setItem('glb-SHOWROOM', blob);
  }

  if (!currCeiling) {
    const showRoomGlb = await fetch(
      `${baseUrl}/data/room/Showroom-Roof.glb?v=${Date.now()}`,
    );
    const blob = await showRoomGlb.blob();
    await assetStore.setItem('glb-SHOWROOM_ROOF', blob);
  }

  if (!blankCube) {
    const cubeGlb = await fetch(
      `${baseUrl}/data/furniture/models/cube.glb?v=${Date.now()}`,
    );
    const blob = await cubeGlb.blob();
    await assetStore.setItem('BLANK_BLOB_FLOOR', blob);
  }
};

async function deleteCache(deleted) {
  const assetStore = localforage.createInstance({
    name: ASSET_STORE_NAME,
  });

  const deleteCache = Object.values(deleted).map(async (furn) => {
    try {
      await assetStore.removeItem(`furnitureImage-${furn.id}`);
      const subDelPromises = Object.values(furn.skus).map(async (item) => {
        try {
          await assetStore.removeItem(`glb-${item.keyVal}`);
          await assetStore.removeItem(`png-${item.keyVal}`);
        } catch (error) {
          console.log('error deleting single item');
        }
      });

      await Promise.all(subDelPromises);
    } catch (error) {
      console.log('error clearing cache');
    }
  });

  return await Promise.all(deleteCache);
}

async function clearCache() {
  const assetStore = localforage.createInstance({
    name: ASSET_STORE_NAME,
  });
  return await assetStore.clear();
}

function* getFurniture(action) {
  try {
    const { forceRefresh } = action.payload;
    let refreshDate = '';
    let localDate = null;

    if (!forceRefresh) {
      refreshDate = yield select(FurnitureSelector.getLastUpdate);
      if (refreshDate) {
        localDate = new Date(refreshDate);
        refreshDate = format(localDate, `yyyy-MM-dd'T'HH:mm`);
      }
    }

    const session = yield call(getSession);
    const response = yield call(get, refreshDate, session.idToken.jwtToken);

    let { data } = response;

    if (refreshDate) {
      let serverDate = new Date(data.forceRefresh);
      serverDate = new Date(
        serverDate.getUTCFullYear(),
        serverDate.getUTCMonth(),
        serverDate.getUTCDate(),
        serverDate.getUTCHours(),
        serverDate.getUTCMinutes(),
        serverDate.getUTCSeconds(),
      );

      if (serverDate.getTime() > localDate.getTime()) {
        yield call(clearCache);
        yield put(furnitureActions.clearFurniture());
        const refreshResp = yield call(get, '', session.idToken.jwtToken);
        data = refreshResp.data;
      }
    } else {
      const deletedAllIds = getAllIds(data.deleted);
      yield put(
        furnitureActions.removeSyncedDeleted({
          allIds: deletedAllIds,
        }),
      );

      yield call(deleteCache, deletedAllIds);
    }

    const byId = getById(data.updated);

    yield put(
      furnitureActions.setFurniture({
        byId,
      }),
    );

    yield call(updateCache, byId);

    let currDate = new Date();
    currDate = new Date(
      currDate.getUTCFullYear(),
      currDate.getUTCMonth(),
      currDate.getUTCDate(),
      currDate.getUTCHours(),
      currDate.getUTCMinutes(),
      currDate.getUTCSeconds(),
    );

    const updateDate = format(currDate, `yyyy-MM-dd'T'HH:mm:ss`);
    yield put(furnitureActions.setLastUpdate(updateDate));
    yield put(furnitureActions.setIsFetching(false));
    yield put(furnitureActions.error({}));
  } catch (error) {
    console.log(error);
    yield put(furnitureActions.setIsFetching(false));
    if (error === 'No current user') {
      yield put(userActions.logOut());
    } else {
      yield put(furnitureActions.error(error));
    }
  }
}

function* patchFurniture(action) {
  try {
    const { furniture } = action.payload;

    const session = yield call(getSession);
    const id = uuidv4();

    // Upload Model Image
    if (furniture.image?.type) {
      const extension = furniture.image.type.split('/');
      const fileName = `${id}.${extension[1]}`;
      yield call(
        uploadHelper,
        session,
        furniture.image,
        'data/furniture/images',
        fileName,
      );
      furniture.image = `/data/furniture/images/${fileName}`;
    }

    const skus = yield call(handleSkusFiles, session, furniture.skus);
    furniture.skus = skus;

    const response = yield call(patch, session.idToken.jwtToken, furniture.id, {
      furniture: furniture,
    });

    const { data } = response;
    const dataArr = [];
    dataArr.push(data);
    const byId = getById(dataArr);

    yield put(
      furnitureActions.setFurniture({
        byId,
      }),
    );

    yield call(updateCache, byId);

    yield put(furnitureActions.error({}));
    yield put(furnitureActions.setSaveComplete(true));
  } catch (error) {
    yield put(furnitureActions.error(error));
    yield put(furnitureActions.setSaveComplete(true));
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

async function uploadHelper(session, file, bucket, fileName) {
  const data = await toBase64(file);
  return await uploadData(session, fileName, bucket, file.type, data);
}

function* postFurniture(action) {
  const { furniture } = action.payload;

  const session = yield call(getSession);
  const id = uuidv4();
  // Upload Model Image
  try {
    if (furniture.image) {
      const extension = furniture.image.type.split('/');
      const fileName = `${id}.${extension[1]}`;
      yield call(
        uploadHelper,
        session,
        furniture.image,
        'data/furniture/images',
        fileName,
      );
      furniture.image = `/data/furniture/images/${fileName}`;
    }

    const skus = yield call(handleSkusFiles, session, furniture.skus);
    furniture.skus = skus;

    const response = yield call(post, session.idToken.jwtToken, {
      furniture: [furniture],
    });

    const { data } = response;
    const byId = getById(data);

    yield put(
      furnitureActions.setFurniture({
        byId,
      }),
    );

    yield call(updateCache, byId);

    yield put(furnitureActions.error({}));
    yield put(furnitureActions.setSaveComplete(true));
  } catch (error) {
    yield put(furnitureActions.error(error));
  }
}

function* deleteFurniture(action) {
  try {
    const session = yield call(getSession);
    yield call(remove, session.idToken.jwtToken, action.payload);
    yield put(furnitureActions.deleteRequestSuccess({ id: action.payload }));
    yield put(furnitureActions.setDeleteComplete(true));
    yield put(furnitureActions.error({}));
  } catch (error) {
    yield put(furnitureActions.error(error));
  }
}

async function handleSkusFiles(session, skus) {
  // Upload Model
  const skuPromises = skus.map(async (s) => {
    const id = uuidv4();
    if (s.file?.type) {
      const extension = s.file.type.split('/');
      const fileName = `${id}.${extension[1]}`;
      await uploadHelper(session, s.file, 'data/furniture/models', fileName);
      s.file = `/data/furniture/models/${fileName}`;
    }

    // Upload PDF Png
    if (s.png?.type) {
      const extension = s.png.type.split('/');
      const fileName = `${id}.${extension[1]}`;
      await uploadHelper(session, s.png, 'data/furniture/pngs', fileName);
      s.png = `/data/furniture/pngs/${fileName}`;
    }

    return s;
  });

  return await Promise.all(skuPromises);
}

async function uploadData(session, filename, folder, contentType, data) {
  const uploadUrl = await getUploadUrl(
    session.idToken.jwtToken,
    filename,
    folder,
    contentType,
  );

  const options = {
    params: {
      Key: `${folder}/${filename}`,
      ContentType: contentType,
    },
    headers: {
      'Content-Type': contentType,
    },
  };

  const buffer = Buffer.from(
    data.replace(/^data:image\/\w+;base64,/, ''),
    'base64',
  );

  return await uploadFile(uploadUrl.data, buffer, options);
}

export default function* watchFurniture() {
  yield takeEvery(types.GET_REQUEST, getFurniture);
  yield takeEvery(types.POST_REQUEST, postFurniture);
  yield takeEvery(types.PATCH_REQUEST, patchFurniture);
  yield takeEvery(types.DELETE_REQUEST, deleteFurniture);
}
