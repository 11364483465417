const NAME = 'MAP';

export const types = {
  GET_REQUEST: `${NAME}/GET_REQUEST`,
  SET_MAPS: `${NAME}/SET_MAPS`,
  GET_ONE_REQUEST: `${NAME}/GET_ONE_REQUEST`,
  GET_DIRTY: `${NAME}/GET_DIRTY`,
  PATCH_REQUEST: `${NAME}/PATCH_REQUEST`,
  POST_REQUEST: `${NAME}/POST_REQUEST`,
  SET_MAP_POST: `${NAME}/SET_MAP_POST`,
  SET_MAP_PATCH: `${NAME}/SET_MAP_PATCH`,
  DELETE_REQUEST: `${NAME}/DELETE_REQUEST`,
  DELETE_REQUEST_SUCCESS: `${NAME}/DELETE_REQUEST_SUCCESS`,
  ERROR: `${NAME}/ERROR`,
  GET_BY_USER_REQUEST: `${NAME}/GET_BY_USER_REQUEST`,
  SET_MAP_TO_OVERWRITE: `${NAME}/SET_MAP_TO_OVERWRITE`,
  SET_SAVE_COMPLETE: `${NAME}/SET_SAVE_COMPLETE`,
  SET_DELETE_COMPLETE: `${NAME}/SET_DELETE_COMPLETE`,
  SET_MAP_LOADED: `${NAME}/SET_MAP_LOADED`,
  SET_MAP_PDF_URL: `${NAME}/SET_MAP_PDF_URL`,
  MAP_PDF_REQUEST: `${NAME}/MAP_PDF_REQUEST`,
  SET_GET_PDF_COMPLETE: `${NAME}/SET_GET_PDF_COMPLETE`,
  SET_LAST_UPDATE: `${NAME}/SET_LAST_UPDATE`,
  SET_IS_DIRTY: `${NAME}/SET_IS_DIRTY`,
  REMOVE_DELETED_FROM_SYNC: `${NAME}/REMOVE_DELETED_FROM_SYNC`,
  SET_CONCURRENT_WRITE: `${NAME}/SET_CONCURRENT_WRITE`,
  CLEAR_MAPS: `${NAME}/CLEAR_MAPS`,
};

export const actions = {
  getRequest: (forceRefresh) => ({
    type: types.GET_REQUEST,
    payload: { forceRefresh },
  }),
  getByUserRequest: () => ({ type: types.GET_BY_USER_REQUEST }),
  setMaps: (maps) => ({ type: types.SET_MAPS, payload: maps }),
  getOneRequest: ({ id }) => ({ type: types.GET_ONE_REQUEST, payload: { id } }),
  patchRequest: (map, forceOverwrite) => ({
    type: types.PATCH_REQUEST,
    payload: { map, forceOverwrite },
  }),
  clearMaps: () => ({
    type: types.CLEAR_MAPS,
  }),
  postRequest: (map) => ({
    type: types.POST_REQUEST,
    payload: { map },
  }),
  getIsDirtyRequest: (id, updatedAt) => ({
    type: types.GET_DIRTY,
    payload: { id, updatedAt },
  }),
  mapPdfRequest: (id, scene, snapshot) => ({
    type: types.MAP_PDF_REQUEST,
    payload: { id, scene, snapshot },
  }),
  setSaveComplete: (saveComplete) => ({
    type: types.SET_SAVE_COMPLETE,
    payload: saveComplete,
  }),
  setIsDirty: (isDirty) => ({
    type: types.SET_IS_DIRTY,
    payload: isDirty,
  }),
  setDeleteComplete: (deleteComplete) => ({
    type: types.SET_DELETE_COMPLETE,
    payload: deleteComplete,
  }),
  setMapPost: (id, payload) => ({
    type: types.SET_MAP_POST,
    payload: { id, ...payload },
  }),
  setMapPatch: (id, payload) => ({
    type: types.SET_MAP_PATCH,
    payload: { id, ...payload },
  }),
  setMapToOverwrite: (mapToOverwrite) => ({
    type: types.SET_MAP_TO_OVERWRITE,
    payload: mapToOverwrite,
  }),
  setMapLoaded: (loadedMap) => ({
    type: types.SET_MAP_LOADED,
    payload: loadedMap,
  }),
  setMapUrl: (mapPdfUrl) => ({
    type: types.SET_MAP_PDF_URL,
    payload: mapPdfUrl,
  }),
  setGetPdfComplete: (getPdfComplete) => ({
    type: types.SET_GET_PDF_COMPLETE,
    payload: getPdfComplete,
  }),
  setConcurrentWrite: (concurrentWrite) => ({
    type: types.SET_CONCURRENT_WRITE,
    payload: concurrentWrite,
  }),
  deleteRequest: (id) => ({ type: types.DELETE_REQUEST, payload: id }),
  deleteRequestSuccess: ({ id }) => ({
    type: types.DELETE_REQUEST_SUCCESS,
    payload: { id },
  }),
  removeSyncedDeleted: (maps) => ({
    type: types.REMOVE_DELETED_FROM_SYNC,
    payload: maps,
  }),
  setLastUpdate: (lastUpdate) => ({
    type: types.SET_LAST_UPDATE,
    payload: lastUpdate,
  }),
  error: (error) => ({ type: types.ERROR, payload: error }),
};

const initialState = {
  byId: {},
  allIds: [],
  isFetching: false,
  error: {},
  saveComplete: false,
  deleteComplete: false,
  mapToOverwrite: {},
  loadedMap: {},
  persistedScene: {},
  mapPdfUrl: '',
  getPdfComplete: '',
  fetchingPdf: false,
  lastUpdate: '',
  concurrentWrite: false,
  isDirty: 'NOT_SET',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_BY_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_MAPS:
      let merged = state.allIds.concat(payload.allIds);
      merged = [...new Set([...state.allIds, ...payload.allIds])];
      return {
        byId: { ...state.byId, ...payload.byId },
        allIds: merged,
        isFetching: false,
      };
    case types.CLEAR_MAPS:
      return {
        ...state,
        byId: {},
        allIds: [],
      };
    case types.GET_ONE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_DIRTY:
      return {
        ...state,
        isFetching: true,
      };
    case types.PATCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.MAP_PDF_REQUEST:
      return {
        ...state,
        fetchingPdf: true,
      };
    case types.SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: payload,
      };
    case types.SET_MAP_POST: {
      const { id } = payload;
      const currentIds = state.allIds;
      currentIds.push(id);
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...payload,
          },
        },
        allIds: currentIds,
        isFetching: false,
        saveComplete: true,
      };
    }
    case types.SET_MAP_PATCH: {
      const { id } = payload;
      const currentMap = state.byId[id] || {};
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...currentMap,
            ...payload,
          },
        },
        isFetching: false,
        saveComplete: true,
      };
    }
    case types.DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.DELETE_REQUEST_SUCCESS: {
      const { id } = payload;
      const curentByid = { ...state.byId };
      delete curentByid[id];

      return {
        ...state,
        allIds: state.allIds.filter((mapId) => mapId !== id),
        byId: curentByid,
        isFetching: false,
      };
    }
    case types.REMOVE_DELETED_FROM_SYNC: {
      const curentByid = { ...state.byId };
      let currAllId = state.allIds;

      payload.allIds.forEach((id) => {
        delete curentByid[id];
        currAllId = currAllId.filter((mapId) => mapId !== id);
      });

      return {
        ...state,
        allIds: currAllId,
        byId: curentByid,
        isFetching: false,
      };
    }
    case types.SET_CONCURRENT_WRITE:
      return {
        ...state,
        concurrentWrite: payload,
        isFetching: !payload,
      };
    case types.SET_SAVE_COMPLETE:
      return {
        ...state,
        saveComplete: payload,
      };
    case types.SET_IS_DIRTY:
      return {
        ...state,
        isDirty: payload,
      };
    case types.SET_GET_PDF_COMPLETE:
      return {
        ...state,
        getPdfComplete: payload,
        isFetching: false,
      };
    case types.SET_DELETE_COMPLETE:
      return {
        ...state,
        deleteComplete: payload,
        isFetching: false,
      };
    case types.SET_MAP_TO_OVERWRITE:
      return {
        ...state,
        mapToOverwrite: payload,
      };
    case types.SET_MAP_LOADED:
      return {
        ...state,
        loadedMap: payload,
      };
    case types.SET_MAP_PDF_URL:
      return {
        ...state,
        mapPdfUrl: payload,
        fetchingPdf: false,
      };
    case types.ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
