import React from 'react';
import { DefaultToastContainer } from 'react-toast-notifications';

const CustomToastContainer = (props) => (
  <span className="customToastContainer">
    <DefaultToastContainer {...props} />
  </span>
);

export default CustomToastContainer;
