import axios from 'axios';

export const config = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'content-Type': 'application/json; charset=utf-8',
  },
  validateStatus: () => true,
};

export default axios.create({});
