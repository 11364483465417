import React, { useContext, useCallback } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { APIContext } from '../../context/APIContext';
import { TOOLBAR_POS } from '../../constants';

const DPad = ({ setSelectionUiPos, selectedFurnitureRef }) => {
  const apiContext = useContext(APIContext);
  const { api } = apiContext;

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  const move = useCallback(
    (direction, start) => {
      if (api) {
        switch (direction) {
          case 'FORWARD':
            api.moveForward(start);
            break;
          case 'LEFT':
            api.moveLeft(start);
            break;
          case 'RIGHT':
            api.moveRight(start);
            break;
          case 'BACK':
            api.moveBackward(start);
            break;
          default:
            break;
        }

        if (selectedFurnitureRef.current && !start) {
          const rootObject = api.getRootObject(api.selectedObject);
          if (rootObject) {
            const p2d = api.getObjectBoundaryPoint(rootObject, 'right');
            setSelectionUiPos({
              top: p2d.y,
              left: p2d.x,
            });
          }
        } else {
          setSelectionUiPos(TOOLBAR_POS);
        }
      }
    },
    [api, setSelectionUiPos, selectedFurnitureRef],
  );

  return (
    <div className="dPadWrapper" onPointerDown={onPointerDown}>
      <div className="buttonWrapper">
        <button
          key="dpadUp"
          className="btnController dpadUp"
          onPointerDown={() => move('FORWARD', true)}
          onPointerUp={() => move('FORWARD', false)}
          onPointerLeave={() => move('FORWARD', false)}
        >
          <ArrowUpwardIcon />
        </button>
        <button
          key="dpadLeft"
          className="btnController dpadLeft"
          onPointerDown={() => move('LEFT', true)}
          onPointerUp={() => move('LEFT', false)}
          onPointerLeave={() => move('LEFT', false)}
        >
          <ArrowBackIcon />
        </button>
        <button
          key="dpadRight"
          className="btnController dpadRight"
          onPointerDown={() => move('RIGHT', true)}
          onPointerUp={() => move('RIGHT', false)}
          onPointerLeave={() => move('RIGHT', false)}
        >
          <ArrowForwardIcon />
        </button>
        <button
          key="dpadDown"
          className="btnController dpadDown"
          onPointerDown={() => move('BACK', true)}
          onPointerUp={() => move('BACK', false)}
          onPointerLeave={() => move('BACK', false)}
        >
          <ArrowDownwardIcon />
        </button>
      </div>
    </div>
  );
};

export default React.memo(DPad);
