import { all, fork } from 'redux-saga/effects';
import watchUser from './user';
import watchSKU from './sku';
import watchEditSKU from './editSKU';
import watchCategories from './categories';
import watchMaps from './maps';
import watchFurniture from './furniture';
import watchFurnitureTypes from './furnitureTypes';
import watchSubCategories from './subCategories';

export default function* () {
  yield all([
    fork(watchUser),
    fork(watchSKU),
    fork(watchEditSKU),
    fork(watchCategories),
    fork(watchMaps),
    fork(watchFurniture),
    fork(watchFurnitureTypes),
    fork(watchSubCategories),
  ]);
}
