import FileSaver from 'file-saver';

export const generatePdf = (props) => {
  const { url, name } = props;

  fetch(url, { responseType: 'blob' })
    .then((response) => response.blob())
    .then((blob) => {
      const theFile = new Blob([blob], {
        type: 'application/pdf',
      });
      FileSaver.saveAs(theFile, name);
    });
};
