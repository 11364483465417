const NAME = 'FURNITURETYPES';

export const types = {
  GET_REQUEST: `${NAME}/GET_REQUEST`,
  SET_FURNITURE_TYPES: `${NAME}/SET_CATEGORIES`,
  ERROR: `${NAME}/ERROR`,
};

export const actions = {
  getRequest: () => ({ type: types.GET_REQUEST }),
  setFurnitureTypes: (furnitureTypes) => ({
    type: types.SET_FURNITURE_TYPES,
    payload: furnitureTypes,
  }),
  error: (error) => ({ type: types.ERROR, error }),
};

const initialState = {
  byId: {},
  allIds: [],
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_FURNITURE_TYPES:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case types.ERROR:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
