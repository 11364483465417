import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import PlansModal from './plans';
import pinLocation from '../../assets/images/icons/png/pin-location-light.png';
import estateDimensions from '../../assets/images/icons/png/real-estate-dimensions-plan-light.png';
import floppyDisk from '../../assets/images/icons/png/floppy-disk-light.png';
import { actions as mapActions } from '../../reducers/maps';
import logo from '../../assets/images/restoration-hardware-logo-large.jpg';

const IntroModal = ({ showModal }) => {
  const dispatch = useDispatch();
  const [startSavedPlan, setStartSavedPlan] = useState(false);
  const IntroHeader = () => (
    <div className="store">
      <h1>RH DALLAS</h1>
      <h3>3133 Knox St</h3>
      <h3>Dallas, TX 75205</h3>
    </div>
  );

  const IntroContent = () => (
    <>
      <h3>welcome to the</h3>
      <img className="large-logo" src={logo} alt="Resoration Hardware" />
      <h3>gallery planner</h3>
    </>
  );

  const planNewMap = () => {
    dispatch(mapActions.setMapLoaded(null));
    showModal(false);
  };

  const startFromSavedPlan = () => {
    setStartSavedPlan(true);
  };

  const IntroButtons = () => (
    <>
      <button className="inative">
        <img src={pinLocation} alt="" />
        <span>change gallery</span>
      </button>

      <button onClick={planNewMap}>
        <img src={estateDimensions} alt="" />
        <span>plan new map</span>
      </button>

      <button onClick={startFromSavedPlan}>
        <img src={floppyDisk} alt="" />
        <span>start with</span>
        <span>saved plan</span>
      </button>
    </>
  );

  return startSavedPlan ? (
    <PlansModal setStartSavedPlan={setStartSavedPlan} showModal={showModal} />
  ) : (
    <Modal
      header={<IntroHeader />}
      content={<IntroContent />}
      buttons={<IntroButtons />}
      wrapperStyles="intro"
    />
  );
};

IntroModal.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default IntroModal;
