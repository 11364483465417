import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Modal = ({
  header,
  backButton,
  content,
  buttons,
  plans,
  planName,
  overlayStyles,
  wrapperStyles,
  buttonWrapperStyles,
}) => {
  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div
      className={classNames('dialogBox', overlayStyles)}
      onPointerDown={onPointerDown}
    >
      <div className={classNames('dialogBox__wrapper', wrapperStyles)}>
        {header && (
          <div
            className={`dialogBox__wrapper--header ${
              backButton ? '--back' : ''
            }`}
          >
            {header}
          </div>
        )}

        <div className="dialogBox__wrapper--content">{content}</div>

        {buttons && (
          <div
            className={classNames(
              'dialogBox__wrapper--buttons',
              buttonWrapperStyles,
            )}
          >
            {buttons}
          </div>
        )}

        {plans && <div className="dialogBox__wrapper--plans">{plans}</div>}

        {planName && (
          <div className="dialogBox__wrapper--planName">{planName}</div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  header: PropTypes.element,
  content: PropTypes.element.isRequired,
  backButton: PropTypes.bool,
  buttons: PropTypes.element,
  plans: PropTypes.element,
  planName: PropTypes.element,
  overlayStyles: PropTypes.string,
  wrapperStyles: PropTypes.string,
  buttonWrapperStyles: PropTypes.string,
};

Modal.defaultProps = {
  backButton: false,
  header: null,
  buttons: null,
  plans: null,
  planName: null,
  overlayStyles: '',
  wrapperStyles: '',
  buttonWrapperStyles: '',
};

export default Modal;
