import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { APIContext } from '../../context/APIContext';
import * as MapsSelector from '../../selectors/maps';
import { actions as mapActions } from '../../reducers/maps';

import { PNG_SNAP_QUALITY } from '../../constants';

const ConcurrentSaveModal = ({ showModal }) => {
  const mapToOverwrite = useSelector(MapsSelector.getMapToOverwrite);

  const ConcurrentSaveContent = () => (
    <div className="overwriteModal">
      Another user has modified '{mapToOverwrite.name}'. Click "Overwrite" to
      overwrite their changes or click "Cancel" to go back and save as new.
    </div>
  );

  const ConcurrentSaveButtons = () => {
    const dispatch = useDispatch();

    const apiContext = useContext(APIContext);
    const { api } = apiContext;
    const loading = useSelector(MapsSelector.getLoading);

    const overwriteMap = async () => {
      const currentScene = await api.exportScene();
      const snapshot = api.getSnapShot2D(PNG_SNAP_QUALITY);
      mapToOverwrite.scene = currentScene;
      dispatch(mapActions.setConcurrentWrite(false));
      dispatch(mapActions.patchRequest(mapToOverwrite, snapshot, true));
    };

    const cancel = () => {
      dispatch(mapActions.setConcurrentWrite(false));
      showModal(false);
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Overwrite"
            onClick={overwriteMap}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConcurrentSaveContent />}
      buttons={<ConcurrentSaveButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="offlineWarning"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConcurrentSaveModal.propTypes = {
  showModal: PropTypes.func.isRequired,
};
export default ConcurrentSaveModal;
