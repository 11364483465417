import API, { config } from '../API';

export const get = (token) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get('/furnituretype', apiConf);
};

export const getOne = (id) => API.get(`/furnituretype/${id}`);

export const post = (payload) => API.post('/furnituretype', payload);
