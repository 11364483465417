import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { CircularProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { actions as userActions } from '../../reducers/user';
import { CHANGE_NAME_REQUIRED_MSG } from '../../constants';
import { actions as mapActions } from '../../reducers/maps';
import ChangePasswordModal from '../Modals/changePassword';
import floorImage from '../../assets/images/rh-map.jpg';
import * as MapsSelector from '../../selectors/maps';
import * as UserSelector from '../../selectors/users';
import * as MapSelectors from '../../selectors/maps';
import LoadingModal from '../../scenes/Modals/loadingModal';
import ConfirmDeleteModal from '../Modals/confirmDelete';
import ConfirmOpenModal from '../Modals/confirmOpen';

import './style.scss';

const MyAccount = () => {
  const dispatch = useDispatch();
  const [isChangingPass, setIsChangingPassword] = useState(false);
  const [isChangingName, setIsChangingName] = useState(false);
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [showConfirmOpen, setShowConfirmOpen] = useState(false);
  const [openMap, setOpenMap] = useState('');
  const currentUser = useSelector(UserSelector.getUser);
  const loading = useSelector(UserSelector.getLoading);
  const changePassModalActive = useSelector(UserSelector.getShowChagePassword);
  const error = useSelector(UserSelector.getError);
  const mapsByUserId = useSelector(MapsSelector.getMapsForUser);
  const mapsById = useSelector(MapsSelector.getById);
  const mapError = useSelector(MapsSelector.getError);
  const deleteComplete = useSelector(MapsSelector.getDeleteComplete);
  const [mapToDelete, setMapToDelete] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const nameUpdateComplete = useSelector(UserSelector.getNameUpdateComplete);
  const passUpdateComplete = useSelector(
    UserSelector.getPasswordUpdateComplete,
  );
  const loadingPdf = useSelector(MapSelectors.getFetchingPdf);
  const { addToast } = useToasts();

  /*
  useEffect(() => {
    if (getMapPdfComplete && mapPdfUrl) {
      const fileName = mapToGeneratePdf
        ? mapToGeneratePdf.name
        : 'floor_plan.pdf';
      generatePdf({
        url: mapPdfUrl,
        name: fileName,
      });
      dispatch(mapActions.setGetPdfComplete(false));
    }
  }, [dispatch, mapPdfUrl, getMapPdfComplete, mapToGeneratePdf]);
  */

  const [formAccount, setFormAccount] = useState({
    name: '',
    position: '',
    email: '',
    password: 'invalidPassForShow',
  });

  useEffect(() => {
    const pageLoad = async () => {
      batch(() => {
        dispatch(mapActions.error({}));
        dispatch(userActions.setError({}));
        dispatch(userActions.setChangingPass(false));
      });
    };
    pageLoad();
  }, [dispatch]);

  useEffect(() => {
    setFormAccount({
      name: currentUser.userObject.attributes.name,
      position: currentUser.userObject.attributes['custom:position'],
      email: currentUser.userObject.attributes.email,
      password: 'invalidPassForShow',
    });
  }, [currentUser]);

  useEffect(() => {
    if (nameUpdateComplete && !error.message) {
      addToast('Name updated!', {
        appearance: 'success',
      });
      dispatch(userActions.setNameUpdateComplete(false));
    }
    if (passUpdateComplete && !error.message) {
      addToast('Password updated!', {
        appearance: 'success',
      });
      dispatch(userActions.setPasswordUpdateComplete(false));
    }
  }, [dispatch, addToast, nameUpdateComplete, passUpdateComplete, error]);

  useEffect(() => {
    setShowChangePassModal(changePassModalActive);
  }, [changePassModalActive]);

  useEffect(() => {
    if (error?.message && !changePassModalActive) {
      setIsChangingName(true);
    }
  }, [error, changePassModalActive]);

  useEffect(() => {
    if (deleteComplete && !mapError?.message) {
      addToast('Delete Successful', {
        appearance: 'success',
      });
      setShowConfirm(false);
    } else if (deleteComplete && mapError?.message) {
      addToast('Error Performing Delete', {
        appearance: 'error',
      });
    }
    dispatch(mapActions.setDeleteComplete(false));
  }, [dispatch, addToast, deleteComplete, mapError]);

  const handleChangePassword = () => {
    if (isChangingName) {
      dispatch(userActions.setError({}));
      setFormAccount({
        ...formAccount,
        name: currentUser.userObject.attributes.name,
      });
      setIsChangingName(!isChangingName);
    }
    setIsChangingPassword(!isChangingPass);
    dispatch(userActions.setChangingPass(true));
  };

  const handleChangeName = () => {
    if (isChangingName) {
      if (!formAccount.name) {
        dispatch(userActions.setError({ message: CHANGE_NAME_REQUIRED_MSG }));
      } else {
        dispatch(userActions.updateNameRequest(formAccount.name));
        setIsChangingName(!isChangingName);
      }
    } else {
      setIsChangingName(!isChangingName);
    }
  };

  const handleInputChange = (name, value) => {
    setFormAccount({ ...formAccount, [name]: value });
  };

  const handleDeleteClick = useCallback(
    (e) => {
      e.stopPropagation();
      const id = e.target.attributes.getNamedItem('mapid').value;
      const getMapToDelete = mapsById[id];
      setMapToDelete(getMapToDelete);
      setShowConfirm(true);
    },
    [mapsById],
  );

  const loadMap = useCallback(
    (mapId) => {
      const mapToLoad = mapsById[mapId];
      setOpenMap(mapToLoad);
      setShowConfirmOpen(true);
    },
    [mapsById],
  );

  /*
  const downloadPdf = useCallback(
    (mapId) => {
      setMapToGeneratePdf(mapsById[mapId]);
      dispatch(mapActions.mapPdfRequest(mapId));
    },
    [dispatch, mapsById],
  );
    */

  return (
    <>
      {showChangePassModal && (
        <ChangePasswordModal showModal={setShowChangePassModal} />
      )}
      <section className="container myAccount__page">
        <h1>My Account</h1>
        <div className="frm__myAccount">
          <div className="half__container">
            <div className="form-group">
              <label>
                Name
                <button onClick={handleChangeName} className="link-edit">
                  {!loading && isChangingName && 'Save Name'}
                  {!loading && !isChangingName && 'Change Name'}
                  {loading && <CircularProgress size={14} />}
                </button>
                <input
                  type="text"
                  className={
                    isChangingName || loading
                      ? 'myAccountEnabled'
                      : 'myAccountDisabled'
                  }
                  disabled={!isChangingName || loading}
                  value={formAccount.name}
                  onChange={(e) =>
                    handleInputChange('name', e.currentTarget.value)
                  }
                />
                {!changePassModalActive && (
                  <div className="error">{error?.message}</div>
                )}
              </label>
            </div>
          </div>
          <div className="half__container">
            <div className="form-group">
              <label>
                Position
                <input
                  type="text"
                  disabled={true}
                  value={formAccount.position}
                />
              </label>
            </div>
          </div>
          <div className="half__container">
            <div className="form-group">
              <label>
                Email
                <input type="email" disabled={true} value={formAccount.email} />
              </label>
            </div>
          </div>
          <div className="half__container">
            <div className="form-group">
              <label>
                Password
                <button onClick={handleChangePassword} className="link-edit">
                  Change Password
                </button>
                <input
                  type="password"
                  disabled={true}
                  value={formAccount.password}
                />
              </label>
            </div>
          </div>
        </div>
        <h1>My Saved Plans</h1>
        <div className="wrapper">
          <div className="content">
            {Object.values(mapsByUserId).map((map) => {
              const { id, name, city, creator, createdOn, updatedAt } = map;
              return (
                <div key={id} className="planItem" onClick={() => loadMap(id)}>
                  <div className="thumbnail">
                    <img
                      className="floorPlanImg"
                      src={floorImage}
                      alt=""
                      title=""
                    />
                  </div>
                  <div className="info">
                    <div className="infoWrapper">
                      <span className="nameItem">{name}</span>
                      <span className="localItem">{city || 'Dallas'}</span>
                      <span className="createdByItem">
                        created by <small>{creator}</small>
                      </span>
                      <span className="createdOnItem">
                        created on{' '}
                        <small>{format(new Date(createdOn), 'MM/dd/yy')}</small>
                      </span>
                      <span className="lastUpdatedItem">
                        last updated{' '}
                        <small>{format(new Date(updatedAt), 'MM/dd/yy')}</small>
                      </span>
                      {/* TEMP REMOVE EDITORS FOR THIS POC.
                      <span className="editorsItem">
                        <button className="editBtn" />
                        <strong>editors</strong>
                        <p>{editors.join(', ')}</p>
                      </span>
                      */}
                    </div>
                  </div>
                  <button
                    mapid={id}
                    className="deleteBtn"
                    onClick={handleDeleteClick}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {showConfirm && (
        <ConfirmDeleteModal
          mapToDelete={mapToDelete}
          setShowConfirm={setShowConfirm}
        />
      )}
      {loadingPdf && <LoadingModal text="Generating PDF. Please Wait." />}
      {showConfirmOpen && (
        <ConfirmOpenModal
          mapToLoad={openMap}
          setShowConfirm={setShowConfirmOpen}
        />
      )}
    </>
  );
};

export default MyAccount;
