import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { actions as userActions } from '../../reducers/user';
import * as UserSelector from '../../selectors/users';

import { PASSWORD_RESET_ERROR_MSG } from '../../constants';
import logo from '../../assets/images/restoration-hardware-logo-large.jpg';

const RequestCodeModal = () => {
  const RequestCodeHeader = () => (
    <div className="store">
      <h1>RH DALLAS</h1>
      <h3>3133 Knox St</h3>
      <h3>Dallas, TX 75205</h3>
    </div>
  );

  const RequestCodeContent = () => (
    <>
      <img className="large-logo" src={logo} alt="Resoration Hardware" />
      <h3>gallery planner</h3>
    </>
  );

  const RequestCodeButtons = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const error = useSelector(UserSelector.getError);
    const loading = useSelector(UserSelector.getLoading);

    const onHandleTriggerCode = async () => {
      if (!email) {
        dispatch(userActions.setError({ message: PASSWORD_RESET_ERROR_MSG }));
      } else {
        dispatch(userActions.fogotPasswordRequest(email));
      }
    };

    const onHandleCancel = () => {
      batch(() => {
        dispatch(userActions.setRequestCode(false));
        dispatch(userActions.setError({}));
      });
    };

    return (
      <div className="frm__generic">
        <div className="instructions">
          Please Enter Your Email to Request a Password Reset
        </div>
        <input
          type="email"
          className="email"
          name="email"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <div className="error">{error?.message}</div>
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={onHandleTriggerCode}
            text="Reset"
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={onHandleCancel}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      header={<RequestCodeHeader />}
      content={<RequestCodeContent />}
      buttons={<RequestCodeButtons />}
    />
  );
};

export default RequestCodeModal;
