import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { usePreview } from 'react-dnd-preview';
import { isMobile } from 'react-device-detect';

import IntroModal from '../Modals/intro';
import SaveModal from '../Modals/save';
import LoginModal from '../Modals/login';
import ResetPasswordModal from '../Modals/resetPassword';
import RequestCode from '../Modals/requestCode';
import ForgotPasswordReset from '../Modals/forgotPasswordReset';
import LoadingModal from '../Modals/loadingModal';
import { useLastLocation } from 'react-router-last-location';
import { actions as userActions } from '../../reducers/user';
import { actions as sidebarActions } from '../../reducers/sidebar';
import * as UserSelector from '../../selectors/users';
import * as FurnitureSelectors from '../../selectors/furniture';
import addCircle from '../../assets/images/icons/png/add-circle.png';
import notAllowed from '../../assets/images/icons/png/not-allowed.png';

import { NEW_PASSWORD_REQUIRED } from '../../constants';
import ConfirmExitModal from '../Modals/confirmExit';

const Home = ({
  showIntroModal,
  setShowIntroModal,
  showConfirmExitModal,
  setShowConfirmExitModal,
  showSaveModal,
  setShowSaveModal,
  setSelectionUiPos,
  setSelectedView,
  setCameraState,
  setSelectedRotIndex,
}) => {
  const dispatch = useDispatch();
  const lastRouteLocation = useLastLocation();
  const { addToast } = useToasts();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showRequestCodeModal, setShowRequestCodeModal] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const [loginAction, setLoginAction] = useState(false);
  const currentUser = useSelector(UserSelector.getUser);
  const passwordReset = useSelector(UserSelector.getShowRequestCode);
  const forgotPassword = useSelector(UserSelector.getShowChagePassword);
  const error = useSelector(UserSelector.getError);
  const passUpdateComplete = useSelector(
    UserSelector.getPasswordUpdateComplete,
  );
  const loading = useSelector(FurnitureSelectors.getLoading);

  useEffect(() => {
    const onLoadPage = () => {
      batch(() => {
        dispatch(userActions.setError({}));
        dispatch(userActions.setForgotPassEmail(''));
        dispatch(userActions.setRequestCode(false));
        dispatch(userActions.setChangingPass(false));
        dispatch(sidebarActions.setMenuOpened(false));
      });
    };
    onLoadPage();
  }, [dispatch]);

  useEffect(() => {
    if (!currentUser?.loggedIn && !passwordReset && !forgotPassword) {
      setShowLoginModal(true);
      setShowRequestCodeModal(false);
      setShowForgotPassModal(false);
    } else if (!currentUser?.loggedIn && passwordReset && !forgotPassword) {
      setShowLoginModal(false);
      setShowRequestCodeModal(true);
      setShowForgotPassModal(false);
    } else if (!currentUser?.loggedIn && !passwordReset && forgotPassword) {
      setShowLoginModal(false);
      setShowRequestCodeModal(false);
      setShowForgotPassModal(true);
    } else {
      setShowLoginModal(false);
      if (
        (lastRouteLocation?.pathname !== '/my-account' &&
          lastRouteLocation?.pathname !== '/skulist') ||
        loginAction
      ) {
        setShowIntroModal(true);
        setLoginAction(false);
      }
    }
  }, [
    dispatch,
    currentUser,
    passwordReset,
    forgotPassword,
    lastRouteLocation,
    setShowIntroModal,
    loginAction,
  ]);

  useEffect(() => {
    const onCurrentUserUpdate = () => {
      if (
        currentUser?.loggedIn &&
        currentUser?.userObject?.challengeName === NEW_PASSWORD_REQUIRED &&
        !currentUser?.userObject?.completeNewPasswordChallenge
      ) {
        const clearedUser = { userObject: null, loggedIn: false };
        dispatch(userActions.setUser(clearedUser));
        setShowLoginModal(true);
        setShowPasswordResetModal(false);
      } else if (
        currentUser?.loggedIn &&
        currentUser?.userObject?.challengeName === NEW_PASSWORD_REQUIRED &&
        currentUser?.userObject?.completeNewPasswordChallenge
      ) {
        setShowLoginModal(false);
        setShowPasswordResetModal(true);
      } else if (currentUser?.loggedIn) {
        setShowLoginModal(false);
        setShowPasswordResetModal(false);
      }
    };
    onCurrentUserUpdate();
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (passUpdateComplete && !error.message) {
      addToast('Password Updated Successfully!', {
        appearance: 'success',
      });
      dispatch(userActions.setPasswordUpdateComplete(false));
    }
  }, [dispatch, addToast, passUpdateComplete, error]);

  const MyPreview = () => {
    const preview = usePreview();

    if (!preview.display) {
      return null;
    }

    if (preview.item?.coords) {
      let x = preview.item.coords.x;
      let y = preview.item.coords.y;
      if (isMobile) {
        x = x - 60;
        y = y - 60;
      }
      const newTrans = `translate(${x}px, ${y}px)`;
      preview.style.WebkitTransform = newTrans;
      preview.style.transform = newTrans;
    }

    return (
      <div style={preview.style}>
        <img src={preview.item?.canDrop ? addCircle : notAllowed} alt="" />
      </div>
    );
  };

  return (
    <>
      {showIntroModal && <IntroModal showModal={setShowIntroModal} />}
      {showConfirmExitModal && (
        <ConfirmExitModal
          showModal={setShowConfirmExitModal}
          setShowIntro={setShowIntroModal}
          setSelectionUiPos={setSelectionUiPos}
          setSelectedView={setSelectedView}
          setCameraState={setCameraState}
          setSelectedRotIndex={setSelectedRotIndex}
        />
      )}
      {showSaveModal && (
        <SaveModal
          showModal={setShowSaveModal}
          setShowIntroModal={setShowIntroModal}
        />
      )}
      {showLoginModal && <LoginModal setLoginAction={setLoginAction} />}
      {showPasswordResetModal && (
        <ResetPasswordModal showModal={setShowPasswordResetModal} />
      )}
      {showRequestCodeModal && (
        <RequestCode showModal={setShowRequestCodeModal} />
      )}
      {showForgotPassModal && (
        <ForgotPasswordReset showModal={setShowForgotPassModal} />
      )}
      {loading && (
        <LoadingModal text="Syncing Planner. If this is your first time loading the planner, then this could take a few minutes." />
      )}

      <MyPreview />
    </>
  );
};

export default Home;
