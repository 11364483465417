import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import localforage from 'localforage';
import { useHistory } from 'react-router';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { APIContext } from '../../context/APIContext';
import * as FurnitureSelectors from '../../selectors/furniture';
import { actions as mapActions } from '../../reducers/maps';
import { IMPORT_OPTIONS, ASSET_STORE_NAME, PAGE_HOME } from '../../constants';

const ConfirmOpenModal = ({ mapToLoad, setShowConfirm }) => {
  const ConfirmOpenContent = () => (
    <div className="overwriteModal">
      Warning, opening a map will cause you to lose any unsaved progress in the
      planner. Please return to the planner and save.
    </div>
  );

  const ConfirmOpenButtons = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const apiContext = useContext(APIContext);
    const { api } = apiContext;
    const [loading, setLoading] = useState(false);
    const furniture = useSelector(FurnitureSelectors.getById);

    const loadMap = async () => {
      setLoading(true);
      try {
        const assetStore = localforage.createInstance({
          name: ASSET_STORE_NAME,
        });

        const replacePromises = mapToLoad.scene.models.map(async (model) => {
          const furnCheck = Object.values(furniture).find(
            (f) => f.id === model.userData.furnitureId,
          );
          if (furnCheck || model.userData.isMap) {
            const searchGlbKey = model.userData.isCube
              ? `BLANK_BLOB_FLOOR`
              : `glb-${model.userData.skuKey}`;
            const glb = await assetStore.getItem(searchGlbKey);
            if (glb) {
              const url = URL.createObjectURL(glb);
              if (!url) {
                return undefined;
              }
              model.url = url;
            } else {
              return undefined;
            }

            const png = await assetStore.getItem(
              `png-${model.userData.skuKey}`,
            );
            if (png) {
              const pngUrl = URL.createObjectURL(png);
              model.userData.map2dURL = pngUrl;
            }

            if (!model.userData.isMap) {
              model.userData.allowStacking = furnCheck.allowStacking;
              model.userData.isStackable = furnCheck.isStackable;
              model.userData.nonPrintable = furnCheck.nonPrintable;

              if (model.userData.isCube) {
                const sku = furnCheck.skus.find(
                  (s) => s.keyVal === model.userData.skuKey,
                );
                if (sku) {
                  model.userData.twoDLabel = sku.twoDLabel;
                  model.userData.twoDLabelColor = 'BLACK';
                }
              }
            }

            return model;
          }
        });

        const models = await Promise.all(replacePromises);
        mapToLoad.models = models.filter((m) => m !== undefined);

        await api.importScene(mapToLoad, IMPORT_OPTIONS);
        dispatch(mapActions.setMapLoaded(mapToLoad));
        setShowConfirm(false);
        history.push(PAGE_HOME);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Yes"
            onClick={() => loadMap()}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            disabled={loading}
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConfirmOpenContent />}
      buttons={<ConfirmOpenButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConfirmOpenModal.propTypes = {
  setShowConfirm: PropTypes.func.isRequired,
};
export default ConfirmOpenModal;
