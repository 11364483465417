import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { actions as userActions } from '../../reducers/user';
import * as UserSelector from '../../selectors/users';

import logo from '../../assets/images/restoration-hardware-logo-large.jpg';

import {
  CONFIRMATION_MISMATCH_ERROR_MSG,
  PASSWORD_REQUIREMENTS_ERROR_MSG,
  SET_PASSWORD_REQUIRED_ERROR_MSG,
} from '../../constants';

const ResetPasswordModal = ({ showModal }) => {
  const dispatch = useDispatch();

  const ResetPasswordHeader = () => {
    const handleCancelClick = () => {
      const clearedUser = { userObject: null, loggedIn: false };
      dispatch(userActions.setUser(clearedUser));
      showModal(false);
    };

    return (
      <button className="backBtn" onClick={handleCancelClick}>
        cancel
      </button>
    );
  };

  const ResetPasswordContent = () => (
    <>
      <h3>welcome to the</h3>
      <img className="large-logo" src={logo} alt="Resoration Hardware" />
      <h3>gallery planner</h3>
    </>
  );

  const ResetPasswordButtons = () => {
    const dispatch = useDispatch();
    const nameInputRef = useRef('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState();
    const currentUser = useSelector(UserSelector.getUser);
    const error = useSelector(UserSelector.getError);
    const loading = useSelector(UserSelector.getLoading);

    useEffect(() => {
      nameInputRef.current.value =
        currentUser?.userObject?.challengeParam?.userAttributes?.name;
    }, [currentUser]);

    const onHandleReset = async () => {
      var strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
      );

      if (!nameInputRef.current.value || !password) {
        dispatch(
          userActions.setError({ message: SET_PASSWORD_REQUIRED_ERROR_MSG }),
        );
      } else if (password !== confirmPassword) {
        dispatch(
          userActions.setError({ message: CONFIRMATION_MISMATCH_ERROR_MSG }),
        );
      } else if (!strongRegex.test(password)) {
        dispatch(
          userActions.setError({ message: PASSWORD_REQUIREMENTS_ERROR_MSG }),
        );
      } else {
        dispatch(
          userActions.setPasswordRequest({
            currentUser,
            password,
            name: nameInputRef.current.value,
          }),
        );
      }
    };

    return (
      <div className="frm__generic">
        <div className="instructions">Please Reset Password</div>
        <input
          type="password"
          className="password"
          placeholder="PASSWORD"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <input
          type="password"
          className="password"
          placeholder="CONFIRM PASSWORD"
          name="confirm_password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        />
        <input
          type="text"
          className="name"
          placeholder="FULL NAME"
          name="name"
          ref={nameInputRef}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <div className="error">{error?.message}</div>
        <ButtonLoader
          className="btn btnSubmitModal"
          type="submit"
          onClick={onHandleReset}
          text="SET PASSWORD"
          loading={loading}
        />
      </div>
    );
  };

  return (
    <Modal
      header={<ResetPasswordHeader />}
      content={<ResetPasswordContent />}
      buttons={<ResetPasswordButtons />}
      backButton
    />
  );
};

export default ResetPasswordModal;
