import categories from './categories';
import editSKU from './editSKU';
import maps from './maps';
import SKU from './sku';
import user from './user';
import furniture from './furniture';
import furnitureTypes from './furnitureTypes';
import subCategories from './subCategories';
import sidebar from './sidebar';
import serviceWorker from './serviceWorker';

const reducers = {
  categories,
  editSKU,
  maps,
  SKU,
  user,
  furniture,
  furnitureTypes,
  subCategories,
  sidebar,
  serviceWorker,
};

export default reducers;
