import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { actions as mapActions } from '../../reducers/maps';
import { PAGE_HOME, BASE_URL_ICON } from '../../constants';
import sofa from '../../assets/images/sofa.png';
import * as MapsSelector from '../../selectors/maps';

import './style.scss';

const MapPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const mapsById = useSelector(MapsSelector.getById);
  const { name, creator, createdOn, updatedAt, wallItems = [], skuList = [] } =
    mapsById[id] || {};

  useEffect(() => {
    const loadMaps = () => {
      dispatch(mapActions.getOneRequest({ id }));
    };
    loadMaps();
  }, [dispatch, id]);

  return (
    <>
      <section className="container map__page">
        <div className="map__page--header">
          <div className="map__page--sidebar">
            <div className="header__logoWrapper">
              <div className="logo" onClick={() => history.push(PAGE_HOME)}>
                RH
              </div>
              <div className="header__logoWrapper--store">
                <h1>RH DALLAS</h1>
                <h3>3133 Knox St Dallas, TX 75205</h3>
              </div>
            </div>
            <div className="info">
              <div className="infoWrapper">
                <div className="nameItem">{name}</div>
                <div className="createdByItem">
                  <span>created by</span> <small>{creator}</small>
                </div>
                <div className="createdOnItem">
                  <span>created</span> on{' '}
                  <small>
                    {createdOn && format(new Date(createdOn), 'MM/dd/yy')}
                  </small>
                </div>
                <div className="lastUpdatedItem">
                  <span>last updated</span>{' '}
                  <small>
                    {updatedAt && format(new Date(updatedAt), 'MM/dd/yy')}
                  </small>
                </div>
                {/* TEMP Remove Editors for POC
                <div className="editorsItem">
                  <strong>editors</strong>
                  <p>{editors.join(', ')}</p>
                </div>
                */}
              </div>
              <div className="wallItems">
                <div className="nameItem">WALL ITEMS</div>
                {Object.entries(wallItems).map((wallItem) => (
                  <div key={wallItem[0]} className="item">
                    <span>{wallItem[0]}</span> {wallItem[1]}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="map__page--map">
            <figure>
              <img src="https://placehold.it/1980x1000" alt="" />
            </figure>
          </div>
        </div>

        <h1>FURNITURE USED</h1>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>Used name</th>
              <th>Imported name</th>
              <th>2d abbrev. name</th>
              <th>Category</th>
              <th>Subcategory</th>
              <th>Sub-Subcategory</th>
              <th>Date Loaded</th>
              <th>Loaded by</th>
              <th>sku #</th>
            </tr>
          </thead>
          <tbody>
            {skuList.map(
              ({
                id,
                sku,
                usedName,
                importedName,
                twodAbbrevName,
                categoryName,
                subCategoryName,
                subSubCategoryName,
                dateLoaded,
                loadedBy,
                icon,
              }) => (
                <tr key={id}>
                  <td>
                    <img
                      src={icon ? `${BASE_URL_ICON}${icon}` : sofa}
                      alt=""
                      title=""
                    />
                  </td>
                  <td>{usedName}</td>
                  <td>{importedName}</td>
                  <td>{twodAbbrevName}</td>
                  <td>{categoryName}</td>
                  <td>{subCategoryName}</td>
                  <td>{subSubCategoryName}</td>
                  <td>{format(new Date(dateLoaded), 'MM/dd/yy')}</td>
                  <td>{loadedBy}</td>
                  <td>{sku}</td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default MapPage;
