const NAME = 'SERVICE_WORKER';

export const types = {
  INIT: `${NAME}/INIT`,
  UPDATE: `${NAME}/UPDATE`,
};

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case types.UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: payload.updated,
        serviceWorkerRegistration: payload.worker,
      };
    default:
      return state;
  }
};
