import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistCombineReducers } from 'redux-persist';
import localforage from 'localforage';

import reducers from './reducers';
import rootSaga from './sagas';

const rootReducer = persistCombineReducers(
  {
    key: 'root',
    storage: localforage,
  },
  reducers,
);

const middlewares = [];
const enhancers = [];

/* Saga */

const sagaMonitor = window.__DEV__ ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);
enhancers.push(applyMiddleware(...middlewares));

/* Create Store */

const createAppropriateStore = window.__DEV__
  ? console.tron.createStore
  : createStore;

export const store = createAppropriateStore(rootReducer, compose(...enhancers));

/* Redux-Persist + Store */

export const persistor = persistStore(store);

/* Run saga */

sagaMiddleware.run(rootSaga);
