import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { MASKS } from '@evolutionv/room-planner-3d';
import localforage from 'localforage';

import { APIContext } from '../../context/APIContext';
import ButtonLoader from '../ButtonLoader';
import * as FurnitureSelectors from '../../selectors/furniture';

import { IMPORT_OPTIONS, ASSET_STORE_NAME } from '../../constants';

const MassModelLoader = () => {
  const apiContext = useContext(APIContext);
  const furniture = useSelector(FurnitureSelectors.getById);
  const [loading, setLoading] = useState(false);
  const { api } = apiContext;

  const addModel = async (randomSku, furnitureId, intersections) => {
    let pngUrl = randomSku.png;
    let glbUrl = randomSku.file;

    const assetStore = localforage.createInstance({
      name: ASSET_STORE_NAME,
    });

    const glbBlob = await assetStore.getItem(`glb-${randomSku.keyVal}`);
    const pngBlob = await assetStore.getItem(`png-${randomSku.keyVal}`);
    if (glbBlob) glbUrl = URL.createObjectURL(glbBlob);
    if (pngBlob) pngUrl = URL.createObjectURL(pngBlob);

    const object = await api.loadModel(glbUrl, IMPORT_OPTIONS);
    const pickedObject = intersections[0].object;

    object.position.copy(intersections[0].point);

    object.userData = {
      ...object.userData,
      furnitureId,
      sku: randomSku.skuNum,
      skuKey: randomSku.keyVal,
      map2dURL: pngUrl,
    };

    if (pickedObject.name.toLowerCase().includes('piso')) {
      object.constraint = 'y';
    }

    if (pickedObject.name.toLowerCase().includes('ceiling')) {
      object.constraint = 'y';
      object.userData.isCeilingObject = true;
    }

    if (pickedObject.name.toLowerCase().includes('wall')) {
      const pickNormal = intersections[0].face.normal;
      object.userData.isWallObject = true;

      if (Math.abs(Math.abs(pickNormal.x) - 1) < 1e-5) {
        let angle = Math.sign(pickNormal.x) < 0 ? Math.PI : 0;
        object.rotateY(angle);
        object.constraint = 'x';
      }

      if (Math.abs(Math.abs(pickNormal.z) - 1) < 1e-5) {
        object.rotateY((Math.PI / 2) * Math.sign(pickNormal.z));
        object.constraint = 'z';
      }
    }

    api.addObject(object);
  };

  const loadMassModels = async () => {
    setLoading(true);
    const promisesToAwait = [];
    let added = 0;

    while (added <= 100) {
      const furnArr = Object.values(furniture);
      const randomFurn = furnArr[Math.floor(Math.random() * furnArr.length)];
      if (randomFurn) {
        const randomSku =
          randomFurn.skus[Math.floor(Math.random() * randomFurn.skus.length)];

        if (randomSku && randomSku.png && randomSku.file) {
          const position = {
            x: Math.random() * (-0.5 - 0.5) + 0.5,
            y: Math.random() * (-0.9 - 0.9) + 0.9,
          };
          const mask = MASKS.MASK_ALL & ~MASKS.MASK_CEILING;
          const intersections = api.rayCastScreen(position, mask);

          if (intersections.length !== 0) {
            const floorTestPass = !intersections[0].object.name
              .toLowerCase()
              .includes('piso');
            const wallTestPass = !intersections[0].object.name
              .toLowerCase()
              .includes('wall');

            const ceilingTestPass = !intersections[0].object.name
              .toLowerCase()
              .includes('ceiling');

            if (!wallTestPass || !floorTestPass || !ceilingTestPass) {
              promisesToAwait.push(
                addModel(randomSku, randomFurn.id, intersections),
              );
              added++;
            }
          }
        }
      }
    }

    await Promise.all(promisesToAwait);

    setLoading(false);
  };

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <ButtonLoader
      className="btn mass-loader-btn unselectable"
      onClick={loadMassModels}
      onPointerDown={onPointerDown}
      loading={loading}
      text="Load 100 Models"
      type="submit"
    />
  );
};

export default MassModelLoader;
