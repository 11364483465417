import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import * as he from 'he';

import FurnituresInfo from './furnituresInfo';
import MenuCategory from './menuCategory';
import MenuSubCategory from './menuSubCategory';
import { actions as furnitureActions } from '../../reducers/furniture';
import { actions as sidebarActions } from '../../reducers/sidebar';
import * as FurnitureTypeSelectors from '../../selectors/furnitureTypes';
import * as FurnitureSelectors from '../../selectors/furniture';
import * as SidebarSelectors from '../../selectors/sidebar';
import { APIContext } from '../../context/APIContext';

import { TOOLBAR_POS } from '../../constants';

const Furnitures = ({
  setOpenedSubMenu,
  openedSubMenu,
  selectedFurnitureRef,
  selectionUiPos,
  setSelectionUiPos,
  setFurnitureSize,
  isShortCeiling,
}) => {
  const dispatch = useDispatch();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const selectedFurnitureType = useSelector(FurnitureSelectors.getSelectedType);
  const furnitureTypesById = useSelector(FurnitureTypeSelectors.getTypesSorted);
  const isOpenedFromMap = useSelector(SidebarSelectors.getOpenedFromMap);
  const selectedCategory = useSelector(FurnitureSelectors.getSelectedCategory);
  const newSearchVal = useSelector(FurnitureSelectors.getSearchVal);
  const showFurnitureDetails = useSelector(
    FurnitureSelectors.getShowFurnitureDetails,
  );
  const isMenuOpened = useSelector(SidebarSelectors.getMenuOpened);
  const selectedSubCategory = useSelector(
    FurnitureSelectors.getSelectedSubCategory,
  );
  const selectedFurniture = useSelector(
    FurnitureSelectors.getSelectedFurniture,
  );
  const inputRef = useRef(null);

  useEffect(() => {
    batch(() => {
      dispatch(sidebarActions.setMenuOpened(false));
      dispatch(sidebarActions.setOpenedFromMap(false));
      dispatch(furnitureActions.setSelectedCategory(''));
      dispatch(furnitureActions.setSelectedType(''));
      dispatch(furnitureActions.setSelectedSubCategory(''));
      dispatch(furnitureActions.setSelectedFurniture(''));
      dispatch(furnitureActions.setShowFurnitureDetails(false));
      dispatch(furnitureActions.setSearchValue(''));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!newSearchVal) {
      inputRef.current.value = '';
    }
  }, [newSearchVal]);

  useEffect(() => {
    if (showSearchBar) {
      inputRef.current.focus();
    }
  }, [showSearchBar]);

  const categoryClick = (id) => {
    dispatch(furnitureActions.setSelectedType(id));
  };

  const clearSearchVal = () => {
    inputRef.current.value = '';
    dispatch(furnitureActions.setSearchValue(''));
  };

  const handleSearchChange = () => {
    if (inputRef.current.value.length >= 3) {
      dispatch(furnitureActions.setShowFurnitureDetails(true));
    } else if (!selectedSubCategory) {
      dispatch(furnitureActions.setShowFurnitureDetails(false));
    }

    dispatch(furnitureActions.setSearchValue(inputRef.current.value));
  };

  const handleSideBarKeyPress = (event) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleBackClick = () => {
    if (isOpenedFromMap) {
      batch(() => {
        dispatch(furnitureActions.setSelectedFurniture(null));
        dispatch(sidebarActions.setOpenedFromMap(false));
      });
      if (newSearchVal.length > 0) {
        setShowSearchBar(true);
      } else {
        setShowSearchBar(false);
        dispatch(furnitureActions.setShowFurnitureDetails(false));
      }
      selectedFurnitureRef.current = null;
    } else if (
      selectedFurnitureType &&
      !selectedSubCategory &&
      !showFurnitureDetails
    ) {
      dispatch(furnitureActions.setSelectedType(''));
      setShowSearchBar(false);
      clearSearchVal();
    } else if (
      selectedFurnitureType &&
      selectedSubCategory &&
      !selectedFurniture &&
      newSearchVal.length < 3
    ) {
      batch(() => {
        dispatch(furnitureActions.setSelectedCategory(''));
        dispatch(furnitureActions.setSelectedSubCategory(''));
        dispatch(furnitureActions.setSelectedFurniture(''));
        dispatch(furnitureActions.setShowFurnitureDetails(false));
      });
      clearSearchVal();
    } else if (selectedFurniture) {
      dispatch(furnitureActions.setSelectedFurniture(''));
    } else if (showFurnitureDetails) {
      dispatch(furnitureActions.setShowFurnitureDetails(false));
      clearSearchVal();
    }

    api.selectObject({}, {});
    setShowSearchBar(false);
    setSelectionUiPos(TOOLBAR_POS);
  };

  const searchIconClick = () => {
    if (!showFurnitureDetails && selectedFurnitureType) {
      setShowSearchBar(!showSearchBar);
    }
  };

  const closeSideBar = () => {
    dispatch(sidebarActions.setMenuOpened(!isMenuOpened));
  };

  return (
    <div
      className={`sidebar__content ${selectedFurniture ? 'overlayWhite' : ''}`}
    >
      <button
        onClick={closeSideBar}
        className={`sidebar__content--hiddenBtn hidden ${
          isMenuOpened ? '' : 'active'
        }`}
      />
      <div
        className={`sidebar__subMenuWrapper--search ${
          selectedFurnitureType || showFurnitureDetails ? '' : 'hidden'
        }`}
      >
        <div className="form hidden">
          <button className="btnBack" onClick={handleBackClick}>
            back
          </button>
          <button
            className={`btn ${
              showSearchBar || showFurnitureDetails || selectedFurniture
                ? 'hidden'
                : ''
            }`}
            onClick={searchIconClick}
          />
        </div>
      </div>
      <div
        className={`sidebar__subMenuWrapper--search ${
          (selectedFurnitureType && !showSearchBar) || selectedFurniture
            ? 'hidden'
            : ''
        }`}
      >
        <div className="form">
          <input
            key="search"
            type="text"
            placeholder="SEARCH (Name, SKU)"
            onChange={handleSearchChange}
            ref={inputRef}
            onKeyDown={handleSideBarKeyPress}
          />
          <button className="btn" onClick={searchIconClick} />
        </div>
      </div>
      {!showFurnitureDetails && !selectedFurniture && !selectedFurnitureType && (
        <>
          <button
            onClick={closeSideBar}
            className={`sidebar__content--hiddenBtn hidden ${
              isMenuOpened ? '' : 'active'
            }`}
          />
          <div className="sidebar__subMenuWrapper--items">
            {Object.values(furnitureTypesById).map((furn) => {
              return (
                <div
                  key={furn.id}
                  className="item"
                  onClick={() => categoryClick(furn.id)}
                >
                  {he.decode(furn.name || furn.label)}
                </div>
              );
            })}
          </div>
        </>
      )}
      {!showFurnitureDetails && selectedFurnitureType && !selectedCategory && (
        <MenuCategory
          openedSubMenu={openedSubMenu}
          setOpenedSubMenu={setOpenedSubMenu}
        />
      )}
      {((showFurnitureDetails && !selectedFurniture) ||
        (selectedSubCategory && !selectedFurniture)) && <MenuSubCategory />}
      {selectedFurniture && (
        <FurnituresInfo
          selectedFurnitureRef={selectedFurnitureRef}
          selectionUiPos={selectionUiPos}
          setSelectionUiPos={setSelectionUiPos}
          setFurnitureSize={setFurnitureSize}
          isShortCeiling={isShortCeiling}
        />
      )}
    </div>
  );
};

Furnitures.propTypes = {
  openedSubMenu: PropTypes.shape({
    1: PropTypes.bool,
    2: PropTypes.bool,
    3: PropTypes.bool,
    4: PropTypes.bool,
    5: PropTypes.bool,
    6: PropTypes.bool,
    7: PropTypes.bool,
    8: PropTypes.bool,
    9: PropTypes.bool,
  }).isRequired,
  setOpenedSubMenu: PropTypes.func.isRequired,
  isShortCeiling: PropTypes.bool.isRequired,
};

export default Furnitures;
