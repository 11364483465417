import { all, call, put, takeEvery } from 'redux-saga/effects';
import { types, actions as editSKUActions } from '../../reducers/editSKU';
import { actions as skuActions } from '../../reducers/sku';
import { getColors, getShapes, postImage } from '../../api/editSku';
import { getAllIds, getById } from '../../utils/responseData';

function* getRequest(action) {
  try {
    const { colorId, shapeId } = action.payload;
    const [responseColors, responseShapes] = yield all([
      call(getColors, colorId), call(getShapes, shapeId)
    ]);
    yield put(editSKUActions.requestSuccess({
      colors: {
        allIds: getAllIds(responseColors.data),
        byId: getById(responseColors.data)
      },
      shapes: {
        allIds: getAllIds(responseShapes.data),
        byId: getById(responseShapes.data)
      }
    }));
  } catch (error) {
    yield put(editSKUActions.error(error));
  }
}

function* uploadFile(action) {
  try {
    const { id, file } = action.payload;
    const { data: { filename } } = yield call(postImage, id, file);
    yield all([
      put(editSKUActions.uploadIcon({ icon: filename })),
      put(skuActions.setSKU(id, { icon: filename }))
    ]);
  } catch (error) {
    yield put(editSKUActions.error(error));
  }
}

export default function* watchEditSKU() {
  yield takeEvery(types.REQUEST, getRequest);
  yield takeEvery(types.UPLOAD_ICON_REQUEST, uploadFile);
}
