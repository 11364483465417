import API, { config } from '../API';

export const get = (dateFrom, token) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get(`/maps?dateFrom=${dateFrom}`, apiConf);
};

export const getByUser = (token) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get('/maps/user', apiConf);
};

export const getOne = (id) => API.get(`/maps/${id}/sku`);

export const post = (token, payload) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.post('/maps', payload, apiConf);
};

export const getDirty = (token, id, updatedAt) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get(`/maps/${id}/dirty?updated=${updatedAt}`, apiConf);
};

export const createPdf = (token, id, payload) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.post(`/maps/${id}/createMapPdf`, payload, apiConf);
};

export const patch = (token, payload, forceOverwrite) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.patch(
    `/maps/${payload.id}?force=${forceOverwrite}`,
    payload,
    apiConf,
  );
};

export const remove = (token, id) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.delete(`/maps/${id}`, apiConf);
};

export const jsonAPIBody = (
  usedName,
  importedName,
  twodAbbrevName,
  categoryId,
  subCategoryId,
  subSubcategoryId,
  colorsId,
  sizesId,
) => ({
  usedName,
  importedName,
  twodAbbrevName,
  categoryId,
  subCategoryId,
  subSubcategoryId,
  colorsId,
  sizesId,
});
