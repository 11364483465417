import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';

import App from './App';
import { types } from './reducers/serviceWorker';
import CustomToastContainer from './components/CustomToastContainer';
import { store, persistor } from './store';
import './styles/index.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={4000}
          placement="top-right"
          components={{ ToastContainer: CustomToastContainer }}
        >
          <App />
        </ToastProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: types.INIT }),
  onUpdate: (reg) => {
    store.dispatch({
      type: types.UPDATE,
      payload: { updated: true, worker: reg },
    });
    console.log('needs service worker update');
  },
});
