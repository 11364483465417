import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import localforage from 'localforage';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

import Modal from '../../components/Modal';
import floorImage from '../../assets/images/rh-map.jpg';
import * as MapsSelector from '../../selectors/maps';
import { actions as mapActions } from '../../reducers/maps';
import { APIContext } from '../../context/APIContext';
import * as FurnitureSelectors from '../../selectors/furniture';

import { IMPORT_OPTIONS, ASSET_STORE_NAME } from '../../constants';

const PlansModal = ({ setStartSavedPlan, showModal }) => {
  const PlansHeader = () => (
    <>
      <button className="backBtn" onClick={() => setStartSavedPlan(false)}>
        back
      </button>
      <div className="store">
        <h1>RH DALLAS</h1>
        <h3>3133 Knox St</h3>
        <h3>Dallas, TX 75205</h3>
      </div>
    </>
  );

  const PlansContent = () => <h2>select a saved plan</h2>;

  const PlansPlans = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMapId, setLoadingMapId] = useState('');
    const mapsAllIds = useSelector(MapsSelector.getAllIds);
    const mapsById = useSelector(MapsSelector.getById);
    const furniture = useSelector(FurnitureSelectors.getById);
    const apiContext = useContext(APIContext);
    const { api } = apiContext;

    useEffect(() => {
      setIsLoading(false);
      setLoadingMapId('');
    }, []);

    const handleSavedPlanClick = async (planId) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      setLoadingMapId(planId);
      const map = mapsById[planId];
      const mapToLoad = map.scene;
      try {
        const assetStore = localforage.createInstance({
          name: ASSET_STORE_NAME,
        });

        const replacePromises = mapToLoad.models.map(async (model) => {
          const furnCheck = Object.values(furniture).find(
            (f) => f.id === model.userData.furnitureId,
          );
          if (furnCheck || model.userData.isMap) {
            const searchGlbKey = model.userData.isCube
              ? `BLANK_BLOB_FLOOR`
              : `glb-${model.userData.skuKey}`;
            const glb = await assetStore.getItem(searchGlbKey);
            if (glb) {
              const url = URL.createObjectURL(glb);
              if (!url) {
                return undefined;
              }
              model.url = url;
            } else {
              return undefined;
            }

            const png = await assetStore.getItem(
              `png-${model.userData.skuKey}`,
            );
            if (png) {
              const pngUrl = URL.createObjectURL(png);
              model.userData.map2dURL = pngUrl;
            }

            if (!model.userData.isMap) {
              model.userData.allowStacking = furnCheck.allowStacking;
              model.userData.isStackable = furnCheck.isStackable;
              model.userData.nonPrintable = furnCheck.nonPrintable;

              if (model.userData.isCube) {
                const sku = furnCheck.skus.find(
                  (s) => s.keyVal === model.userData.skuKey,
                );
                if (sku) {
                  model.userData.twoDLabel = sku.twoDLabel;
                  model.userData.twoDLabelColor = 'BLACK';
                }
              }
            }

            return model;
          }
        });

        const models = await Promise.all(replacePromises);
        mapToLoad.models = models.filter((m) => m !== undefined);

        await api.importScene(mapToLoad, IMPORT_OPTIONS);
        setStartSavedPlan(false);
        dispatch(mapActions.setMapLoaded(map));
        showModal(false);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div
        className={classNames('wrapper', {
          loadingWrapper: isLoading,
        })}
      >
        <div className="content">
          {mapsAllIds.map((id) => {
            const { name, city, creator, createdOn, updatedAt } = mapsById[id];
            return (
              <div
                key={id}
                className={classNames('planItem', {
                  planItemNotLoading: !isLoading,
                  planItemLoading: isLoading,
                  selectedPlanLoading: loadingMapId === id,
                })}
                onClick={() => handleSavedPlanClick(id)}
              >
                {isLoading && loadingMapId === id && (
                  <CircularProgress size={50} />
                )}
                <div className="thumbnail">
                  <img
                    className="floorPlanImg"
                    src={floorImage}
                    alt=""
                    title=""
                  />
                </div>
                <div className="info">
                  <div className="infoWrapper">
                    <span className="nameItem">{name}</span>
                    <span className="localItem">{city}</span>
                    <span className="createdByItem">
                      created by <small>{creator}</small>
                    </span>
                    <span className="createdOnItem">
                      created on{' '}
                      <small>{format(new Date(createdOn), 'MM/dd/yy')}</small>
                    </span>
                    <span className="lastUpdatedItem">
                      last updated{' '}
                      <small>{format(new Date(updatedAt), 'MM/dd/yy')}</small>
                    </span>
                    {/* TEMP Remove editors for POC.
                    <span className="editorsItem">
                      <button className="editBtn" />
                      <strong>editors</strong>
                      <p>{editors.join(', ')}</p>
                    </span>
                    */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      backButton
      header={<PlansHeader />}
      content={<PlansContent />}
      plans={<PlansPlans />}
    />
  );
};

PlansModal.propTypes = {
  setStartSavedPlan: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default PlansModal;
