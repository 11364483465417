import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import localforage from 'localforage';

import { ASSET_STORE_NAME, THUMBNAIL_SCALE } from '../../constants';

const DraggableFurniture = ({ furniture }) => {
  const defaultSku = furniture.skus.find((sku) => sku.default);
  const allowedPlacements = furniture.placementType;
  const isStackable = furniture.isStackable;
  const allowStacking = furniture.allowStacking;
  const nonPrintable = furniture.nonPrintable;
  const [image, setImage] = useState();

  const [isDragging, drag] = useDrag({
    item: {
      type: 'FURNITURE',
      id: furniture.id,
      sku: defaultSku,
      allowedPlacements,
      isStackable,
      allowStacking,
      nonPrintable,
      twoDLabelColor: furniture.twoDLabelColor,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    let isSubscribed = true;
    const getImage = async () => {
      const assetStore = localforage.createInstance({
        name: ASSET_STORE_NAME,
      });

      const imageBlob = await assetStore.getItem(
        `furnitureImage-${furniture.id}`,
      );
      if (isSubscribed) {
        if (imageBlob) {
          const imageUrl = URL.createObjectURL(imageBlob);
          setImage(imageUrl);
        } else {
          setImage(
            `${
              process.env.REACT_APP_THUMBNAIL_BASE_URL
                ? process.env.REACT_APP_THUMBNAIL_BASE_URL
                : ''
            }${furniture.image}/${THUMBNAIL_SCALE}?v=${Date.now()}`,
          );
        }
      }
    };
    getImage();
    return () => (isSubscribed = false);
  }, [furniture.id, furniture.image]);

  return (
    <>
      <div className="preview-container">
        <img
          src={image}
          style={{
            opacity: isDragging.isDragging ? 0.5 : 1,
          }}
          className="preview-image"
          alt={furniture.name || furniture.label}
        />
        <div
          draggable={true}
          id="overlay_text"
          className="overlay_text"
          ref={drag}
          style={{
            cursor: 'move',
          }}
        ></div>
      </div>
    </>
  );
};

export default DraggableFurniture;
