import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import * as FurnitureSelector from '../../selectors/furniture';
import { actions as furnitureActions } from '../../reducers/furniture';

const ConfirmFurnitureDeleteModal = ({ furnToDelete, setShowConfirm }) => {
  const ConfirmFurnitureDeleteContent = () => (
    <div className="overwriteModal">
      Delete '{furnToDelete?.name || furnToDelete?.label}'. Are you sure?
    </div>
  );

  const ConfirmFurnitureDeleteButtons = () => {
    const dispatch = useDispatch();
    const loading = useSelector(FurnitureSelector.getLoading);

    const deleteFurn = async () => {
      dispatch(furnitureActions.deleteRequest(furnToDelete.id));
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Yes"
            onClick={deleteFurn}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConfirmFurnitureDeleteContent />}
      buttons={<ConfirmFurnitureDeleteButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConfirmFurnitureDeleteModal.propTypes = {
  furnToDelete: PropTypes.object.isRequired,
  setShowConfirm: PropTypes.func.isRequired,
};
export default ConfirmFurnitureDeleteModal;
