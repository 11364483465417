// paths
export const PAGE_HOME = '/';
export const PAGE_SKULIST = '/skulist';
export const PAGE_MY_ACCOUNT = '/my-account';
export const PAGE_MAP = '/map';

// save modal
export const SAVE_MENU = '0';
export const SAVE_AS_NEW = '1';
export const SAVE_OVERWRITE = '2';

// sidebar
export const SIDEBAR_MENU = '0';

export const SIDEBAR_TAB_FURNITURES = '0';
export const SIDEBAR_TAB_MAPS = '1';
export const SIDEBAR_TAB_STORES = '2';

// API
export const BASE_URL_ICON = process.env.REACT_APP_API_ENDPOINT_ICON;

// User States
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

// Login Errors
export const LOGIN_REQUIRED_ERROR_MSG = 'Email and Password are required';
export const SET_PASSWORD_REQUIRED_ERROR_MSG = 'Name and Password are required';
export const CONFIRMATION_MISMATCH_ERROR_MSG =
  'Confirmation Password Does Not Match';
export const PASSWORD_REQUIREMENTS_ERROR_MSG =
  'Password must be at least 8 characters long, contain at least 1 number, at least 1 upper case letter, at least 1 lower case letter, and at least 1 special character';
export const PASSWORD_RESET_ERROR_MSG = 'Email is Required';
export const FORGOT_PASS_REQUIRED_MSG = 'Code and Password are required';
export const CHANGE_PASS_REQUIRED_MSG =
  'Current Password and Old Password are required';
export const CHANGE_NAME_REQUIRED_MSG = 'Name is required';

export const FPC_HEIGHT_OFFSET = 0.2;
export const MAX_POLOAR_ANGLE = (0.9 * Math.PI) / 2;

// Map Toolbar
export const ROTATION_INCREMENT = 1;
export const CAMERA_ROTATION = Math.PI / 2;
export const ZOOM_INCREMENT = 120;
export const FOV_ZOOM_INCREMENT = 5;
export const DEFAULT_FOV = 45;
export const UP_VECTORS = [
  { x: 0, y: 0, z: 1 },
  { x: -1, y: 0, z: 0 },
  { x: 0, y: 0, z: -1 },
  { x: 1, y: 0, z: 0 },
];
export const ROOM_NAMES = [
  '101',
  '102',
  '103',
  '104',
  '105',
  '106',
  '107',
  '108',
  '109',
  '110',
  '111',
  '112',
  '113',
  '114A',
  '114B',
  '115',
  '116A',
  '116B',
  '117',
  '118',
  '119',
  '120',
  '121',
  '122',
  '123',
  '124',
  '125',
  '126',
  '127',
  '128',
  '129',
  '130',
  '131',
  '132',
  '133',
  '134',
  '135',
  '136',
  '137',
];

// Color Picker
export const HEXES = [
  '#E02020',
  '#FA6400',
  '#F7B500',
  '#6DD400',
  '#44D7B6',
  '#32C5FF',
  '#717171',
];

// MODEL TOOLBAR
export const MODEL_TOOLBAR_HEIGHT = 288;
export const MODEL_TOOLBAR_WIDTH = 48;
export const TRANSLATE_MODE = 'translate';
export const ROTATE_MODE = 'rotate';
export const TOOLBAR_POS = {
  left: 0,
  top: 0,
};

export const PNG_SNAP_QUALITY = 4;
export const PNG_PRINT_QUALITY = 2;
export const PNG_RENDER_SIZE = 1600;
export const MODEL_SCALE = 39.37008017832114;

export const THUMBNAIL_SCALE = '119x116x40';

// 3D DEFAULT IMPORT OPTIONS
export const IMPORT_OPTIONS = {
  extension: 'glb',
};

// OFFLINE
export const ASSET_STORE_NAME = 'assets';
// OFFLINE ERRORS
export const OFFLINE_ERROR_MYACCOUNT =
  'You cannot manage your account without an internet connection! Please connect and try again.';
export const OFFLINE_ERROR_PDF =
  'You cannot generate a PDF without an internet connection! Please connect and try again.';
export const OFFLINE_ERROR_CSV =
  'You cannot generate a CSV without an internet connection! Please connect and try again.';
export const OFFLINE_ERROR_SKU_LIST =
  'You cannot manage SKUs without an internet connection! Please connect and try again.';
export const OFFLINE_ERROR_SAVE =
  'You cannot save a map without an internet connection! Please connect and try again.';

export const API_MAP_CONCURRENCY_ERROR =
  'Another User Has Updated this resource.';
