import { useCallback } from 'react';
import { Vector3 } from 'three';
import * as fractional from 'fractional';

export const useGetDimensions = () => {
  const getDimension = useCallback(
    (api, rootObject, inputSku, allowedPlacements) => {
      const furnitureSize = {};
      const box = api.getBoundingBox(rootObject);
      const size = new Vector3();
      const unitMarker = inputSku.units === 'FEET' ? "'" : '"';
      const divisor = inputSku.units === 'FEET' ? 12 : 1;
      const width = new fractional.Fraction(inputSku.width / divisor);
      const height = new fractional.Fraction(inputSku.height / divisor);
      const depth = new fractional.Fraction(inputSku.depth / divisor);

      box.getSize(size);

      if (
        allowedPlacements.includes('WALL') &&
        !inputSku.diameter &&
        !inputSku.square
      ) {
        if (size.x > size.y) {
          furnitureSize.width = width.toString() + unitMarker;
          furnitureSize.length = height.toString() + unitMarker;
        } else {
          furnitureSize.width = height.toString() + unitMarker;
          furnitureSize.length = width.toString() + unitMarker;
        }
      } else if (!inputSku.diameter && !inputSku.square) {
        if (size.x > size.z) {
          furnitureSize.width = width.toString() + unitMarker;
          furnitureSize.length = depth.toString() + unitMarker;
        } else {
          furnitureSize.width = depth.toString() + unitMarker;
          furnitureSize.length = width.toString() + unitMarker;
        }
      } else if (inputSku.diameter) {
        const diameter = new fractional.Fraction(inputSku.diameter);
        furnitureSize.diameter = `${diameter.toString() + unitMarker} diam.`;
      } else if (inputSku.square) {
        const square = new fractional.Fraction(inputSku.square);
        furnitureSize.square = `${square.toString() + unitMarker} sq.`;
      }

      furnitureSize.units = inputSku.units;

      return furnitureSize;
    },
    [],
  );
  return { getDimension };
};
