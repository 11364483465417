import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal';

const FurntiureSaveErrorModal = ({ message, showError }) => {
  const FurntiureSaveErrorContent = () => (
    <div className="overwriteModal">{message}</div>
  );

  const FurntiureSaveErrorButtons = () => {
    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => showError(false)}
          >
            OK
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<FurntiureSaveErrorContent />}
      buttons={<FurntiureSaveErrorButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

FurntiureSaveErrorModal.propTypes = {
  message: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
};
export default FurntiureSaveErrorModal;
