import { get } from 'lodash';
import { createSelector } from 'reselect';
import * as UserSelectors from './users';

const root = (state) => get(state, 'maps');

export const getAllIds = (state) => get(root(state), 'allIds', false);
export const getById = (state) => get(root(state), 'byId', false);
export const getError = (state) => get(root(state), 'error', false);
export const getLoading = (state) => get(root(state), 'isFetching', false);
export const getMapToOverwrite = (state) =>
  get(root(state), 'mapToOverwrite', false);
export const getSaveComplete = (state) =>
  get(root(state), 'saveComplete', false);
export const getDeleteComplete = (state) =>
  get(root(state), 'deleteComplete', false);
export const getPdfComplete = (state) =>
  get(root(state), 'getPdfComplete', false);
export const getPdfUrl = (state) => get(root(state), 'mapPdfUrl', false);
export const getLoadedMap = (state) => get(root(state), 'loadedMap', false);
export const getFetchingPdf = (state) => get(root(state), 'fetchingPdf', false);
export const getLastUpdate = (state) => get(root(state), 'lastUpdate', false);
export const getConcurrentWrite = (state) =>
  get(root(state), 'concurrentWrite', false);
export const getisDirty = (state) => get(root(state), 'isDirty', false);
export const getMapsForUser = createSelector(
  [getById, UserSelectors.getUser],
  (allMaps, user) => {
    const newMaps = Object.values(allMaps).filter((map) => {
      return map.modifiers?.includes(user.userObject.attributes.sub);
    });
    return newMaps;
  },
);
