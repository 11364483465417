import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import { actions as userActions } from '../../reducers/user';
import * as UserSelector from '../../selectors/users';

const ForgotPasswordResetModal = () => {
  const ForgotPasswordResetContent = () => {
    const forgotPassEmail = useSelector(UserSelector.getForgotPassEmail);

    return (
      <>
        <div className="instructions">
          An Email was sent to {forgotPassEmail} with instructions on how to
          reset your password.
        </div>
      </>
    );
  };

  const ForgotPasswordResetButtons = () => {
    const dispatch = useDispatch();

    const onOkClick = async () => {
      dispatch(userActions.setChangingPass(false));
    };

    return (
      <div className="frm__generic">
        <button
          className="btn btnSubmitModal"
          type="submit"
          onClick={onOkClick}
        >
          OK
        </button>
      </div>
    );
  };

  return (
    <Modal
      content={<ForgotPasswordResetContent />}
      buttons={<ForgotPasswordResetButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

export default ForgotPasswordResetModal;
