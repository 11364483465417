import { call, put, takeEvery, all } from 'redux-saga/effects';
import { types, actions as userActions } from '../../reducers/user';
import {
  login,
  logout,
  completeNewPassword,
  refreshAuth,
  resetPassword,
  updateName,
  userChangePassword,
  jsonAPIBody,
  jsonPassBody,
  jsonSetNameBody,
  jsonChangePassBody,
} from '../../auth/user';
import { forgotPassword } from '../../api/user';

function* logIn(action) {
  try {
    const { email, password } = action.payload;
    const reqBody = jsonAPIBody(email, password);
    const userObject = yield call(login, reqBody);
    const user = { userObject, loggedIn: true };
    yield put(userActions.setUser(user));
    yield put(userActions.setError({}));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* refreshAuthRequest() {
  try {
    const userObject = yield call(refreshAuth);
    const user = { userObject, loggedIn: true };
    yield put(userActions.setUser(user));
    yield put(userActions.setError({}));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* completePassword(action) {
  try {
    const { currentUser, password, name } = action.payload;
    const reqBody = jsonPassBody(currentUser.userObject, password, name);
    yield call(completeNewPassword, reqBody);
    const userObject = yield call(refreshAuth);
    const user = { userObject, loggedIn: true };
    yield put(userActions.setUser(user));
    yield put(userActions.setError({}));
    yield put(userActions.setPasswordUpdateComplete(true));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* requestPasswordReset(action) {
  try {
    const resp = yield call(forgotPassword, action.payload);

    if (resp.status === 200) {
      yield all([
        put(userActions.setForgotPassEmail(action.payload.email)),
        put(userActions.setRequestCode(false)),
        put(userActions.setChangingPass(true)),
        put(userActions.setError({})),
      ]);
    } else {
      yield put(userActions.setError(resp.data));
    }
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* updateNameRequest(action) {
  try {
    const { name } = action.payload;
    const preUserObject = yield call(refreshAuth);
    const reqBody = jsonSetNameBody(preUserObject, name);
    yield call(updateName, reqBody);
    const userObject = yield call(refreshAuth);
    const user = { userObject, loggedIn: true };
    yield put(userActions.setUser(user));
    yield put(userActions.setError({}));
    yield put(userActions.setNameUpdateComplete(true));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* changePasswordRequest(action) {
  try {
    const { oldPassword, password } = action.payload;
    const preUserObject = yield call(refreshAuth);
    const reqBody = jsonChangePassBody(preUserObject, oldPassword, password);
    yield call(userChangePassword, reqBody);
    const userObject = yield call(refreshAuth);
    const user = { userObject, loggedIn: true };
    yield put(userActions.setUser(user));
    yield put(userActions.setChangingPass(false));
    yield put(userActions.setError({}));
    yield put(userActions.setPasswordUpdateComplete(true));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* completeForgotPassword(action) {
  try {
    yield call(resetPassword, action.payload);
    yield put(userActions.setChangingPass(false));
    yield put(userActions.setError({}));
    yield put(userActions.setPasswordUpdateComplete(true));
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

function* logOut() {
  try {
    yield all([call(logout), put(userActions.logOut())]);
  } catch (error) {
    yield put(userActions.setError(error));
  }
}

export default function* watchUser() {
  yield takeEvery(types.LOG_IN_REQUEST, logIn);
  yield takeEvery(types.PASSWORD_REQUEST, completePassword);
  yield takeEvery(types.LOG_OUT_REQUEST, logOut);
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, requestPasswordReset);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, completeForgotPassword);
  yield takeEvery(types.UPDATE_NAME_REQUEST, updateNameRequest);
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
  yield takeEvery(types.REFRESH_AUTH_REQUEST, refreshAuthRequest);
}
