import { get } from 'lodash';
import { createSelector } from 'reselect';

import * as CategorySelectors from './categories';

const root = (state) => get(state, 'subCategories');

export const getAllIds = (state) => get(root(state), 'allIds', false);
export const getById = (state) => get(root(state), 'byId', false);

export const getSorted = createSelector(
  [getById, CategorySelectors.getById, CategorySelectors.getSelected],
  (byId, catsById, selectedCat) => {
    if (catsById[selectedCat]) {
      const cats = catsById[selectedCat].subCategories.map((id) => {
        return byId[id];
      });
      const sorted = Object.values(cats).sort((a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      return sorted;
    }
    return [];
  },
);
