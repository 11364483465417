import { call, put, takeEvery } from 'redux-saga/effects';
import {
  types,
  actions as subCategoryActions,
} from '../../reducers/subCategories';
import { getSession } from '../../auth/user';
import { get } from '../../api/subCategories';
import { getAllIds, getById } from '../../utils/responseData';

function* getSubCategories() {
  try {
    const session = yield call(getSession);
    const response = yield call(get, session.idToken.jwtToken);
    const { data } = response;
    yield put(
      subCategoryActions.setSubCategories({
        allIds: getAllIds(data),
        byId: getById(data),
      }),
    );
  } catch (error) {
    yield put(subCategoryActions.error(error));
  }
}

export default function* watchSubCategories() {
  yield takeEvery(types.GET_REQUEST, getSubCategories);
}
