import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

const NotAllowedOfflineModal = ({ showModal, text }) => {
  const NotAllowedOfflineContent = () => (
    <div style={{ whiteSpace: 'pre-line' }}>{text}</div>
  );

  const NotAllowedOfflineButtons = () => {
    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => showModal(false)}
          >
            OK
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<NotAllowedOfflineContent />}
      buttons={<NotAllowedOfflineButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="offlineWarning"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

NotAllowedOfflineModal.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default NotAllowedOfflineModal;
