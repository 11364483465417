import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { actions as userActions } from '../../reducers/user';
import * as UserSelector from '../../selectors/users';

import {
  CHANGE_PASS_REQUIRED_MSG,
  CONFIRMATION_MISMATCH_ERROR_MSG,
  PASSWORD_REQUIREMENTS_ERROR_MSG,
} from '../../constants';

const ChangePasswordModal = () => {
  const ChangePasswordHeader = () => {
    const dispatch = useDispatch();
    const handleCloseClick = () => {
      dispatch(userActions.setError({}));
      dispatch(userActions.setChangingPass(false));
    };

    return (
      <>
        <button onClick={handleCloseClick} className="btnClose" />
      </>
    );
  };

  const ChangePasswordContent = () => (
    <>
      <h3>change password</h3>
    </>
  );

  const ChangePasswordButtons = () => {
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const error = useSelector(UserSelector.getError);
    const loading = useSelector(UserSelector.getLoading);

    const onHandleTriggerChange = async () => {
      var strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
      );

      if (!oldPassword || !password || !confirmPassword) {
        dispatch(userActions.setError({ message: CHANGE_PASS_REQUIRED_MSG }));
      } else if (password !== confirmPassword) {
        dispatch(
          userActions.setError({ message: CONFIRMATION_MISMATCH_ERROR_MSG }),
        );
      } else if (!strongRegex.test(password)) {
        dispatch(
          userActions.setError({ message: PASSWORD_REQUIREMENTS_ERROR_MSG }),
        );
      } else {
        dispatch(userActions.changePasswordRequest(oldPassword, password));
      }
    };

    return (
      <div className="frm__generic">
        <div className="instructions">
          Please enter your current password and your new password.
        </div>
        <input
          type="password"
          name="oldPassword"
          placeholder="CURRENT PASSWORD"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.currentTarget.value)}
        />
        <input
          type="password"
          className="password"
          placeholder="NEW PASSWORD"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <input
          type="password"
          className="password"
          placeholder="CONFIRM NEW PASSWORD"
          name="confirm_password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        />
        <div className="error">{error?.message}</div>
        <ButtonLoader
          className="btn btnSubmitModal"
          type="submit"
          onClick={onHandleTriggerChange}
          text="Change"
          loading={loading}
        />
      </div>
    );
  };

  return (
    <Modal
      header={<ChangePasswordHeader />}
      content={<ChangePasswordContent />}
      buttons={<ChangePasswordButtons />}
    />
  );
};

export default ChangePasswordModal;
