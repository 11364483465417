import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useToasts } from 'react-toast-notifications';
import localforage from 'localforage';

import { APIContext } from '../../context/APIContext';
import ButtonLoader from '../../components/ButtonLoader';
import * as CategorySelectors from '../../selectors/categories';
import * as SubCategorySelectors from '../../selectors/subCategories';
import * as FurnitureTypeSelectors from '../../selectors/furnitureTypes';
import * as FurnitureSelectors from '../../selectors/furniture';
import { actions as furnitureActions } from '../../reducers/furniture';
import FurntiureSaveErrorModal from '../Modals/furnitureSaveError';

import {
  THUMBNAIL_SCALE,
  ASSET_STORE_NAME,
  IMPORT_OPTIONS,
} from '../../constants';

const Sidebar = ({ isOpen, setIsOpen, sku, loadData }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const BASE_URL_ICON = process.env.REACT_APP_ASSET_BASE_URL;
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [imageName, setImageName] = useState({});
  const [pngName, setPngName] = useState([{}]);
  const [isNew, setIsNew] = useState(true);

  const furnitureTypesById = useSelector(FurnitureTypeSelectors.getById);
  const categoriesById = useSelector(CategorySelectors.getById);
  const subCategoriesById = useSelector(SubCategorySelectors.getById);
  const isLoading = useSelector(FurnitureSelectors.getLoading);
  const error = useSelector(FurnitureSelectors.getError);
  const saveComplete = useSelector(FurnitureSelectors.getSaveComplete);
  const furniture = useSelector(FurnitureSelectors.getById);

  const defaultFurnitureType =
    furnitureTypesById[Object.keys(furnitureTypesById)[0]];
  const defaultCategory =
    defaultFurnitureType && categoriesById[defaultFurnitureType.categories[0]];

  let filterCatArr = defaultFurnitureType && [
    ...defaultFurnitureType.categories,
  ];
  let filterSubCatArr = defaultCategory && [...defaultCategory.subCategories];

  const [categoryFilteredArray, setCategoryFilteredArray] = useState(
    filterCatArr,
  );
  const [subCategoryFilteredArray, setSubCategoryFilteredArray] = useState(
    filterSubCatArr,
  );

  const [formFurniture, setFormFurniture] = useState({
    name: '',
    city: '',
    furnitureType: '',
    category: '',
    subCategory: '',
    skus: [],
    placementType: '',
    details: '',
    description: '',
    image: '',
    allowStacking: true,
    isStackable: true,
    nonPrintable: true,
  });

  useEffect(() => {
    const onLoadSidebar = () => {
      if (loadData) {
        if (sku) {
          let skus = {};

          const newPngNames = [];

          sku.skus.forEach((s) => {
            const tempId = uuidv4();
            const newSku = {
              file: s.file,
              png: s.png,
              keyVal: s.keyVal,
              skuNum: s.skuNum,
              tempId: tempId,
              twoDLabel: s.twoDLabel || '',
              size: parseDimensions(s.options),
            };
            skus = {
              ...skus,
              [tempId]: { ...newSku },
            };
            newPngNames.push({
              name: s.png,
              url: s.png,
            });
          });

          let details = '';
          if (sku.details) {
            details = sku.details.join('\n');
          }

          setFormFurniture({
            id: sku.id,
            name: sku.name,
            furnitureType: sku.furnitureType[0],
            category: sku.category[0],
            subCategory: sku.subCategory[0],
            city: sku.city,
            description: sku.description,
            details: details,
            image: sku.image,
            placementType: sku.placementType[0] || '',
            skus: skus,
            isStackable: sku.isStackable,
            allowStacking: sku.allowStacking,
            nonPrintable: sku.nonPrintable,
          });

          setPngName(newPngNames);
          setImageName({ name: sku?.image, url: sku?.image });
          //setModelName(sku?.sku?.file);
        }
        setIsNew(false);
      } else {
        setFormFurniture({
          sizes: [],
          allowStacking: true,
          isStackable: true,
          nonPrintable: true,
        });
        setIsNew(true);
      }
    };
    onLoadSidebar();
  }, [dispatch, sku, loadData]);

  const handleInputChange = (name, value) => {
    setFormFurniture({
      ...formFurniture,
      [name]: value,
    });
  };

  const handleSkuNumChange = (value, tempId) => {
    let sku = Object.values(formFurniture.skus).find(
      (s) => s.tempId === tempId,
    );
    if (sku) {
      sku.skuNum = value;

      const newSkus = {
        ...formFurniture.skus,
        [tempId]: { ...sku },
      };

      setFormFurniture({ ...formFurniture, skus: newSkus });
    }
  };

  const handleSkuLabelChange = (value, tempId) => {
    let sku = Object.values(formFurniture.skus).find(
      (s) => s.tempId === tempId,
    );
    if (sku) {
      sku.twoDLabel = value;

      const newSkus = {
        ...formFurniture.skus,
        [tempId]: { ...sku },
      };

      setFormFurniture({ ...formFurniture, skus: newSkus });
    }
  };

  const changeFurnitureType = (name, value) => {
    setFormFurniture({
      ...formFurniture,
      furnitureType: value,
      category: '',
      subCategory: '',
    });
  };

  const changePlacementType = (value) => {
    setFormFurniture({
      ...formFurniture,
      placementType: value,
    });
  };

  const changeCategory = (name, value) => {
    setFormFurniture({
      ...formFurniture,
      category: value,
      subCategory: '',
    });
  };

  const changeSubCategory = (name, value) => {
    setFormFurniture({
      ...formFurniture,
      subCategory: value,
    });
  };

  useEffect(() => {
    if (furnitureTypesById[formFurniture.furnitureType]) {
      let filterCatArr = [
        ...furnitureTypesById[formFurniture.furnitureType]?.categories,
      ];
      setCategoryFilteredArray(filterCatArr);

      if (filterCatArr?.length > 0) {
        let filterSubCatArr = [
          ...categoriesById[filterCatArr[0]].subCategories,
        ];
        setSubCategoryFilteredArray(filterSubCatArr);
      }
    } else {
      setSubCategoryFilteredArray([]);
      setCategoryFilteredArray([]);
    }
  }, [formFurniture.furnitureType, furnitureTypesById, categoriesById]);

  useEffect(() => {
    let filterSubCatArr = [];
    if (categoriesById[formFurniture.category]) {
      filterSubCatArr = [
        ...categoriesById[formFurniture.category].subCategories,
      ];
    }
    setSubCategoryFilteredArray(filterSubCatArr);
  }, [formFurniture.category, categoriesById]);

  useEffect(() => {
    const updateMap = async (assetStore, s, thisSkuObj, furnImage, furnId) => {
      console.log(s.twoDLabel);

      const furnFile = await fetch(
        `${
          process.env.REACT_APP_THUMBNAIL_BASE_URL
            ? process.env.REACT_APP_THUMBNAIL_BASE_URL
            : ''
        }${furnImage}/${THUMBNAIL_SCALE}?v=${Date.now()}`,
      );

      const furnBlob = await furnFile.blob();
      await assetStore.setItem(`furnitureImage-${furnId}`, furnBlob);

      const currObjects = api.getObjectBySkuKey(s.keyVal);
      if (currObjects && currObjects.length > 0) {
        currObjects.map(async (currObject) => {
          const rootObject = api.getRootObject(currObject);
          if (rootObject) {
            const searchGlbKey = rootObject.userData.isCube
              ? `BLANK_BLOB_FLOOR`
              : `glb-${s.keyVal}`;

            const pngFile = await fetch(
              `${
                process.env.REACT_APP_ASSET_BASE_URL
                  ? process.env.REACT_APP_ASSET_BASE_URL
                  : ''
              }${thisSkuObj.png}?v=${Date.now()}`,
            );

            const pngBlob = await pngFile.blob();
            await assetStore.setItem(`png-${s.keyVal}`, pngBlob);

            assetStore.getItem(searchGlbKey).then((value) => {
              const url = URL.createObjectURL(value);
              const pngUrl = URL.createObjectURL(pngBlob);
              api.loadModel(url, IMPORT_OPTIONS).then((object) => {
                //copy data out of existing model
                object.name = s.keyVal;
                object.position.copy(rootObject.position);
                object.constraint = rootObject.constraint;
                object.rotation.copy(rootObject.rotation);
                object.userData = {
                  ...object.userData,
                  ...rootObject.userData,
                  isStackable: formFurniture.isStackable,
                  allowStacking: formFurniture.allowStacking,
                  nonPrintable: formFurniture.nonPrintable,
                  map2dURL: pngUrl,
                  twoDLabel: s.twoDLabel,
                  twoDLabelColor: 'BLACK',
                };

                object.scale.set(
                  Number(s.size.w),
                  Number(s.size.h),
                  Number(s.size.l),
                );
                // add new model
                api.addObject(object);
                // remove old model
                api.removeObject(rootObject);
              });
            });
          }
        });
      }
    };
    if (saveComplete && !error?.message) {
      if (formFurniture.id) {
        const globalAssetStore = localforage.createInstance({
          name: ASSET_STORE_NAME,
        });
        const updatedFurn = furniture[formFurniture.id];
        Object.values(formFurniture.skus).forEach((updatedSku) => {
          const thisSku = updatedFurn.skus.find(
            (searchSku) => searchSku.keyVal === updatedSku.keyVal,
          );
          updateMap(
            globalAssetStore,
            updatedSku,
            thisSku,
            updatedFurn.image,
            formFurniture.id,
          );
        });
      }
      setIsOpen(false);
      addToast('Save Successful', {
        appearance: 'success',
      });
      dispatch(furnitureActions.setSaveComplete(false));
    }
  }, [
    saveComplete,
    dispatch,
    addToast,
    setIsOpen,
    formFurniture,
    error,
    api,
    furniture,
  ]);

  useEffect(() => {
    if (error?.message && formFurniture.name) {
      addToast('Error saving furniture. Please try again.', {
        appearance: 'error',
      });
      dispatch(furnitureActions.setSaveComplete(false));
      dispatch(furnitureActions.error(null));
    } else if (error?.message) {
      dispatch(furnitureActions.setSaveComplete(false));
      dispatch(furnitureActions.error(null));
    }
  }, [dispatch, addToast, error, formFurniture]);

  const parseDimensions = (options) => {
    const sizes = Object.values(options).filter((option) => {
      return option.type === 'SIZE';
    });
    const parsedSize = sizes.map((size) => {
      // Replace with Regex
      if (
        size.value?.includes('L') &&
        size.value.includes('W') &&
        size.value.includes('H')
      ) {
        const sizeParts = size.value
          .replace(/X/g, '')
          .replace('L', '')
          .replace('W', '')
          .replace('H', '')
          .replaceAll('x', '')
          .replace(/  +/g, ' ')
          .split(' ');
        const splitSize = {
          l: sizeParts[0],
          w: sizeParts[1],
          h: sizeParts[2],
        };
        return splitSize;
      }
      return { l: 0, w: 0, h: 0 };
    });

    return parsedSize[0];
  };

  const handleSizeInputChange = (tempId, dimension, value) => {
    let sku = Object.values(formFurniture.skus).find(
      (s) => s.tempId === tempId,
    );
    if (sku) {
      if (dimension === 'l') {
        sku.size.l = value;
      } else if (dimension === 'w') {
        sku.size.w = value;
      } else if (dimension === 'h') {
        sku.size.h = value;
      }

      const newSkus = {
        ...formFurniture.skus,
        [tempId]: { ...sku },
      };

      setFormFurniture({ ...formFurniture, skus: newSkus });
    }
  };

  const handleInputImage = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      setFormFurniture({
        ...formFurniture,
        image: file,
      });
      setImageName({ name: file.name, url: '' });
    } else return;
  };

  const handleInputPng = (evt, tempId, index) => {
    const file = evt.target.files[0];
    let sku = Object.values(formFurniture.skus).find(
      (s) => s.tempId === tempId,
    );
    if (file && sku) {
      sku.png = file;
      const newSkus = {
        ...formFurniture.skus,
        [tempId]: { ...sku },
      };

      setFormFurniture({ ...formFurniture, skus: newSkus });
      const newPngNames = pngName;
      if (newPngNames[index]) {
        newPngNames[index] = {
          name: file.name,
          url: '',
        };
      } else {
        newPngNames.push({
          name: file.name,
          url: '',
        });
      }
      setPngName(newPngNames);
    }
  };

  const handleAddSku = (evt) => {
    const id = uuidv4();

    const newSku = {
      file: '',
      png: '',
      keyVal: '',
      skuNum: '',
      tempId: id,
      size: {
        l: '0',
        w: '0',
        h: '0',
      },
    };

    const newSkus = {
      ...formFurniture.skus,
      [id]: { ...newSku },
    };

    setFormFurniture({ ...formFurniture, skus: newSkus });
  };

  const handleRemoveSize = (tempId) => {
    let newSkus = { ...formFurniture.skus };
    delete newSkus[tempId];
    setFormFurniture({ ...formFurniture, skus: newSkus });
  };

  const onHandleClose = () => {
    setFormFurniture({
      name: '',
      city: '',
      furnitureType: '',
      category: '',
      subCategory: '',
      skus: [],
      placementType: '',
      details: '',
      description: '',
      image: '',
    });
    setIsOpen(false);
  };

  const showErrorMessage = (msg) => {
    setErrorMsg(msg);
    setShowErrorMsg(true);
  };

  const handleSaveFurniture = () => {
    if (!formFurniture.name) {
      showErrorMessage('Please enter a Display Name.');
      return;
    }
    if (formFurniture.skus?.length === 0) {
      showErrorMessage('Please add at least one Size.');
      return;
    }
    if (!formFurniture.image) {
      showErrorMessage('Please upload an image.');
      return;
    }

    //TODO: Switch to map sku to check

    if (!formFurniture.furnitureType) {
      showErrorMessage('Please select a category');
      return;
    }
    if (!formFurniture.category) {
      showErrorMessage('Please select a sub category');
      return;
    }
    if (!formFurniture.subCategory) {
      showErrorMessage('Please select a sub-subcategory');
      return;
    }

    if (!formFurniture.placementType) {
      showErrorMessage('Please select a type (WALL, FLOOR, CEILING)');
      return;
    }

    let skusComplete = true;

    if (!formFurniture.skus || formFurniture.skus.length < 1) {
      showErrorMessage('You must add at least 1 sku');
      return;
    }

    const skus = Object.values(formFurniture.skus).map((s) => {
      const options = [];
      const option = {
        type: 'SIZE',
        value: s.size.l + 'L x ' + s.size.w + 'W x ' + s.size.h + 'H',
      };
      options.push(option);
      const sku = {
        skuNum: s.skuNum,
        options: options,
        png: s.png,
        file: s.file,
        twoDLabel: s.twoDLabel,
        keyVal: s.keyVal || '',
      };

      if (!s.size.l || !s.size.w || !s.size.h || !s.skuNum) {
        skusComplete = false;
      }

      return sku;
    });

    if (!skusComplete) {
      showErrorMessage(
        'Please Provide Length, Width, Height, and SKU# for all SKUs',
      );
      return;
    }

    const categories = [];
    categories.push(formFurniture.category);

    const furnitureTypes = [];
    furnitureTypes.push(formFurniture.furnitureType);

    const subCats = [];
    subCats.push(formFurniture.subCategory);

    const placementTypes = [];
    placementTypes.push(formFurniture.placementType);

    const details = formFurniture.details.split('\n');

    const furniture = {
      ...formFurniture,
      skus: skus,
      furnitureType: furnitureTypes,
      category: categories,
      subCategory: subCats,
      placementType: placementTypes,
      city: 'Dallas',
      details: details,
      twoDLabelColor: 'BLACK',
    };

    if (isNew) {
      dispatch(furnitureActions.postRequest(furniture));
    } else {
      dispatch(furnitureActions.patchRequest(furniture));
    }
  };

  return (
    <>
      <section className={`info__sidebar ${isOpen ? 'opened' : 'closed'}`}>
        <button className="close-button" onClick={() => onHandleClose()} />
        <div className="frm__skulist">
          <div className="form-group">
            <label>
              Display Name*
              <input
                type="text"
                value={formFurniture.name}
                onChange={(e) =>
                  handleInputChange('name', e.currentTarget.value)
                }
                required
              />
            </label>
          </div>
          <div className="half__container">
            <div className="form-group">
              <label>
                Category*
                <select
                  onChange={(e) =>
                    changeFurnitureType('furnitureType', e.currentTarget.value)
                  }
                  value={formFurniture.furnitureType}
                >
                  <option value="">Select a Category</option>
                  {Object.keys(furnitureTypesById).map((id) => (
                    <option key={id} value={id}>
                      {furnitureTypesById[id].name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="form-group">
              <label>
                Subcategory*
                <br />
                <select
                  onChange={(e) =>
                    changeCategory('category', e.currentTarget.value)
                  }
                  value={formFurniture.category}
                  disabled={
                    !formFurniture.furnitureType ||
                    categoryFilteredArray.length === 0
                  }
                >
                  <option value="">Select a Subcategory</option>
                  {Object.keys(categoriesById)
                    .filter((cat) => categoryFilteredArray.includes(cat))
                    .map((id) => (
                      <option key={id} value={id}>
                        {categoriesById[id].name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div className="form-group">
              <label>
                Sub-Subcategory*
                <br />
                <select
                  onChange={(e) =>
                    changeSubCategory('subCategory', e.currentTarget.value)
                  }
                  value={formFurniture.subCategory}
                  disabled={
                    !formFurniture.category ||
                    subCategoryFilteredArray.length === 0
                  }
                >
                  <option value="">Select a Sub-Subcategory</option>
                  {Object.keys(subCategoriesById)
                    .filter((subCat) =>
                      subCategoryFilteredArray.includes(subCat),
                    )
                    .map((id) => (
                      <option key={id} value={id}>
                        {subCategoriesById[id].name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div className="form-group">
              <label>
                Type*
                <br />
                <select
                  onChange={(e) => changePlacementType(e.currentTarget.value)}
                  value={formFurniture.placementType}
                >
                  <option value={null}>Select Type</option>
                  <option key="FLOOR" value="FLOOR">
                    Floor
                  </option>
                  <option key="WALL" value="WALL">
                    Wall
                  </option>
                  <option key="CEILING" value="CEILING">
                    Ceiling
                  </option>
                </select>
              </label>
            </div>
          </div>
          <div className="half__container">
            <div className="form-group">
              <label>
                Upload Furniture Image*
                <br />
                {!imageName.url && imageName.name}
                <div className="image">
                  {imageName.url && (
                    <img
                      src={`${BASE_URL_ICON}${formFurniture.image}/${THUMBNAIL_SCALE}`}
                      alt=""
                      title=""
                    />
                  )}
                  <div className="actions-icons">
                    <div className="upload-button-wrapper">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleInputImage}
                      />
                      <button className="icon-button upload-button" />
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="form-group">
              <label>
                Is Stackable?
                <input
                  type="checkbox"
                  checked={formFurniture.isStackable}
                  onChange={(e) =>
                    handleInputChange('isStackable', e.currentTarget.checked)
                  }
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Allow Stacking?
                <input
                  type="checkbox"
                  checked={formFurniture.allowStacking}
                  onChange={(e) =>
                    handleInputChange('allowStacking', e.currentTarget.checked)
                  }
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Print on 2D?
                <input
                  type="checkbox"
                  checked={!formFurniture.nonPrintable}
                  onChange={(e) =>
                    handleInputChange('nonPrintable', !e.currentTarget.checked)
                  }
                />
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>
              Description
              <textarea
                type="text"
                value={formFurniture.description}
                onChange={(e) =>
                  handleInputChange('description', e.currentTarget.value)
                }
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Details
              <textarea
                type="text"
                value={formFurniture.details}
                onChange={(e) =>
                  handleInputChange('details', e.currentTarget.value)
                }
              />
            </label>
          </div>
          <br />
          <br />
          <div className="title">
            <label>
              {`SKUS`}
              <button className="icon-button" onClick={handleAddSku}>
                +
              </button>
            </label>
          </div>
          {formFurniture.skus &&
            Object.values(formFurniture.skus).map((sku, index) => {
              return (
                sku && (
                  <div key={`${sku.tempId}-div`} className="frm__skulist">
                    <div className="form-group">
                      <label>
                        SKU
                        <button
                          className="icon-button"
                          key={`${sku.tempId}-rmv`}
                          onClick={() => handleRemoveSize(sku.tempId)}
                        >
                          -
                        </button>
                        <input
                          type="text"
                          key={sku.tempId}
                          value={sku.skuNum}
                          onChange={(e) =>
                            handleSkuNumChange(
                              e.currentTarget.value,
                              sku.tempId,
                            )
                          }
                        />
                      </label>
                    </div>
                    <div className="half__container">
                      <div className="form-group">
                        <div className="title" key={`${sku.tempId}-size`}>
                          <label>
                            {`${sku.size.l}L x ${sku.size.w}W x ${sku.size.h}H`}
                            <br />
                            <input
                              className="sizeInput"
                              type="text"
                              value={sku.size.l}
                              key={`${sku.tempId}-l`}
                              onChange={(e) =>
                                handleSizeInputChange(
                                  sku.tempId,
                                  'l',
                                  e.currentTarget.value,
                                )
                              }
                            />
                            <input
                              className="sizeInput"
                              type="text"
                              value={sku.size.w}
                              key={`${sku.tempId}-w`}
                              onChange={(e) =>
                                handleSizeInputChange(
                                  sku.tempId,
                                  'w',
                                  e.currentTarget.value,
                                )
                              }
                            />
                            <input
                              className="sizeInput"
                              type="text"
                              value={sku.size.h}
                              key={`${sku.tempId}-h`}
                              onChange={(e) =>
                                handleSizeInputChange(
                                  sku.tempId,
                                  'h',
                                  e.currentTarget.value,
                                )
                              }
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        SKU 2D Label
                        <input
                          type="text"
                          key={`${sku.tempId}-label`}
                          value={sku.twoDLabel}
                          onChange={(e) =>
                            handleSkuLabelChange(
                              e.currentTarget.value,
                              sku.tempId,
                            )
                          }
                        />
                      </label>
                    </div>
                    <div className="half__container">
                      <div className="form-group">
                        {/*
                      TEMP REMOVING AS WE ARE ONLY ALLOWING BLOB FILE TO START
                      <label>
                        Upload Model
                        <br />
                        {modelName}
                        <div className="fileUpload">
                          <div className="actions-icons">
                            <div className="upload-button-wrapper">
                              <input
                                type="file"
                                accept="model/gltf+json, .glb"
                                onChange={handleInputModel}
                              />
                              <button className="icon-button upload-button" />
                            </div>
                          </div>
                        </div>
                      </label>*/}
                        <label>
                          Upload 2D Png
                          <br />
                          {!pngName[index]?.url && pngName[index]?.name}
                          <div className="image">
                            {pngName[index]?.url && (
                              <img
                                src={`${BASE_URL_ICON}${sku.png}`}
                                alt=""
                                title=""
                              />
                            )}
                            <div className="actions-icons">
                              <div className="upload-button-wrapper">
                                <input
                                  key={`${sku.tempId}-png`}
                                  type="file"
                                  accept="image/png"
                                  onChange={(e) =>
                                    handleInputPng(e, sku.tempId, index)
                                  }
                                />
                                <button className="icon-button upload-button" />
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          <ButtonLoader
            className="title btn"
            onClick={handleSaveFurniture}
            text="Save"
            loading={isLoading}
            disabled={isLoading}
            type="submit"
          />
        </div>
      </section>
      {showErrorMsg && (
        <FurntiureSaveErrorModal
          message={errorMsg}
          showError={setShowErrorMsg}
        />
      )}
    </>
  );
};

export default Sidebar;
