import { call, put, takeEvery } from 'redux-saga/effects';
import { types, actions as categoriesActions } from '../../reducers/categories';
import { getSession } from '../../auth/user';
import { get } from '../../api/categories';
import { getAllIds, getById } from '../../utils/responseData';

function* getCategories() {
  try {
    const session = yield call(getSession);
    const response = yield call(get, session.idToken.jwtToken);
    const { data } = response;
    yield put(
      categoriesActions.setCategories({
        allIds: getAllIds(data),
        byId: getById(data),
      }),
    );
  } catch (error) {
    yield put(categoriesActions.error(error));
  }
}

export default function* watchCategories() {
  yield takeEvery(types.GET_REQUEST, getCategories);
}
