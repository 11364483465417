import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import ButtonLoader from '../../components/ButtonLoader';
import { actions as userActions } from '../../reducers/user';
import * as UserSelector from '../../selectors/users';
import { LOGIN_REQUIRED_ERROR_MSG } from '../../constants';

import logo from '../../assets/images/restoration-hardware-logo-large.jpg';

const LoginModal = ({ setLoginAction }) => {
  const LoginHeader = () => (
    <div className="store">
      <h1>RH DALLAS</h1>
      <h3>3133 Knox St</h3>
      <h3>Dallas, TX 75205</h3>
    </div>
  );

  const LoginContent = () => (
    <>
      <h3>welcome to the</h3>
      <div>
        <img className="large-logo" src={logo} alt="Resoration Hardware" />
      </div>
      <h3>gallery planner</h3>
    </>
  );

  const LoginButtons = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const error = useSelector(UserSelector.getError);
    const loading = useSelector(UserSelector.getLoading);

    const onHandleLogin = async () => {
      if (!email || !password) {
        dispatch(userActions.setError({ message: LOGIN_REQUIRED_ERROR_MSG }));
      } else {
        dispatch(userActions.loginRequest({ email, password }));
        setLoginAction(true);
      }
    };

    const forgotPasswordClick = () => {
      dispatch(userActions.setRequestCode(true));
    };

    return (
      <form>
        <div className="frm__generic">
          <input
            type="email"
            className="email"
            name="email"
            placeholder="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <input
            type="password"
            className="password"
            placeholder="PASSWORD"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <div className="instructions">
            <span className="forgotPassword" onClick={forgotPasswordClick}>
              Forgot Password?
            </span>
          </div>
          <div className="error">{error?.message}</div>
          <ButtonLoader
            className="btn btnSubmitModal"
            type="submit"
            onClick={onHandleLogin}
            text="LOGIN"
            loading={loading}
          />
        </div>
      </form>
    );
  };

  return (
    <Modal
      header={<LoginHeader />}
      content={<LoginContent />}
      buttons={<LoginButtons />}
      overlayStyles="blur-heavy"
    />
  );
};

export default LoginModal;
