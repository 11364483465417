import React from 'react';
import { useSelector } from 'react-redux';
import * as he from 'he';

import * as FurnitureSelectors from '../../selectors/furniture';
import OptionSelector from './OptionSelector';

const FurnituresInfo = ({
  selectedFurnitureRef,
  selectionUiPos,
  setSelectionUiPos,
  setFurnitureSize,
  isShortCeiling,
}) => {
  const divStyle = {
    overflowY: 'scroll',
  };
  const furnitureByIds = useSelector(FurnitureSelectors.getById);
  const selectedFurniture = useSelector(
    FurnitureSelectors.getSelectedFurniture,
  );
  const options = furnitureByIds[
    selectedFurniture
  ].options.sort((optionA, optionB) =>
    optionA.type.toLowerCase() > optionB.type.toLowerCase() ? 1 : -1,
  );

  const detailArrayJSX = [];
  if (furnitureByIds[selectedFurniture].details) {
    furnitureByIds[selectedFurniture].details
      .filter((detail) => detail)
      .map((detail, index) => {
        return detailArrayJSX.push(
          <li
            key={`detail-${index}`}
            dangerouslySetInnerHTML={{
              __html: he.decode(detail),
            }}
          />,
        );
      });
  }
  return (
    <div style={divStyle}>
      <div className="sidebar__subMenuWrapper--search">
        <div className="result">
          <span>
            {he.decode(
              furnitureByIds[selectedFurniture].name ||
                furnitureByIds[selectedFurniture].label,
            )}
          </span>
        </div>
      </div>

      {options
        .filter((option) => option)
        .map((option, index) => {
          return (
            <OptionSelector
              key={`${option.type}-${index}`}
              option={option}
              skus={furnitureByIds[selectedFurniture].skus}
              selectedFurnitureRef={selectedFurnitureRef}
              selectionUiPos={selectionUiPos}
              setSelectionUiPos={setSelectionUiPos}
              setFurnitureSize={setFurnitureSize}
              isShortCeiling={isShortCeiling}
            />
          );
        })}
      <div className="sidebar__subMenuWrapper--infoContent">
        <div className="info">
          <div className="content">
            <p
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: he.decode(
                  furnitureByIds[selectedFurniture].description || '',
                ),
              }}
            />
          </div>
          <div className="details">
            <h3>Details</h3>
            <ul>{detailArrayJSX}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FurnituresInfo;
