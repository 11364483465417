import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import { LastLocationProvider } from 'react-router-last-location';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { useLocation } from 'react-router-dom';

import { APIProvider } from './context/APIContext';
import {
  PAGE_HOME,
  PAGE_MY_ACCOUNT,
  PAGE_MAP,
  TOOLBAR_POS,
  PAGE_SKULIST,
  SIDEBAR_TAB_FURNITURES,
  NEW_PASSWORD_REQUIRED,
} from './constants';
import Home from './scenes/Home';
import MyAccount from './scenes/MyAccount';
import MapPage from './scenes/Map';
import SKUList from './scenes/SKUList';
import NewVersionModal from './scenes/Modals/newVersion';
import { actions as furnitureActions } from './reducers/furniture';
import { actions as furnitureTypeActions } from './reducers/furnitureTypes';
import { actions as categoryActions } from './reducers/categories';
import { actions as subCategoryActions } from './reducers/subCategories';
import { actions as mapActions } from './reducers/maps';
import * as UserSelector from './selectors/users';
import * as ServiceWorkerSelector from './selectors/serviceWorker';
import * as FurnitureSelectors from './selectors/furniture';
import RoomDesigner from './components/RoomDesigner';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

import './styles/index.scss';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(UserSelector.getUser);
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser?.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={PAGE_HOME} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedFurnitureRef = useRef(null);
  const loading = useSelector(FurnitureSelectors.getLoading);
  const [showNewVersionModal, setShowNewVersionModal] = useState(false);
  const [furnitureSize, setFurnitureSize] = useState({});
  const [selectionUiPos, setSelectionUiPos] = useState(TOOLBAR_POS);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [sharedCameraState, setCameraState] = useState({});
  const [isShortCeiling, setIsShortCeiling] = useState(false);
  const [selectedRotIndex, setSelectedRotIndex] = useState(2);
  const [selectedTab, setSelectedTab] = useState(SIDEBAR_TAB_FURNITURES);
  const [selectedView, setSelectedView] = useState('orbit');
  const currentUser = useSelector(UserSelector.getUser);

  const serviceWorkerRegistration = useSelector(
    ServiceWorkerSelector.getWaitingWorker,
  );
  const serviceWorkerUpdated = useSelector(
    ServiceWorkerSelector.isServiceWorkerUpdated,
  );
  const serviceWorkerInitialized = useSelector(
    ServiceWorkerSelector.isServiceWorkerInitialized,
  );

  useEffect(() => {
    if (
      navigator.onLine &&
      currentUser?.loggedIn &&
      currentUser?.userObject?.challengeName !== NEW_PASSWORD_REQUIRED
    ) {
      batch(() => {
        dispatch(mapActions.getRequest());
        dispatch(furnitureActions.getRequest());
        dispatch(furnitureTypeActions.getRequest());
        dispatch(categoryActions.getRequest());
        dispatch(subCategoryActions.getRequest());
      });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (serviceWorkerUpdated && !loading) {
      setShowNewVersionModal(true);
    } else {
      setShowNewVersionModal(false);
    }
  }, [
    serviceWorkerRegistration,
    serviceWorkerUpdated,
    serviceWorkerInitialized,
    loading,
  ]);

  return (
    <>
      <a
        href="https://evolutionv.freshdesk.com"
        rel="noopener noreferrer"
        target="_blank"
        className="float"
      >
        <HelpOutline className="helpIcon" />{' '}
        <span className="helpText">Help</span>
      </a>
      {showNewVersionModal && (
        <NewVersionModal showModal={setShowNewVersionModal} />
      )}
      <APIProvider>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <Header
            openSaveModal={setShowSaveModal}
            openConfirmExitModal={setShowConfirmExitModal}
            selectedFurnitureRef={selectedFurnitureRef}
            setSelectionUiPos={setSelectionUiPos}
            setSelectedView={setSelectedView}
            selectedView={selectedView}
            sharedCameraState={sharedCameraState}
            setCameraState={setCameraState}
            setSelectedRotIndex={setSelectedRotIndex}
          />
          {location.pathname === PAGE_HOME && (
            <Sidebar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selectedFurnitureRef={selectedFurnitureRef}
              selectionUiPos={selectionUiPos}
              setSelectionUiPos={setSelectionUiPos}
              setFurnitureSize={setFurnitureSize}
              isShortCeiling={isShortCeiling}
            />
          )}
          <RoomDesigner
            selectedFurnitureRef={selectedFurnitureRef}
            selectionUiPos={selectionUiPos}
            setSelectionUiPos={setSelectionUiPos}
            furnitureSize={furnitureSize}
            setFurnitureSize={setFurnitureSize}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sharedCameraState={sharedCameraState}
            setCameraState={setCameraState}
            selectedRotIndex={selectedRotIndex}
            setSelectedRotIndex={setSelectedRotIndex}
            setIsShortCeiling={setIsShortCeiling}
            isShortCeiling={isShortCeiling}
          />
          <Switch>
            <LastLocationProvider>
              <ProtectedRoute path={`${PAGE_MAP}/:id`} component={MapPage} />
              <ProtectedRoute path={PAGE_MY_ACCOUNT} component={MyAccount} />
              <Route
                path={PAGE_HOME}
                exact
                render={(props) => {
                  return (
                    <Home
                      showIntroModal={showIntroModal}
                      setShowIntroModal={setShowIntroModal}
                      showConfirmExitModal={showConfirmExitModal}
                      setShowConfirmExitModal={setShowConfirmExitModal}
                      showSaveModal={showSaveModal}
                      setShowSaveModal={setShowSaveModal}
                      setSelectionUiPos={setSelectionUiPos}
                      setSelectedView={setSelectedView}
                      setCameraState={setCameraState}
                      setSelectedRotIndex={setSelectedRotIndex}
                    />
                  );
                }}
              />
              <ProtectedRoute path={PAGE_SKULIST} component={SKUList} />
              <Redirect to={PAGE_HOME} />
            </LastLocationProvider>
          </Switch>
        </DndProvider>
      </APIProvider>
    </>
  );
};

export default App;
