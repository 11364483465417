const NAME = 'SIDEBAR';

export const types = {
  SET_MENU_OPEN: `${NAME}/SET_MENU_OPEN`,
  SET_OPENED_FROM_MAP: `${NAME}/SET_OPENED_FROM_MAP`,
};

export const actions = {
  setMenuOpened: (menuOpened) => ({
    type: types.SET_MENU_OPEN,
    payload: menuOpened,
  }),
  setOpenedFromMap: (openedFromMap) => ({
    type: types.SET_OPENED_FROM_MAP,
    payload: openedFromMap,
  }),
};

const initialState = {
  menuOpened: false,
  openedFromMap: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MENU_OPEN:
      return {
        ...state,
        menuOpened: payload,
      };
    case types.SET_OPENED_FROM_MAP:
      return {
        ...state,
        openedFromMap: payload,
      };
    default:
      return state;
  }
};
