import { get } from 'lodash';

const root = (state) => get(state, 'serviceWorker');

export const isServiceWorkerInitialized = (state) =>
  get(root(state), 'serviceWorkerInitialized', false);
export const isServiceWorkerUpdated = (state) =>
  get(root(state), 'serviceWorkerUpdated', false);
export const getWaitingWorker = (state) =>
  get(root(state), 'serviceWorkerRegistration', false);
