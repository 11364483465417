import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as he from 'he';

import DraggableFurniture from '../DraggableFurniture';
import * as FurnitureSelectors from '../../selectors/furniture';
import * as FurnitureTypeSelectors from '../../selectors/furnitureTypes';
import * as CategorySelectors from '../../selectors/categories';
import * as SubCategorySelectors from '../../selectors/subCategories';

const MenuSubCategory = () => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const furnitures = useSelector(FurnitureSelectors.getFurniture);
  const furnitureTypesById = useSelector(FurnitureTypeSelectors.getById);
  const categoriesById = useSelector(CategorySelectors.getById);
  const subCategoriesById = useSelector(SubCategorySelectors.getById);
  const selectedCategory = useSelector(FurnitureSelectors.getSelectedCategory);
  const selectedFurnitureType = useSelector(FurnitureSelectors.getSelectedType);

  const showFurnitureDetails = useSelector(
    FurnitureSelectors.getShowFurnitureDetails,
  );

  const selectedSubCategory = useSelector(
    FurnitureSelectors.getSelectedSubCategory,
  );

  useEffect(() => {
    setShowSearchBar(showFurnitureDetails);
  }, [showFurnitureDetails]);

  return (
    <>
      <div className="sidebar__subMenuWrapper--search">
        <div className={`result ${showSearchBar ? 'hidden' : ''}`}>
          <span>{furnitureTypesById[selectedFurnitureType]?.name}</span>
          <div className="breadcrumb">
            <span>
              {categoriesById[selectedCategory]?.name}
              {' > '}
              {subCategoriesById[selectedSubCategory]?.name}
            </span>
          </div>
        </div>
      </div>
      <div className="sidebar__subMenuWrapper--allElementsContent">
        <div className="contentWrapper">
          {furnitures &&
            Object.values(furnitures).map((furniture) => {
              return (
                <div
                  key={furniture.id}
                  className="item"
                  title={furniture.name || furniture.label}
                >
                  <DraggableFurniture furniture={furniture} />
                  <div
                    name={furniture.name || furniture.label}
                    className="description"
                  >
                    {he.decode(furniture.name || furniture.label)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MenuSubCategory;
