import React, { useContext, useCallback, useState, useEffect } from 'react';
import { useDispatch, batch } from 'react-redux';
import classNames from 'classnames';
import { Vector3 } from 'three';

import { APIContext } from '../../context/APIContext';
import { actions as sidebarActions } from '../../reducers/sidebar';
import { actions as furnitureActions } from '../../reducers/furniture';
import {
  TOOLBAR_POS,
  UP_VECTORS,
  DEFAULT_FOV,
  FPC_HEIGHT_OFFSET,
  MAX_POLOAR_ANGLE,
} from '../../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextFieldsIcon from '@material-ui/icons/TextFields';

const ViewChanger = ({
  setSelectionUiPos,
  selectedFurnitureRef,
  setLeftDimensionUIPo,
  setTopDimensionUIPo,
  setRoomNamesEnabled,
  selectedView,
  setSelectedView,
  isTransition,
  setIsTransition,
  cameraState,
  setCameraState,
  setFovVal,
  selectedRotIndex,
  setPasteButtonPos,
}) => {
  const dispatch = useDispatch();
  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const [isAnnotating, setAnnotating] = useState(false);

  const clearToolbars = useCallback(() => {
    setRoomNamesEnabled(false);
    setSelectionUiPos(TOOLBAR_POS);
    api.selectObject({}, {});
    setLeftDimensionUIPo(TOOLBAR_POS);
    setTopDimensionUIPo(TOOLBAR_POS);
    setPasteButtonPos(TOOLBAR_POS);
    selectedFurnitureRef.current = null;
    batch(() => {
      dispatch(furnitureActions.setSelectedFurniture(''));
      dispatch(sidebarActions.setOpenedFromMap(false));
    });
  }, [
    dispatch,
    api,
    selectedFurnitureRef,
    setSelectionUiPos,
    setLeftDimensionUIPo,
    setTopDimensionUIPo,
    setRoomNamesEnabled,
    setPasteButtonPos,
  ]);

  const ceilingVisibility = useCallback(
    (bEnable) => {
      const ceilingObject = api.getObjectByNameOrId('ceilings');
      ceilingObject &&
        ceilingObject.traverse((obj) => {
          obj.visible = bEnable;
        });
    },
    [api],
  );

  const set3D = useCallback(() => {
    api.setCameraMode('perspective', { fov: DEFAULT_FOV });
    setFovVal(DEFAULT_FOV);
    api.renderParams = { mode: '3d' };
  }, [api, setFovVal]);

  const updateCameraState = useCallback(
    (key, state) => {
      if (selectedView !== 'POPUP') {
        const newState = {
          ...cameraState,
          [key]: {
            ...state,
          },
        };

        setCameraState(newState);
      }
    },
    [cameraState, selectedView, setCameraState],
  );

  const set45View = useCallback(() => {
    if (isTransition || selectedView === 'orbit') {
      return;
    }
    setIsTransition(true);
    const state = api.captureCameraState();
    const key = state.cameraState.type + api.renderParams.mode;

    clearToolbars();
    set3D();
    updateCameraState(key, state);
    setSelectedView('orbit');
    ceilingVisibility(false);

    const finishCallback = () => {
      setIsTransition(false);
    };

    if (cameraState['orbit3d']) {
      cameraState['orbit3d'].cameraState.maxPolarAngle = MAX_POLOAR_ANGLE;
      api.transition(cameraState['orbit3d'].cameraState, 1.5, finishCallback);
      return;
    }

    const radius = api.sceneSphere.radius;
    const target = api.sceneCentre;
    const position = { x: radius, y: radius, z: radius };
    const maxDistance = api.sceneSphere.radius * 2;
    const up = { x: 0, y: 1, z: 0 };

    api.transition(
      {
        type: 'orbit',
        position: position,
        up: up,
        target: target,
        maxDistance: maxDistance,
        maxPolarAngle: MAX_POLOAR_ANGLE,
      },
      1.5,
      finishCallback,
    );
  }, [
    api,
    cameraState,
    setSelectedView,
    isTransition,
    selectedView,
    clearToolbars,
    set3D,
    ceilingVisibility,
    updateCameraState,
    setIsTransition,
  ]);

  const setFirstPerson = useCallback(() => {
    if (isTransition || selectedView === 'fpc') {
      return;
    }
    setIsTransition(true);
    const state = api.captureCameraState();
    const key = state.cameraState.type + api.renderParams.mode;

    clearToolbars();
    set3D();
    updateCameraState(key, state);
    setSelectedView('fpc');

    const finishCallback = () => {
      ceilingVisibility(true);
      setIsTransition(false);
    };

    if (cameraState['fpc3d']) {
      let oldState = cameraState['fpc3d'];
      if (selectedView === 'POPUP') {
        const oldPosition = oldState.cameraState.position;
        const cameraPosition_ = state.cameraState.position;
        const offset = new Vector3().copy(oldPosition);
        if (offset && cameraPosition_) {
          offset.sub(cameraPosition_);
          offset.normalize();
          const target = {
            x: oldPosition.x + offset.x,
            y: oldPosition.y,
            z: oldPosition.z + offset.z,
          };
          oldState.cameraState.target = target;
          api.transition(oldState.cameraState, 1.5, finishCallback);
          return;
        }
      }
    }

    const eyeHeight = api.sceneSize.y * FPC_HEIGHT_OFFSET;

    const maxDistance = api.sceneSphere.radius * 2;
    const up = new Vector3(0, 1, 0);

    const camera = api.sceneManager_.activeCamera_;

    // plane-ray intersection as described here:
    // https://www.scratchapixel.com/lessons/3d-basic-rendering/minimal-ray-tracer-rendering-simple-shapes/ray-plane-and-ray-disk-intersection

    const planeCenter = new Vector3(0, eyeHeight, 0);
    const planeNormal = new Vector3(0, 1, 0);

    const rayOrigin = camera.position.clone();
    const rayDir = camera.getWorldDirection(new Vector3()).normalize();

    const rayDotUp = rayDir.dot(up);
    let t = 0;

    const threshold = 0.000001;
    if (rayDotUp > threshold || rayDotUp < -threshold) {
      const rayOriginToPlane = planeCenter.sub(rayOrigin);
      t = rayOriginToPlane.dot(planeNormal) / rayDotUp;
    }

    const position = rayDir.clone().multiplyScalar(t).add(camera.position);
    position.y = eyeHeight;

    api.transition(
      {
        type: 'fpc',
        up,
        position,
        eyeHeight,
        target: {
          x: position.x + rayDir.x,
          z: position.z + rayDir.z,
          y: position.y,
        },
        maxDistance,
      },
      1.5,
      finishCallback,
    );
  }, [
    api,
    cameraState,
    setSelectedView,
    isTransition,
    selectedView,
    clearToolbars,
    set3D,
    ceilingVisibility,
    setIsTransition,
    updateCameraState,
  ]);

  const setPopUp = useCallback(() => {
    if (isTransition) {
      return;
    }
    if (selectedView === 'POPUP') {
      setFirstPerson();
      return;
    }
    const state = api.captureCameraState();
    const up = { x: 0, y: 1, z: 0 };
    let radius = api.sceneSphere.radius * 0.2;
    let cameraPosition = state.cameraState.position;
    let position = { x: 1, y: 1, z: 1 };
    const maxDistance = api.sceneSphere.radius * 2;
    const key = state.cameraState.type + api.renderParams.mode;

    updateCameraState(key, state);
    setSelectedView('POPUP');
    ceilingVisibility(false);

    let target = {
      x: api.sceneCentre.x,
      y: api.sceneCentre.y,
      z: api.sceneCentre.z,
    };

    if (cameraPosition) {
      target = {
        x: cameraPosition.x,
        y: cameraPosition.y,
        z: cameraPosition.z,
      };
    }

    let offset = state.cameraState.target;

    if (offset && cameraPosition) {
      offset.sub(cameraPosition);
      offset.normalize();
      offset.multiply(new Vector3(radius, radius, radius));
    }

    position = {
      x: target.x - offset.x,
      y: 2 * radius,
      z: target.z - offset.z,
    };

    api.transition(
      {
        type: 'orbit',
        position: position,
        up: up,
        target: target,
        maxDistance: maxDistance,
        maxPolarAngle: MAX_POLOAR_ANGLE,
      },
      1,
    );
  }, [
    api,
    isTransition,
    selectedView,
    ceilingVisibility,
    setSelectedView,
    setFirstPerson,
    updateCameraState,
  ]);

  const setThreeDView = useCallback(() => {
    if (isTransition || selectedView === '3D') {
      return;
    }
    const state = api.captureCameraState();
    const key = state.cameraState.type + api.renderParams.mode;
    const upVector = UP_VECTORS[selectedRotIndex];
    const maxDistance = api.sceneSphere.radius * 2;

    clearToolbars();
    set3D();
    updateCameraState(key, state);
    setSelectedView('3D');
    ceilingVisibility(false);

    api.sceneView = {
      side: 'top',
      up: upVector,
      enableZoom: true,
      enablePan: true,
      maxDistance: maxDistance,
      transition: true,
    };
  }, [
    api,
    setSelectedView,
    isTransition,
    selectedView,
    selectedRotIndex,
    clearToolbars,
    set3D,
    ceilingVisibility,
    updateCameraState,
  ]);

  const setTwoDView = useCallback(() => {
    if (isTransition || selectedView === '2D') {
      return;
    }
    api.selectObject({}, {});
    selectedFurnitureRef.current = null;
    const state = api.captureCameraState();
    const key = state.cameraState.type + api.renderParams.mode;
    const upVector = UP_VECTORS[2];
    updateCameraState(key, state);
    ceilingVisibility(false);
    const maxDistance = api.sceneSphere.radius * 2;

    api.sceneView = {
      side: 'top',
      up: upVector,
      enableZoom: true,
      enablePan: true,
      maxDistance: maxDistance,
    };

    api.renderParams = { mode: '2d' };
    clearToolbars();
    setSelectedView('2D');
    dispatch(sidebarActions.setMenuOpened(false));
  }, [
    api,
    dispatch,
    setSelectedView,
    isTransition,
    selectedView,
    clearToolbars,
    ceilingVisibility,
    updateCameraState,
    selectedFurnitureRef,
  ]);

  const toggleAnnotating = () => {
    if (selectedView === '2D') {
      return;
    }
    setAnnotating(!isAnnotating);
  };

  const pointerDownEvent = (event) => {
    //prevent fpc wabble
    event.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    if (api) {
      api.setAnnotationMode(isAnnotating);
    }
  }, [isAnnotating, api]);

  return (
    <div id="viewChanges" key="viewChanges" className="unselectable">
      <div
        className={classNames('viewSelector walking-man', {
          selected: selectedView === 'fpc',
        })}
        onClick={setFirstPerson}
        onPointerDown={(event) => pointerDownEvent(event)}
        key="setFirstPerson"
      />
      {(selectedView === 'POPUP' || selectedView === 'fpc') && (
        <div
          className={classNames('viewSelector', {
            selected: selectedView === 'POPUP',
          })}
          key="setPopup"
          onClick={setPopUp}
          onPointerDown={(event) => pointerDownEvent(event)}
        >
          <VisibilityIcon />
        </div>
      )}
      <div
        className={classNames('viewSelector', {
          selected: selectedView === 'orbit',
        })}
        onClick={set45View}
        onPointerDown={(event) => pointerDownEvent(event)}
        key="set45View"
      >
        45&#176;
      </div>
      <div
        className={classNames('viewSelector', {
          selected: selectedView === '3D',
        })}
        key="setThreeDView"
        onClick={setThreeDView}
        onPointerDown={(event) => pointerDownEvent(event)}
      >
        3D
      </div>
      <div
        className={classNames('viewSelector', {
          selected: selectedView === '2D',
        })}
        key="set2DView"
        onClick={setTwoDView}
        onPointerDown={(event) => pointerDownEvent(event)}
      >
        2D
      </div>
      <div
        className={classNames('viewSelector', {
          selected: isAnnotating || selectedView === '2D',
        })}
        key="setAnnotating"
        onClick={toggleAnnotating}
        onPointerDown={(event) => pointerDownEvent(event)}
      >
        <TextFieldsIcon />
      </div>
    </div>
  );
};

export default React.memo(ViewChanger);
