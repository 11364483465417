import { get } from 'lodash';
import { createSelector } from 'reselect';

import * as FurnitureSelectors from './furniture';
import * as FurnitureTypeSelectors from './furnitureTypes';

const root = (state) => get(state, 'categories');

export const getAllIds = (state) => get(root(state), 'allIds', false);
export const getById = (state) => get(root(state), 'byId', false);
export const getSelected = (state) => get(root(state), 'selected', false);

export const getSorted = createSelector(
  [getById, FurnitureTypeSelectors.getById, FurnitureSelectors.getSelectedType],
  (byId, typesById, selectedType) => {
    const cats = typesById[selectedType].categories.map((id) => {
      return byId[id];
    });
    const sorted = Object.values(cats).sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return sorted;
  },
);
