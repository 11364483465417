import axios from 'axios';

import API, { config } from '../API';

export const getUploadUrl = (token, filename, folder, contentType) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get(
    `/presignedurl?filename=${filename}&folder=${folder}&contenttype=${contentType}`,
    apiConf,
  );
};

export const uploadFile = (url, file, options) => {
  return axios.put(url, file, options);
};
