import { get } from 'lodash';

const root = (state) => get(state, 'user');

export const getUser = (state) => get(root(state), 'user', false);
export const getLoading = (state) => get(root(state), 'isFetching', false);
export const getError = (state) => get(root(state), 'error', false);

export const getForgotPassEmail = (state) =>
  get(root(state), 'forgotPassEmail', false);

export const getShowRequestCode = (state) =>
  get(root(state), 'showRequestCode', false);

export const getShowChagePassword = (state) =>
  get(root(state), 'showChangePassword', false);

export const getNameUpdateComplete = (state) =>
  get(root(state), 'nameUpdateComplete', false);

export const getPasswordUpdateComplete = (state) =>
  get(root(state), 'passwordUpdateComplete', false);
