import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { format } from 'date-fns';

import { actions as userActions } from '../../reducers/user';
import { types, actions as mapActions } from '../../reducers/maps';
import {
  get,
  getByUser,
  getOne,
  post,
  patch,
  remove,
  createPdf,
  getDirty,
} from '../../api/maps';
import { getUploadUrl, uploadFile } from '../../api/s3';
import { getSession } from '../../auth/user';
import * as MapSelector from '../../selectors/maps';
import { getAllIds, getById } from '../../utils/responseData';
import { API_MAP_CONCURRENCY_ERROR } from '../../constants';

function* getMaps(action) {
  try {
    const { forceRefresh } = action.payload;
    let refreshDate = '';
    let localDate = null;

    if (!forceRefresh) {
      refreshDate = yield select(MapSelector.getLastUpdate);
      if (refreshDate) {
        localDate = new Date(refreshDate);
        refreshDate = format(localDate, `yyyy-MM-dd'T'HH:mm`);
      }
    }

    const session = yield call(getSession);
    const response = yield call(get, refreshDate, session.idToken.jwtToken);

    let { data } = response;

    if (refreshDate) {
      let serverDate = new Date(data.forceRefresh);
      serverDate = new Date(
        serverDate.getUTCFullYear(),
        serverDate.getUTCMonth(),
        serverDate.getUTCDate(),
        serverDate.getUTCHours(),
        serverDate.getUTCMinutes(),
        serverDate.getUTCSeconds(),
      );

      if (serverDate.getTime() > localDate.getTime()) {
        yield put(mapActions.clearMaps());
        const refreshResp = yield call(get, '', session.idToken.jwtToken);
        data = refreshResp.data;
      }
    }

    yield put(
      mapActions.setMaps({
        allIds: getAllIds(data.updated),
        byId: getById(data.updated),
      }),
    );

    yield put(
      mapActions.removeSyncedDeleted({
        allIds: getAllIds(data.deleted),
      }),
    );

    let currDate = new Date();
    currDate = new Date(
      currDate.getUTCFullYear(),
      currDate.getUTCMonth(),
      currDate.getUTCDate(),
      currDate.getUTCHours(),
      currDate.getUTCMinutes(),
      currDate.getUTCSeconds(),
    );

    const updateDate = format(currDate, `yyyy-MM-dd'T'HH:mm:ss`);
    yield put(mapActions.setLastUpdate(updateDate));
    yield put(mapActions.error({}));
  } catch (error) {
    yield put(mapActions.error(error));
  }
}

function* getMapsForUser() {
  try {
    const session = yield call(getSession);
    const response = yield call(getByUser, session.idToken.jwtToken);
    const { data } = response;
    yield put(
      mapActions.setMaps({
        allIds: getAllIds(data),
        byId: getById(data),
      }),
    );
  } catch (error) {
    yield put(mapActions.error(error));
  }
}

function* getMap(action) {
  try {
    const { id } = action.payload;
    const response = yield call(getOne, id);
    const { data } = response;
    yield put(mapActions.setMap(id, { ...data }));
  } catch (error) {
    yield put(mapActions.error(error));
  }
}

function* uploadPng(filename, session, snapshot) {
  const folder = 'data/pdf';
  const contentType = 'image/png';

  const uploadUrl = yield call(
    getUploadUrl,
    session.idToken.jwtToken,
    filename,
    folder,
    contentType,
  );

  const options = {
    params: {
      Key: `${folder}/${filename}`,
      ContentType: contentType,
    },
    headers: {
      'Content-Type': contentType,
    },
  };

  const buffer = Buffer.from(
    snapshot.replace(/^data:image\/\w+;base64,/, ''),
    'base64',
  );

  yield call(uploadFile, uploadUrl.data, buffer, options);
}

function* getMapPdf(action) {
  try {
    const { id, scene, snapshot } = action.payload;
    const session = yield call(getSession);

    if (snapshot) {
      const filename = `${id}-scene.png`;
      yield call(uploadPng, filename, session, snapshot);
    }

    const response = yield call(createPdf, session.idToken.jwtToken, id, {
      scene: scene,
    });

    const { data } = response;
    if (response.status !== 200) {
      yield put(mapActions.error({ message: 'Error Generating PDF' }));
      yield put(mapActions.setGetPdfComplete(true));
    }
    yield put(mapActions.setMapUrl(data.url));
    yield put(mapActions.setGetPdfComplete(true));
  } catch (error) {
    yield put(mapActions.error(error));
    yield put(mapActions.setGetPdfComplete(true));
  }
}

function* postMap(action) {
  try {
    const { map } = action.payload;
    const session = yield call(getSession);

    const response = yield call(post, session.idToken.jwtToken, map);
    const { data } = response;
    //const filename = `${data.id}-scene-save.png`;

    //yield call(uploadPng, filename, session, snapshot);

    yield put(mapActions.setMapPost(data.id, { ...data }));
    yield put(mapActions.setMapLoaded(data));
    yield put(mapActions.error({}));
    yield put(mapActions.setSaveComplete(true));
  } catch (error) {
    if (error === 'No current user') {
      yield put(userActions.logOut());
    } else {
      yield put(mapActions.error(error));
    }
  }
}

function* patchMap(action) {
  try {
    const { map, forceOverwrite } = action.payload;
    const session = yield call(getSession);
    //const filename = `${map.id}-scene-save.png`;

    //yield call(uploadPng, filename, session);

    const response = yield call(
      patch,
      session.idToken.jwtToken,
      map,
      forceOverwrite,
    );

    const { data } = response;

    if (response.status !== 200) {
      if (data.message === API_MAP_CONCURRENCY_ERROR) {
        yield all([
          put(mapActions.error({})),
          put(mapActions.setConcurrentWrite(true)),
          put(mapActions.setSaveComplete(true)),
        ]);
      } else {
        yield all([
          put(mapActions.error(data.message)),
          put(mapActions.setSaveComplete(true)),
        ]);
      }
    } else {
      yield all([
        put(mapActions.setMapPatch(data.id, { ...data })),
        put(mapActions.setMapLoaded(data)),
        put(mapActions.error({})),
        put(mapActions.setSaveComplete(true)),
      ]);
    }
  } catch (error) {
    if (error.message === API_MAP_CONCURRENCY_ERROR) {
      yield all([
        put(mapActions.error({})),
        put(mapActions.setConcurrentWrite(true)),
      ]);
    } else {
      if (error === 'No current user') {
        yield put(userActions.logOut());
      } else {
        yield put(mapActions.error(error));
      }
    }
  }
}

function* deleteMap(action) {
  try {
    const session = yield call(getSession);
    yield call(remove, session.idToken.jwtToken, action.payload);
    yield put(mapActions.deleteRequestSuccess({ id: action.payload }));
    yield put(mapActions.setDeleteComplete(true));
    yield put(mapActions.error({}));
  } catch (error) {
    yield put(mapActions.error(error));
  }
}

function* getIsDirty(action) {
  const { id, updatedAt } = action.payload;
  const session = yield call(getSession);
  const response = yield call(
    getDirty,
    session.idToken.jwtToken,
    id,
    updatedAt,
  );

  try {
    const { data } = response;
    if (response.status !== 200) {
      yield all([
        put(mapActions.error(data.message)),
        put(mapActions.setSaveComplete(true)),
      ]);
    } else {
      const dirtyStr = data.isDirty ? 'DIRTY' : 'CLEAN';

      if (data.isDirty) {
        yield all([
          put(mapActions.setConcurrentWrite(data.isDirty)),
          put(mapActions.setIsDirty(dirtyStr)),
          put(mapActions.setSaveComplete(true)),
          put(mapActions.error({})),
        ]);
      } else {
        yield all([
          put(mapActions.setIsDirty(dirtyStr)),
          put(mapActions.error({})),
        ]);
      }
    }
  } catch (error) {
    if (error === 'No current user') {
      yield put(userActions.logOut());
    } else {
      yield all([
        put(mapActions.error(error)),
        put(mapActions.setSaveComplete(true)),
      ]);
    }
  }
}

export default function* watchMaps() {
  yield takeEvery(types.GET_REQUEST, getMaps);
  yield takeEvery(types.GET_ONE_REQUEST, getMap);
  yield takeEvery(types.PATCH_REQUEST, patchMap);
  yield takeEvery(types.DELETE_REQUEST, deleteMap);
  yield takeEvery(types.GET_BY_USER_REQUEST, getMapsForUser);
  yield takeEvery(types.POST_REQUEST, postMap);
  yield takeEvery(types.MAP_PDF_REQUEST, getMapPdf);
  yield takeEvery(types.GET_DIRTY, getIsDirty);
}
