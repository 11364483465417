import React, { useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import Furnitures from './furnitures';
import * as SidebarSelectors from '../../selectors/sidebar';
import { actions as furnitureActions } from '../../reducers/furniture';
import { actions as sidebarActions } from '../../reducers/sidebar';

const Sidebar = ({
  selectedFurnitureRef,
  selectionUiPos,
  setSelectionUiPos,
  setFurnitureSize,
  isShortCeiling,
}) => {
  const dispatch = useDispatch();
  const isMenuOpened = useSelector(SidebarSelectors.getMenuOpened);

  const [openedSubMenu, setOpenedSubMenu] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  const openSideBar = () => {
    if (!isMenuOpened) {
      dispatch(sidebarActions.setMenuOpened(true));
    } else {
      batch(() => {
        dispatch(furnitureActions.setSelectedFurniture(''));
        dispatch(furnitureActions.setShowFurnitureDetails(false));
        dispatch(furnitureActions.setSearchValue(''));
        dispatch(furnitureActions.setSelectedCategory(''));
        dispatch(furnitureActions.setSelectedType(''));
        dispatch(furnitureActions.setSelectedSubCategory(''));
        dispatch(sidebarActions.setOpenedFromMap(false));
      });
    }
  };

  return (
    <section className="sidebar" onPointerDown={onPointerDown}>
      <div className="sidebar__menuWrapper">
        <div className="sidebar__menuWrapper--menu">
          <MenuOpenIcon
            className="item storePlanner icon-sofa-double active"
            onClick={() => openSideBar()}
          />
          {/* Temporary Hiding Thes since they are not a part of POC.
          <button
            className={`item map icon-real-estate-dimensions-plan ${selectedTab === SIDEBAR_TAB_MAPS && 'active'}`}
            onClick={() => setSelectedTab(SIDEBAR_TAB_MAPS)}
          />
          <button
            className={`item store icon-pin-location ${selectedTab === SIDEBAR_TAB_STORES && 'active'}`}
            onClick={() => setSelectedTab(SIDEBAR_TAB_STORES)}
          />
          */}
        </div>
      </div>
      <div
        className={`sidebar__subMenuWrapper ${isMenuOpened ? '' : 'hidden'}`}
      >
        <Furnitures
          setOpenedSubMenu={setOpenedSubMenu}
          openedSubMenu={openedSubMenu}
          selectedFurnitureRef={selectedFurnitureRef}
          selectionUiPos={selectionUiPos}
          setSelectionUiPos={setSelectionUiPos}
          setFurnitureSize={setFurnitureSize}
          isShortCeiling={isShortCeiling}
        />
        {/*
        {selectedTab === SIDEBAR_TAB_MAPS && <Maps />}
        {selectedTab === SIDEBAR_TAB_STORES && <Stores />}
          */}
      </div>
    </section>
  );
};

Sidebar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  selectedFurnitureRef: PropTypes.object.isRequired,
  selectionUiPos: PropTypes.object.isRequired,
  setSelectionUiPos: PropTypes.func.isRequired,
  setFurnitureSize: PropTypes.func.isRequired,
  isShortCeiling: PropTypes.bool.isRequired,
};

export default Sidebar;
