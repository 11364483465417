import { call, put, takeEvery } from 'redux-saga/effects';
import { types, actions as skuActions } from '../../reducers/sku';
import { get, patch, jsonAPIBody } from '../../api/sku';
import { getAllIds, getById } from '../../utils/responseData';

function* getSKUs(action) {
  try {
    const { page, search, category } = action.payload;
    const response = yield call(get, page, search, category);
    const { data } = response.data;
    yield put(skuActions.setSKUs({
      allIds: getAllIds(data),
      byId: getById(data),
      lastPage: response.data.last_page,
      currentPage: response.data.current_page,
      total: response.data.total,
      perPage: response.data.per_page
    }));
  } catch (error) {
    yield put(skuActions.error(error));
  }
}

function* putSKU(action) {
  try {
    const { id, usedName, importedName, twodAbbrevName, categoryId, subCategoryId, subSubcategoryId, colorsId, sizesId } = action.payload;
    const reqBody = jsonAPIBody(usedName, importedName, twodAbbrevName, categoryId, subCategoryId, subSubcategoryId, colorsId, sizesId);
    const response = yield call(patch, id, reqBody);
    const { data } = response;
    yield put(skuActions.setSKU(id, { ...data }));
  } catch (error) {
    yield put(skuActions.error(error));
  }
}

export default function* watchSKU() {
  yield takeEvery(types.GET_REQUEST, getSKUs);
  yield takeEvery(types.PUT_REQUEST, putSKU);
}
