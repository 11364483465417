import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useToasts } from 'react-toast-notifications';

import { actions as furnitureActions } from '../../reducers/furniture';
import { actions as sidebarActions } from '../../reducers/sidebar';
import { actions as skuActions } from '../../reducers/sku';
import { APIContext } from '../../context/APIContext';
import * as SidebarSelectors from '../../selectors/sidebar';
import * as FurnitureSelectors from '../../selectors/furniture';

import { TRANSLATE_MODE, ROTATE_MODE, TOOLBAR_POS } from '../../constants';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';

const ModelToolbar = ({
  selectionUiPos,
  setSelectionUiPos,
  selectedFurnitureRef,
  translateMode,
  setTranslateMode,
  setLeftDimensionUIPo,
  setTopDimensionUIPo,
  selectedView,
  attachedLabel,
  isShortCeiling,
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isFineTune, setIsFineTune] = useState(false);
  const [isCenterable, setCenterable] = useState(false);
  const [isWall, setIsWall] = useState(false);
  const sideBarOpen = useSelector(SidebarSelectors.getMenuOpened);
  const showDimension = useSelector(FurnitureSelectors.getShowDimensions);
  const furniture = useSelector(FurnitureSelectors.getById);
  const newSearchVal = useSelector(FurnitureSelectors.getSearchVal);
  const selectedFurniture = useSelector(
    FurnitureSelectors.getSelectedFurniture,
  );

  const apiContext = useContext(APIContext);
  const { api } = apiContext;

  const ToolbarTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: 10,
      fontFamily: 'Proxima Nova',
      fontWeight: '500',
      letterSpacing: '1px',
      lineHeight: '12px',
      marginLeft: 3,
    },
  }))(Tooltip);

  useEffect(() => {
    if (selectionUiPos.top === 0 || selectionUiPos.left === 0) {
      dispatch(furnitureActions.setShowDimensions(false));
    }

    if (api) {
      setTimeout(() => {
        setCenterable(api.isSelectedObjectCenterable());
        const rootObject = api.getRootObject(api.selectedObject);
        if (rootObject) {
          setIsWall(rootObject.userData?.isWallObject);
          return;
        }
        setIsWall(false);
      }, 0);
    }
  }, [selectionUiPos, dispatch, api]);

  useEffect(() => {
    if (api) {
      let params = api.objectSelectionParams;
      if (params && params.transformControlParams) {
        params.transformControlParams.mode = translateMode;
        params.transformControlParams.rotationSnap =
          translateMode === TRANSLATE_MODE ? Math.PI / 2 : Math.PI / 4;
        params.transformControlParams.translationSnap =
          translateMode === TRANSLATE_MODE ? '' : null;
        api.objectSelectionParams = params;
        setIsFineTune(false);
      }
    }
  }, [api, translateMode]);

  const showOptions = useCallback(() => {
    dispatch(furnitureActions.setShowDimensions(false));
    if (api.selectedObject) {
      const rootObject = api.getRootObject(api.selectedObject);
      const { furnitureId } = rootObject?.userData;

      if (furnitureId) {
        const thisFurniture = furniture[furnitureId];
        const skuNum = rootObject.userData.sku;
        const skuKey = rootObject.userData.skuKey;
        const sku = thisFurniture.skus.find(
          (s) => s.skuNum === skuNum && s.keyVal === skuKey,
        );
        let showSideBar = !sideBarOpen;

        if (sideBarOpen && furnitureId !== selectedFurniture) {
          showSideBar = true;
        }

        batch(() => {
          dispatch(sidebarActions.setMenuOpened(showSideBar));
          dispatch(skuActions.setSkuOptions(sku.options));
          dispatch(furnitureActions.setSelectedFurniture(furnitureId));
          dispatch(furnitureActions.setShowFurnitureDetails(showSideBar));
        });

        if (!sideBarOpen) {
          dispatch(sidebarActions.setOpenedFromMap(showSideBar));
        }
      }
    }
  }, [dispatch, api, sideBarOpen, furniture, selectedFurniture]);

  const removeFurniture = useCallback(() => {
    setSelectionUiPos(TOOLBAR_POS);
    dispatch(furnitureActions.setShowDimensions(false));
    if (api.selectedObject) {
      const rootObject = api.getRootObject(api.selectedObject);
      if (rootObject) {
        api.removeObject(rootObject);
      }
    }

    selectedFurnitureRef.current = null;
    batch(() => {
      dispatch(furnitureActions.setSelectedFurniture(''));
      dispatch(sidebarActions.setOpenedFromMap(false));
      if (newSearchVal.length < 1) {
        dispatch(furnitureActions.setShowFurnitureDetails(false));
      }
    });
  }, [dispatch, api, setSelectionUiPos, selectedFurnitureRef, newSearchVal]);

  const centerSelectedObject = useCallback(() => {
    if (api) {
      api.centerSelectedObject();
    }
  }, [api]);

  const handleDimension = useCallback(() => {
    if (!showDimension && api.selectedObject) {
      const rootObject = api.getRootObject(api.selectedObject);
      if (rootObject) {
        const thisFurniture = furniture[rootObject.userData.furnitureId];
        let leftP2d = null;
        let topP2d = null;

        if (thisFurniture.placementType.includes('WALL')) {
          leftP2d = api.getObjectBoundaryWorldPoint(rootObject, 0, 0.7, 0);
          topP2d = api.getObjectBoundaryWorldPoint(rootObject, 0, 0, 0.7);
        } else if (
          thisFurniture.placementType.includes('CEILING') &&
          selectedView === 'fpc'
        ) {
          topP2d = api.getObjectBoundaryWorldPoint(rootObject, 0, -0.1, -0.7);
          leftP2d = api.getObjectBoundaryWorldPoint(rootObject, -0.6, -0.4, 0);
        } else {
          leftP2d = api.getObjectBoundaryWorldPoint(rootObject, 0.6, 0, 0);
          topP2d = api.getObjectBoundaryWorldPoint(rootObject, 0, 0, 0.7);
        }

        setTopDimensionUIPo({
          left: `${topP2d.x}px`,
          top: `${topP2d.y}px`,
        });

        setLeftDimensionUIPo({
          left: `${leftP2d.x}px`,
          top: `${leftP2d.y}px`,
        });

        dispatch(furnitureActions.setShowDimensions(true));
      }
    } else {
      dispatch(furnitureActions.setShowDimensions(false));
    }
  }, [
    dispatch,
    api,
    furniture,
    showDimension,
    setTopDimensionUIPo,
    setLeftDimensionUIPo,
    selectedView,
  ]);

  const enableRotate = useCallback(() => {
    const params = api.objectSelectionParams;
    const mode =
      params.transformControlParams.mode === TRANSLATE_MODE
        ? ROTATE_MODE
        : TRANSLATE_MODE;
    setTranslateMode(mode);
  }, [api, setTranslateMode]);

  const switchToFineTune = useCallback(() => {
    let params = api.objectSelectionParams;
    setIsFineTune(!isFineTune);
    if (params.transformControlParams.rotationSnap) {
      params.transformControlParams.rotationSnap = null;
    } else {
      params.transformControlParams.rotationSnap = Math.PI / 4;
    }

    api.objectSelectionParams = params;
  }, [api, isFineTune]);

  const copyModel = () => {
    if (api.selectedObject) {
      const selectObject = api.selectedObject;
      const rootObject = api.getRootObject(selectObject);
      if (rootObject) {
        const copiedData = {
          constraint: rootObject.constraint,
          rotation: rootObject.rotation,
          userData: rootObject.userData,
        };
        batch(() => {
          dispatch(furnitureActions.setCopiedModel(copiedData));
          dispatch(furnitureActions.setCopiedCeilingState(isShortCeiling));
        });
        addToast('Copied to Clipboard', {
          appearance: 'success',
        });
      }
    }
  };

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      {selectionUiPos && selectionUiPos.left > 0 && (
        <div
          id="selection_ui"
          key="selection_ui"
          className="unselectable"
          style={{
            ...selectionUiPos,
            left: selectionUiPos.left + 50,
          }}
          onPointerDown={onPointerDown}
        >
          {!attachedLabel && (
            <ToolbarTooltip title="INFO" placement="right">
              <div
                className="info icon"
                key="showOptions"
                onClick={showOptions}
              />
            </ToolbarTooltip>
          )}
          {!attachedLabel && (
            <ToolbarTooltip title="COPY" placement="right">
              <div className="icon">
                <FileCopyIcon
                  className="copy"
                  fontSize="small"
                  key="doCopy"
                  onClick={copyModel}
                />
              </div>
            </ToolbarTooltip>
          )}
          {!attachedLabel && (
            <ToolbarTooltip title="DELETE" placement="right">
              <div
                className="delete icon"
                key="removeFurniture"
                onClick={removeFurniture}
              />
            </ToolbarTooltip>
          )}
          {!attachedLabel && (
            <ToolbarTooltip title="DIMENSIONS" placement="right">
              <div
                className="measure icon"
                key="handleDimension"
                onClick={handleDimension}
              />
            </ToolbarTooltip>
          )}
          <ToolbarTooltip
            title={
              translateMode === TRANSLATE_MODE || translateMode === ''
                ? 'ROTATE'
                : 'MOVE'
            }
            placement="right"
          >
            <div
              className={classNames('icon', {
                dPad: translateMode === ROTATE_MODE,
                configure:
                  translateMode === '' || translateMode === TRANSLATE_MODE,
              })}
              key="enableRotate"
              onClick={enableRotate}
            />
          </ToolbarTooltip>
          {translateMode === ROTATE_MODE && (
            <ToolbarTooltip title="FINE TUNE" placement="right">
              <div
                className={classNames('fineTune icon', {
                  active: isFineTune,
                })}
                key="fineTune"
                onClick={switchToFineTune}
              />
            </ToolbarTooltip>
          )}
          {isCenterable && (
            <ToolbarTooltip title="CENTER" placement="right">
              <div className="icon">
                <VerticalAlignCenterIcon
                  className={isWall ? 'centerVertical' : 'center'}
                  key="center"
                  fontSize="small"
                  onClick={centerSelectedObject}
                />
              </div>
            </ToolbarTooltip>
          )}
        </div>
      )}
    </>
  );
};

ModelToolbar.propTypes = {
  selectionUiPos: PropTypes.object.isRequired,
  setSelectionUiPos: PropTypes.func.isRequired,
};

export default React.memo(ModelToolbar);
