import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import * as MapsSelector from '../../selectors/maps';
import { actions as mapActions } from '../../reducers/maps';

const ConfirmDeleteModal = ({ mapToDelete, setShowConfirm }) => {
  const ConfirmDeleteContent = () => (
    <div className="overwriteModal">
      Delete '{mapToDelete?.name}'. Are you sure?
    </div>
  );

  const ConfirmDeleteButtons = () => {
    const dispatch = useDispatch();
    const loading = useSelector(MapsSelector.getLoading);

    const deleteMap = async () => {
      dispatch(mapActions.deleteRequest(mapToDelete.id));
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Yes"
            onClick={deleteMap}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConfirmDeleteContent />}
      buttons={<ConfirmDeleteButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConfirmDeleteModal.propTypes = {
  mapToDelete: PropTypes.object.isRequired,
  setShowConfirm: PropTypes.func.isRequired,
};
export default ConfirmDeleteModal;
