export const getAllIds = (arr) => arr.map((doc) => doc.id);

export const getById = (arr) =>
  arr.reduce((acc, curr) => {
    acc[curr.id] = {
      id: curr.id,
      ...curr,
    };
    return acc;
  }, {});

export const normalizeCategories = (arr) => {
  const allIds = getAllIds(arr.filter((doc) => doc.level === '0'));
  const byId = getById(arr.filter((doc) => doc.level === '0'));
  allIds.map((id) => {
    byId[id] = {
      ...byId[id],
      subCategories: {
        byId: getById(arr.filter((doc) => doc.parent === id)),
        allIds: getAllIds(arr.filter((doc) => doc.parent === id)),
      },
    };
    byId[id].subCategories.allIds.map((subCategoryId) => {
      byId[id].subCategories.byId[subCategoryId] = {
        ...byId[id].subCategories.byId[subCategoryId],
        subSubCategories: {
          byId: getById(arr.filter((doc) => doc.parent === subCategoryId)),
          allIds: getAllIds(arr.filter((doc) => doc.parent === subCategoryId)),
        },
      };
      return subCategoryId;
    });
    return id;
  });
  return { allIds, byId };
};
