import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import { APIContext } from '../../context/APIContext';
import * as MapsSelector from '../../selectors/maps';
import { actions as mapActions } from '../../reducers/maps';

const ConfirmOverwriteModal = ({ setShowConfirm }) => {
  const mapToOverwrite = useSelector(MapsSelector.getMapToOverwrite);

  const ConfirmOverwriteContent = () => (
    <div className="overwriteModal">
      Overwrite '{mapToOverwrite.name}'. Are you sure?
    </div>
  );

  const ConfirmOverwriteButtons = () => {
    const dispatch = useDispatch();
    const apiContext = useContext(APIContext);
    const { api } = apiContext;
    const loading = useSelector(MapsSelector.getLoading);
    const isDirtyMap = useSelector(MapsSelector.getisDirty);

    useEffect(() => {
      const overwriteMap = async () => {
        const currentScene = await api.exportScene();
        //const snapshot = api.getSnapShot2D(PNG_SNAP_QUALITY);

        mapToOverwrite.scene = currentScene;

        dispatch(mapActions.patchRequest(mapToOverwrite));
      };

      if (isDirtyMap && isDirtyMap !== 'NOT_SET') {
        dispatch(mapActions.setIsDirty('NOT_SET'));
        if (isDirtyMap === 'CLEAN') {
          overwriteMap();
        }
      }
    }, [dispatch, isDirtyMap, api]);

    const beginOverwriteMap = () => {
      dispatch(
        mapActions.getIsDirtyRequest(
          mapToOverwrite.id,
          mapToOverwrite.updatedAt,
        ),
      );
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Yes"
            onClick={() => beginOverwriteMap()}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConfirmOverwriteContent />}
      buttons={<ConfirmOverwriteButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConfirmOverwriteModal.propTypes = {
  setShowConfirm: PropTypes.func.isRequired,
};
export default ConfirmOverwriteModal;
