import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Vector3 } from 'three';

import MapNotLoadedModal from '../../scenes/Modals/mapNotLoaded';
import LoadingModal from '../../scenes/Modals/loadingModal';
import SaveModal from '../../scenes/Modals/save';
import NotAllowedOfflineModal from '../../scenes/Modals/notAllowedOffline';
import { APIContext } from '../../context/APIContext';
import { generateCsv } from '../../utils/generateCsv';
import { generatePdf } from '../../utils/generatePdf';
import * as FurnitureSelectors from '../../selectors/furniture';
import * as MapSelectors from '../../selectors/maps';
import { actions as mapActions } from '../../reducers/maps';
import { actions as sidebarActions } from '../../reducers/sidebar';
import { actions as furnitureActions } from '../../reducers/furniture';

import {
  ROTATION_INCREMENT,
  CAMERA_ROTATION,
  ZOOM_INCREMENT,
  FOV_ZOOM_INCREMENT,
  UP_VECTORS,
  TOOLBAR_POS,
  PNG_PRINT_QUALITY,
  ROOM_NAMES,
  PNG_RENDER_SIZE,
  OFFLINE_ERROR_CSV,
  OFFLINE_ERROR_PDF,
} from '../../constants';

const MapToolbar = ({
  canvasRef,
  setSelectionUiPos,
  selectedFurnitureRef,
  setLeftDimensionUIPo,
  setTopDimensionUIPo,
  containerRef,
  roomNamesEnabled,
  setRoomNamesEnabled,
  fovVal,
  setFovVal,
  selectedRotIndex,
  setSelectedRotIndex,
  setPasteButtonPos,
  selectedView,
}) => {
  const dispatch = useDispatch();
  const canvas = canvasRef.current;
  const [showNotLoadedModal, setShowMapNotLoaded] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [measureEnabled, setMeasureEnabled] = useState(false);
  const [showNotAllowedOffline, setShowNotAllowedOffline] = useState(false);
  const [offlineErrorText, setOfflineErrorText] = useState('');
  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const furniture = useSelector(FurnitureSelectors.getById);
  const mapPdfUrl = useSelector(MapSelectors.getPdfUrl);
  const getMapPdfComplete = useSelector(MapSelectors.getPdfComplete);
  const loadedMap = useSelector(MapSelectors.getLoadedMap);
  const loading = useSelector(MapSelectors.getFetchingPdf);

  useEffect(() => {
    if (getMapPdfComplete && mapPdfUrl) {
      const fileName = loadedMap ? loadedMap.name : 'floor_plan.pdf';
      generatePdf({
        url: mapPdfUrl,
        name: fileName,
      });
      dispatch(mapActions.setGetPdfComplete(false));
    }
  }, [dispatch, mapPdfUrl, getMapPdfComplete, loadedMap]);

  useEffect(() => {
    if (api) {
      ROOM_NAMES.map(async (roomName) => {
        let roomDiv = document.getElementById(roomName);
        if (roomNamesEnabled) {
          const roomObject = api.getObjectByNameOrId(roomName);

          if (roomObject) {
            // NOTE(Jesse): Bug documented at the following tag : @buggy-leftSide-top-value
            const p2d = api.getObjectBoundaryPoint(roomObject, null, 'top');

            if (!roomDiv) {
              roomDiv = document.createElement('div');
              roomDiv.textContent = roomObject.name;
              roomDiv.id = roomObject.name;
              roomDiv.classList.add('room-label');
              roomDiv.style.display = 'block';
              roomDiv.style.left = `${p2d.x - 130}px`;
              roomDiv.style.top = `${p2d.y + 70}px`;
              containerRef.current.appendChild(roomDiv);
            } else {
              roomDiv.style.display = 'block';
              roomDiv.style.left = `${p2d.x - 130}px`;
              roomDiv.style.top = `${p2d.y + 70}px`;
            }
          }
        } else if (roomDiv && roomDiv.style.display === 'block') {
          roomDiv.style.display = 'none';
        }
      });
    }
  }, [api, containerRef, roomNamesEnabled]);

  const rotateCamera = useCallback(
    (angle, cameraState) => {
      if (cameraState.type === 'orbit') {
        const cameraPosition = cameraState.position;
        const cameraTarget = cameraState.target;

        if (cameraPosition && cameraTarget) {
          const x_ =
            (cameraPosition.x - cameraTarget.x) * Math.cos(angle) +
            (cameraPosition.z - cameraTarget.z) * Math.sin(angle);
          const z_ =
            -(cameraPosition.x - cameraTarget.x) * Math.sin(angle) +
            (cameraPosition.z - cameraTarget.z) * Math.cos(angle);
          cameraPosition.x = cameraTarget.x + x_;
          cameraPosition.z = cameraTarget.z + z_;
          api.setCameraControls(cameraState);
        }
      }
      if (cameraState.type === 'fpc') {
        angle = -angle;
        const cameraPosition = cameraState.position;
        const cameraTarget = cameraState.target;
        if (cameraPosition && cameraTarget) {
          const direction = new Vector3()
            .copy(cameraTarget)
            .sub(cameraPosition)
            .normalize();

          const x_ =
            direction.x * Math.cos(angle) + direction.z * Math.sin(angle);
          const z_ =
            -direction.x * Math.sin(angle) + direction.z * Math.cos(angle);
          cameraTarget.x = cameraPosition.x + x_;
          cameraTarget.z = cameraPosition.z + z_;

          api.setCameraControls(cameraState);
        }
      }
    },
    [api],
  );

  const rotate = useCallback(
    (type) => {
      const cameraState = api.captureCameraState().cameraState;
      if (selectedView === '2D') {
        return;
      } else if (cameraState.type === '3d') {
        const increment =
          type === 'CLOCKWISE' ? -ROTATION_INCREMENT : ROTATION_INCREMENT;
        let rotIndex = selectedRotIndex + increment;
        setRoomNamesEnabled(false);
        if (rotIndex > 3) {
          rotIndex = 0;
        } else if (rotIndex < 0) {
          rotIndex = 3;
        }

        setSelectedRotIndex(rotIndex);
        const upVector = UP_VECTORS[rotIndex];
        const radius = api.sceneSphere.radius;
        const target = api.sceneCentre;
        const position = { x: radius, y: radius, z: radius };
        const maxDistance = api.sceneSphere.radius * 2;

        api.setCameraControls({
          type: 'fixed',
          position: position,
          target: target,
          maxDistance: maxDistance,
        });
        api.sceneView = {
          side: 'top',
          up: upVector,
          enableZoom: true,
          enablePan: true,
          maxDistance: maxDistance,
        };
        setSelectionUiPos(TOOLBAR_POS);
        setLeftDimensionUIPo(TOOLBAR_POS);
        setTopDimensionUIPo(TOOLBAR_POS);
        setPasteButtonPos(TOOLBAR_POS);
        api.selectObject({}, {});
        batch(() => {
          dispatch(furnitureActions.setSelectedFurniture(''));
          dispatch(furnitureActions.setShowFurnitureDetails(false));
          dispatch(furnitureActions.setSearchValue(''));
          dispatch(furnitureActions.setSelectedCategory(''));
          dispatch(furnitureActions.setSelectedType(''));
          dispatch(furnitureActions.setSelectedSubCategory(''));
          dispatch(sidebarActions.setOpenedFromMap(false));
          dispatch(sidebarActions.setMenuOpened(false));
        });
      } else {
        const increment =
          type === 'CLOCKWISE' ? CAMERA_ROTATION : -CAMERA_ROTATION;
        rotateCamera(increment, cameraState);
      }
    },
    [
      dispatch,
      rotateCamera,
      api,
      selectedRotIndex,
      setRoomNamesEnabled,
      setLeftDimensionUIPo,
      setSelectionUiPos,
      setTopDimensionUIPo,
      setPasteButtonPos,
      setSelectedRotIndex,
      selectedView,
    ],
  );

  const zoom = useCallback(
    (zoomDirection) => {
      const camera = api.captureCameraState();
      if (camera.cameraState.type === 'fpc') {
        let newFov = fovVal;
        if (zoomDirection === 'NEGATIVE') {
          if (fovVal <= 10) return;

          newFov = newFov - FOV_ZOOM_INCREMENT;
        } else {
          if (fovVal >= 90) return;
          newFov = newFov + FOV_ZOOM_INCREMENT;
        }
        api.setCameraMode('perspective', { fov: newFov });
        setFovVal(newFov);
      }
      let zoomAmount = ZOOM_INCREMENT;
      if (zoomDirection === 'NEGATIVE') {
        zoomAmount = -zoomAmount;
      }
      const evt = document.createEvent('MouseEvents');
      evt.initEvent('wheel', true, true);
      evt.deltaY = zoomAmount;
      canvas.dispatchEvent(evt);
    },
    [canvas, api, fovVal, setFovVal],
  );

  const downloadCsv = useCallback(async () => {
    if (navigator.onLine) {
      if (loadedMap) {
        if (api) {
          const currentScene = await api.exportScene();

          const furnitureModels = currentScene.models.reduce(
            (filtered, model) => {
              if (model.userData.furnitureId) {
                const thisFurniture = furniture[model.userData.furnitureId];
                const thisSku = thisFurniture.skus.find(
                  (s) => s.keyVal === model.userData.skuKey,
                );
                if (thisSku) {
                  const newModel = [
                    `"${thisSku.skuNum.toUpperCase().trim()}"`,
                    ` ${
                      thisFurniture.name
                        ? thisFurniture.name.toUpperCase().trim()
                        : thisFurniture.label.toUpperCase().trim()
                    }`,
                    ' 140',
                  ];

                  filtered.push(newModel);
                }
              }
              return filtered;
            },
            [],
          );

          generateCsv({
            name: 'floor_plan',
            fields: ['"SKU"', ' Description', ' store'],
            data: furnitureModels,
          });
        }
      } else {
        setShowSaveModal(true);
      }
    } else {
      setOfflineErrorText(OFFLINE_ERROR_CSV);
      setShowNotAllowedOffline(true);
    }
  }, [api, furniture, loadedMap]);

  const downloadPdf = useCallback(async () => {
    if (navigator.onLine) {
      if (loadedMap) {
        const currentScene = await api.exportScene();
        const snapshot = api.getSnapShot2D(PNG_PRINT_QUALITY, PNG_RENDER_SIZE);
        dispatch(
          mapActions.mapPdfRequest(loadedMap.id, currentScene, snapshot),
        );
      } else {
        setShowSaveModal(true);
      }
    } else {
      setOfflineErrorText(OFFLINE_ERROR_PDF);
      setShowNotAllowedOffline(true);
    }
  }, [dispatch, loadedMap, api]);

  const handleMeasurementTool = useCallback(() => {
    if (!measureEnabled) {
      api.measurementParams = {
        enable: true,
        mode: 'diagonal',
        drawText: true,
        unit: 'in',
      };
      canvasRef.current.style.cursor = 'crosshair';
      setMeasureEnabled(true);
      setSelectionUiPos(TOOLBAR_POS);
      setLeftDimensionUIPo(TOOLBAR_POS);
      setTopDimensionUIPo(TOOLBAR_POS);
      setPasteButtonPos(TOOLBAR_POS);
      api.selectObject({}, {});
      api.enableCameraControls(false);
      selectedFurnitureRef.current = null;
    } else {
      setMeasureEnabled(false);
      api.enableCameraControls(true);
      api.measurementParams = {
        enable: false,
      };
      canvasRef.current.style.cursor = '';
    }
  }, [
    api,
    measureEnabled,
    canvasRef,
    setSelectionUiPos,
    selectedFurnitureRef,
    setLeftDimensionUIPo,
    setTopDimensionUIPo,
    setPasteButtonPos,
  ]);

  const showRoomNames = useCallback(() => {
    const camera = api.captureCameraState();
    if (camera.cameraState.type !== 'fpc') {
      setRoomNamesEnabled(!roomNamesEnabled);
    }
  }, [api, roomNamesEnabled, setRoomNamesEnabled]);

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      <div
        className="controllerWrapper unselectable"
        onPointerDown={onPointerDown}
      >
        <div className="buttonWrapper">
          <button
            className="btnController item7"
            key="rotate1"
            onClick={() => rotate('CLOCKWISE')}
          />
          <button
            className={classNames('btnController item3', {
              selected: measureEnabled,
            })}
            onClick={handleMeasurementTool}
          />
        </div>
        <div className="buttonWrapper">
          <button
            key="rotate2"
            className="btnController item6"
            onClick={() => rotate('COUNTERCLOCKWISE')}
          />
          <button
            className={classNames('btnController item1', {
              selected: roomNamesEnabled,
            })}
            onClick={showRoomNames}
          />
        </div>
        <div className="buttonWrapper">
          <button
            key="zoom1"
            className="btnController item5"
            onClick={() => zoom('NEGATIVE')}
          />
          <button
            className="btnController item2"
            disabled={loading}
            onClick={downloadPdf}
          />
        </div>
        <div className="buttonWrapper">
          <button
            className="btnController item4"
            key="zoom2"
            onClick={() => zoom('POSITIVE')}
          />
          <button
            key="downloadCsv"
            className="btnController item8"
            onClick={downloadCsv}
          />
        </div>
      </div>
      {showNotLoadedModal && (
        <MapNotLoadedModal showModal={setShowMapNotLoaded} />
      )}
      {showSaveModal && (
        <SaveModal
          showModal={setShowSaveModal}
          setShowIntroModal={(val) => {}}
        />
      )}
      {loading && <LoadingModal text="Generating PDF. Please Wait." />}
      {showNotAllowedOffline && (
        <NotAllowedOfflineModal
          showModal={setShowNotAllowedOffline}
          text={offlineErrorText}
        />
      )}
    </>
  );
};

MapToolbar.propTypes = {
  canvasRef: PropTypes.object.isRequired,
  setSelectionUiPos: PropTypes.func.isRequired,
  selectedFurnitureRef: PropTypes.object.isRequired,
  setLeftDimensionUIPo: PropTypes.func.isRequired,
  setTopDimensionUIPo: PropTypes.func.isRequired,
};

export default React.memo(MapToolbar);
