import API from '../API';

const urlParams = (page, search, category) => {
  if (page || search || category) {
    let params = '?';
    if (search) {
      params = `${params}search=${search}`;
    }
    if (category) {
      params = `${params}&category=${category}`;
    }
    if (page) {
      params = `${params}&page=${page}`;
    }
    return params;
  }
  return '';
};

export const get = (page, search, category) => API.get(`/sku${urlParams(page, search, category)}`);

export const getOne = id => API.get(`/sku/${id}`);

export const post = payload => API.post('/sku', payload);

export const patch = (id, payload) => API.put(`/sku/${id}`, payload);

export const jsonAPIBody = (usedName, importedName, twodAbbrevName, categoryId, subCategoryId, subSubcategoryId, colorsId, sizesId) => ({
  usedName,
  importedName,
  twodAbbrevName,
  categoryId,
  subCategoryId,
  subSubcategoryId,
  colorsId,
  sizesId
});
