import { call, put, takeEvery } from 'redux-saga/effects';
import {
  types,
  actions as furnitureTypeActions,
} from '../../reducers/furnitureTypes';
import { getSession } from '../../auth/user';
import { get } from '../../api/furnitureTypes';
import { getAllIds, getById } from '../../utils/responseData';

function* getFurnitureTypes() {
  try {
    const session = yield call(getSession);
    const response = yield call(get, session.idToken.jwtToken);
    const { data } = response;
    yield put(
      furnitureTypeActions.setFurnitureTypes({
        allIds: getAllIds(data),
        byId: getById(data),
      }),
    );
  } catch (error) {
    yield put(furnitureTypeActions.error(error));
  }
}

export default function* watchFurnitureTypes() {
  yield takeEvery(types.GET_REQUEST, getFurnitureTypes);
}
