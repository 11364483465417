import API, { config } from '../API';

export const get = (dateFrom, token) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.get(`/furniture?dateFrom=${dateFrom}`, apiConf);
};

export const getOne = (id) => API.get(`/furniture/${id}/sku`);

export const post = (token, payload) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.post('/furniture', payload, apiConf);
};

export const patch = (token, id, payload) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.patch(`/furniture/${id}`, payload.furniture, apiConf);
};

export const remove = (token, id) => {
  const apiConf = config;
  apiConf.headers.Authorization = `Bearer ${token}`;
  return API.delete(`/furniture/${id}`, apiConf);
};

/*
  id?: string;
  type: string;
  label: string;
  sku: string;
  city: string;
  category: string[];
  file: string;
  image: string;
  furnitureType: string[];
  subCategory: string[];
  materials: Material[];
  sizes: string[];
  description: string;
  details: string[];
  png: string;
*/

export const jsonAPIBody = (
  type,
  label,
  sku,
  city,
  category,
  file,
  image,
  png,
) => ({
  type,
  label,
  sku,
  city,
  category,
  file,
  image,
  png,
});
