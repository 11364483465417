import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from 'react-table';
import classNames from 'classnames';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useToasts } from 'react-toast-notifications';

import Sidebar from './sidebar';
import * as FurnitureSelectors from '../../selectors/furniture';
import ConfirmFurnitureDeleteModal from '../Modals/confirmFurnDelete';
import { actions as furnitureActions } from '../../reducers/furniture';

import './style.scss';

const SKUList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [selectedSku, setSelectedSku] = useState(null);
  const [showDeleteMsg, setShowDeleteMsg] = useState(false);
  const [furnToDelete, setFurnToDelete] = useState(null);
  const [displayPages, setDisplayPages] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const dispatch = useDispatch();
  const FURNITURE = useSelector((state) => state.furniture.byId);
  const CATEGORIES = useSelector((state) => state.categories.byId);
  const SUBCATEGORIES = useSelector((state) => state.subCategories.byId);
  const FURNITURETYPES = useSelector((state) => state.furnitureTypes.byId);
  const deleteComplete = useSelector(FurnitureSelectors.getDeleteComplete);
  const furnError = useSelector(FurnitureSelectors.getError);
  const searchRef = useRef(null);
  const { addToast } = useToasts();

  useEffect(() => {
    const loadSKU = () => {
      dispatch(furnitureActions.getRequest());
    };
    loadSKU();
  }, [dispatch]);

  useEffect(() => {
    if (deleteComplete && !furnError?.message) {
      addToast('Delete Successful', {
        appearance: 'success',
      });
      setShowDeleteMsg(false);
    } else if (deleteComplete && furnError?.message) {
      addToast('Error Performing Delete', {
        appearance: 'error',
      });
    }
    dispatch(furnitureActions.setDeleteComplete(false));
  }, [dispatch, addToast, deleteComplete, furnError]);

  const openSidebar = (furnitureId) => {
    const furniture = FURNITURE[furnitureId];
    setLoadData(true);
    setSelectedSku(furniture);
    setIsOpen(true);
  };

  const addNewFurniture = () => {
    setSelectedSku({
      furnitureTypes: FURNITURETYPES[0],
      categories: CATEGORIES[0],
      subCategories: SUBCATEGORIES[0],
      sku: { skuNum: '' },
    });
    setIsOpen(true);
  };

  const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  };

  const handleDeleteClick = useCallback(
    (e, furnitureId) => {
      e.stopPropagation();
      const furniture = FURNITURE[furnitureId];
      setFurnToDelete(furniture);
      setShowDeleteMsg(true);
    },
    [FURNITURE],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Used Name',
        accessor: 'label',
        canFilter: false,
        Filter: null,
        filter: '',
      },
      {
        Header: 'CATEGORY',
        accessor: 'furnitureType',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'SUBCATEGORY',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'SUB-SUBCATEGORY',
        accessor: 'subCategory',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        HEADER: '',
        accessor: 'delete',
        canFilter: false,
        Filter: null,
        filter: '',
        disableSortBy: true,
      },
      {
        HEADER: 'ID',
        accessor: 'furnitureId',
        canFilter: false,
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
    ],
    [],
  );

  const data = React.useMemo(() => {
    return Object.values(FURNITURE).reduce((acc, furniture) => {
      const row = {};
      const {
        id,
        name,
        furnitureType: furnitureTypeIds,
        category: categoryIds,
        subCategory: subCategoryIds,
      } = furniture;

      const furnitureTypes = furnitureTypeIds.map((id) => FURNITURETYPES[id]);
      const categories = categoryIds.map((id) => CATEGORIES[id]);
      const subCategories = subCategoryIds.map((id) => SUBCATEGORIES[id]);
      const hasFile = furniture?.skus.find((s) => s.file);

      row['furnitureId'] = id;
      row['label'] = name;
      row['furnitureType'] = furnitureTypes
        ?.map((type) => type?.name)
        .join(',');
      row['category'] = categories?.map((cat) => cat?.name).join(',');
      row['subCategory'] = subCategories
        ?.map((subCat) => subCat?.name)
        .join(',');
      if (!hasFile) {
        row['delete'] = (
          <button
            mapid={id}
            className="deleteBtn"
            onClick={(e) => handleDeleteClick(e, id)}
          />
        );
      }

      acc.push(row);
      return acc;
    }, []);
  }, [FURNITURE, CATEGORIES, FURNITURETYPES, SUBCATEGORIES, handleDeleteClick]);

  const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
      searchRef.current.value = value;
      searchRef.current.focus();
    }, 200);
    return (
      <input
        value={value}
        type="search"
        ref={searchRef}
        className="searchSku"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search...`}
      />
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handlePageChange = (pageToSet) => {
    gotoPage(pageToSet);
    let newPages = [];
    if (pageToSet + 1 > 4) {
      for (let i = pageToSet - 3; i < pageToSet + 5; i++) {
        newPages.push(i);
      }
    } else {
      newPages = [1, 2, 3, 4, 5, 6, 7, 8];
    }
    setDisplayPages(newPages);
  };

  const handleNextPage = () => {
    let newPages = [];
    if (state.pageIndex + 1 > 3) {
      for (let i = state.pageIndex - 2; i < state.pageIndex + 6; i++) {
        newPages.push(i);
      }
      setDisplayPages(newPages);
    }
    nextPage();
  };

  const handlePreviousPage = () => {
    let newPages = [];
    if (state.pageIndex >= 5) {
      for (let i = state.pageIndex - 4; i < state.pageIndex + 4; i++) {
        newPages.push(i);
      }
    } else {
      newPages = [1, 2, 3, 4, 5, 6, 7, 8];
    }
    setDisplayPages(newPages);
    previousPage();
  };

  return (
    <>
      <button
        style={{
          color: '#000000',
          fontFamily: 'Proxima Nova',
          fontWeight: 500,
          border: `1px solid #000000`,
          background: '#ffffff',
          margin: '10px',
          fontSize: '16px',
          top: '50px',
          position: 'absolute',
          zIndex: '10',
        }}
        onClick={() => {
          addNewFurniture();
        }}
      >
        ADD NEW
      </button>
      {isOpen && <div className="overlay" />}
      <section className="container skulist__page">
        <div className="skulist__page--header">
          <h1>SKU LIST</h1>
          <small>
            Click on a title to edit details of the furniture. (CURRENTLY, ONLY
            'BLOBS' ARE EDITABLE)
          </small>
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table
          {...getTableProps()}
          className="skuTable"
          style={{ width: '100%', maxWidth: '1200px' }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="headerRow">
                      <span className="headerText">
                        {column.render('Header')}
                      </span>
                      {!column.disableSortBy && (
                        <span className="arrows">
                          <>
                            <div>
                              <KeyboardArrowUpIcon
                                className={classNames({
                                  disabled:
                                    column.isSorted && column.isSortedDesc,
                                  'sort-up': true,
                                })}
                              />
                            </div>
                            <div>
                              <KeyboardArrowDownIcon
                                className={classNames({
                                  disabled:
                                    column.isSorted && !column.isSortedDesc,
                                  'sort-down': true,
                                })}
                              />
                            </div>
                          </>
                        </span>
                      )}
                    </div>
                    <div>
                      {column.canFilter &&
                      column.id !== '"furnitureId"' &&
                      column.id !== 'label' &&
                      column.id !== 'delete'
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const furniture = FURNITURE[row.original.furnitureId];
              const hasFile = furniture?.skus.find((s) => s.file);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    if (!hasFile) openSidebar(row.original.furnitureId);
                  }}
                  style={
                    hasFile
                      ? { backgroundColor: '#EFEFEF' }
                      : { backgroundColor: '#F0F8FF' }
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => handlePreviousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          {pageCount > 8 && !displayPages.includes(1) && (
            <>
              <span
                className={classNames({
                  pageNum: true,
                })}
                onClick={() => handlePageChange(0)}
              >
                1
              </span>
              <span>...</span>
            </>
          )}
          {displayPages.map((thisPage, index) => {
            if (thisPage <= pageOptions.length) {
              return (
                <span
                  key={index}
                  className={classNames({
                    selected: thisPage === state.pageIndex + 1,
                    pageNum: index !== 0 || displayPages.includes(1),
                    lastPageNum: pageCount === thisPage,
                  })}
                  onClick={() => handlePageChange(thisPage - 1)}
                >
                  {thisPage}
                </span>
              );
            }
            return false;
          })}
          {pageCount > 8 && !displayPages.includes(pageCount) && (
            <>
              <span>...</span>
              <span
                className={classNames({
                  lastPageNum: true,
                })}
                onClick={() => handlePageChange(pageCount - 1)}
              >
                {pageCount}
              </span>
            </>
          )}
          <button onClick={() => handleNextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
        </div>
      </section>
      {
        <Sidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          sku={selectedSku}
          loadData={loadData}
        />
      }
      {showDeleteMsg && (
        <ConfirmFurnitureDeleteModal
          furnToDelete={furnToDelete}
          setShowConfirm={setShowDeleteMsg}
        />
      )}
    </>
  );
};

export default SKUList;
