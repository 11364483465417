import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import { store } from '../../store';
import { types } from '../../reducers/serviceWorker';
import * as ServiceWorkerSelector from '../../selectors/serviceWorker';

const NewVersionModal = ({ showModal }) => {
  const serviceWorkerRegistration = useSelector(
    ServiceWorkerSelector.getWaitingWorker,
  );
  const onUpdateClick = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
    store.dispatch({
      type: types.UPDATE,
      payload: { updated: false, worker: null },
    });
  };

  const NewVersionContent = () => (
    <div style={{ whiteSpace: 'pre-line' }}>
      A New Version of the planner is available. Please click below to update.
    </div>
  );

  const NewVersionButtons = () => {
    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => onUpdateClick()}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<NewVersionContent />}
      buttons={<NewVersionButtons />}
      overlayStyles="newversion"
      wrapperStyles="offlineWarning"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

NewVersionModal.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default NewVersionModal;
