const NAME = 'FURNITURE';

export const types = {
  GET_REQUEST: `${NAME}/GET_REQUEST`,
  POST_REQUEST: `${NAME}/POST_REQUEST`,
  PATCH_REQUEST: `${NAME}/PATCH_REQUEST`,
  DELETE_REQUEST: `${NAME}/DELETE_REQUEST`,
  SET_FURNITURE: `${NAME}/SET_FURNITURE`,
  SET_SELECTED_TYPE: `${NAME}/SET_SELECTED_TYPE`,
  SET_SELECTED_CATEGORY: `${NAME}/SET_SELECTED_CATEGORY`,
  SET_SELECTED_SUB_CATEGORY: `${NAME}/SET_SELECTED_SUB_CATEGORY`,
  SET_SELECTED_FURNITURE: `${NAME}/SET_SELECTED_FURNITURE`,
  SET_SEARCH_VALUE: `${NAME}/SET_SEARCH_VALUE`,
  SET_SHOW_FURNITURE_DETAILS: `${NAME}/SET_SHOW_FURNITURE_DETAILS`,
  SET_SHOW_DIMENSIONS: `${NAME}/SET_SHOW_DIMENSIONS`,
  SET_LAST_UPDATE: `${NAME}/SET_LAST_UPDATE`,
  REMOVE_DELETED_FROM_SYNC: `${NAME}/REMOVE_DELETED_FROM_SYNC`,
  SET_IS_FETCHING: `${NAME}/SET_IS_FETCHING`,
  CLEAR_FURNITURE: `${NAME}/CLEAR_FURNITURE`,
  SET_FURNITURE_POST: `${NAME}/SET_FURNITURE_POST`,
  SET_SAVE_COMPLETE: `${NAME}/SET_SAVE_COMPLETE`,
  DELETE_REQUEST_SUCCESS: `${NAME}/DELETE_REQUEST_SUCCESS`,
  SET_DELETE_COMPLETE: `${NAME}/SET_DELETE_COMPLETE`,
  SET_COPIED_MODEL: `${NAME}/SET_COPIED_MODEL`,
  SET_COPIED_CEILING_STATE: `${NAME}/SET_COPIED_CEILING_STATE`,
  ERROR: `${NAME}/ERROR`,
};

export const actions = {
  getRequest: (forceRefresh) => ({
    type: types.GET_REQUEST,
    payload: { forceRefresh },
  }),
  postRequest: (furniture, furnitureData) => ({
    type: types.POST_REQUEST,
    payload: { furniture, furnitureData },
  }),
  patchRequest: (furniture, furnitureData) => ({
    type: types.PATCH_REQUEST,
    payload: { furniture, furnitureData },
  }),
  setSelectedFurniture: (selectedFurniture) => ({
    type: types.SET_SELECTED_FURNITURE,
    payload: selectedFurniture,
  }),
  setCopiedModel: (copiedModel) => ({
    type: types.SET_COPIED_MODEL,
    payload: copiedModel,
  }),
  setCopiedCeilingState: (copiedCeilingState) => ({
    type: types.SET_COPIED_CEILING_STATE,
    payload: copiedCeilingState,
  }),
  deleteRequest: (id) => ({ type: types.DELETE_REQUEST, payload: id }),
  deleteRequestSuccess: ({ id }) => ({
    type: types.DELETE_REQUEST_SUCCESS,
    payload: { id },
  }),
  setSelectedType: (selectedType) => ({
    type: types.SET_SELECTED_TYPE,
    payload: selectedType,
  }),
  setSelectedCategory: (selectedCategory) => ({
    type: types.SET_SELECTED_CATEGORY,
    payload: selectedCategory,
  }),
  setPost: (id, payload) => ({
    type: types.SET_FURNITURE_POST,
    payload: { id, ...payload },
  }),
  setSelectedSubCategory: (selectedSubCategory) => ({
    type: types.SET_SELECTED_SUB_CATEGORY,
    payload: selectedSubCategory,
  }),
  setFurniture: (furniture) => ({
    type: types.SET_FURNITURE,
    payload: furniture,
  }),
  setSearchValue: (searchValue) => ({
    type: types.SET_SEARCH_VALUE,
    payload: searchValue,
  }),
  setIsFetching: (isFetching) => ({
    type: types.SET_IS_FETCHING,
    payload: isFetching,
  }),
  setShowFurnitureDetails: (showFurnitureDetails) => ({
    type: types.SET_SHOW_FURNITURE_DETAILS,
    payload: showFurnitureDetails,
  }),
  setShowDimensions: (showDimensions) => ({
    type: types.SET_SHOW_DIMENSIONS,
    payload: showDimensions,
  }),
  setLastUpdate: (lastUpdate) => ({
    type: types.SET_LAST_UPDATE,
    payload: lastUpdate,
  }),
  removeSyncedDeleted: (furniture) => ({
    type: types.REMOVE_DELETED_FROM_SYNC,
    payload: furniture,
  }),
  clearFurniture: () => ({
    type: types.CLEAR_FURNITURE,
  }),
  setSaveComplete: (saveComplete) => ({
    type: types.SET_SAVE_COMPLETE,
    payload: saveComplete,
  }),
  setDeleteComplete: (deleteComplete) => ({
    type: types.SET_DELETE_COMPLETE,
    payload: deleteComplete,
  }),
  error: (error) => ({ type: types.ERROR, payload: error }),
};

const initialState = {
  byId: {},
  selectedFurniture: '',
  selectedType: '',
  selectedCategory: '',
  selectedSubCategory: '',
  searchValue: '',
  showFurnitureDetails: false,
  isFetching: false,
  showDimensions: false,
  lastUpdate: '',
  saveComplete: false,
  deleteComplete: false,
  copiedModel: null,
  copiedCeilingState: false,
  error: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.PATCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.CLEAR_FURNITURE:
      return {
        ...state,
        byId: {},
      };
    case types.SET_FURNITURE:
      return {
        ...state,
        byId: { ...state.byId, ...payload.byId },
      };
    case types.SET_FURNITURE_POST: {
      const { id } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...payload,
          },
        },
        isFetching: false,
        saveComplete: true,
      };
    }
    case types.DELETE_REQUEST_SUCCESS: {
      const { id } = payload;
      const curentByid = { ...state.byId };
      delete curentByid[id];

      return {
        ...state,
        byId: curentByid,
        isFetching: false,
      };
    }
    case types.SET_DELETE_COMPLETE:
      return {
        ...state,
        deleteComplete: payload,
        isFetching: false,
      };
    case types.SET_SELECTED_FURNITURE:
      return {
        ...state,
        selectedFurniture: payload,
      };
    case types.SET_COPIED_MODEL:
      return {
        ...state,
        copiedModel: payload,
      };
    case types.SET_COPIED_CEILING_STATE:
      return {
        ...state,
        copiedCeilingState: payload,
      };
    case types.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: payload,
      };
    case types.SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: payload,
      };
    case types.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case types.SET_SELECTED_SUB_CATEGORY:
      return {
        ...state,
        selectedSubCategory: payload,
      };
    case types.SET_SHOW_DIMENSIONS:
      return {
        ...state,
        showDimensions: payload,
      };
    case types.SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: payload,
      };
    case types.SET_SHOW_FURNITURE_DETAILS:
      return {
        ...state,
        showFurnitureDetails: payload,
      };
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: payload,
      };
    case types.ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    case types.REMOVE_DELETED_FROM_SYNC: {
      const curentByid = { ...state.byId };

      payload.allIds.forEach((id) => {
        delete curentByid[id];
      });

      return {
        ...state,
        byId: curentByid,
      };
    }
    case types.SET_SAVE_COMPLETE:
      return {
        ...state,
        saveComplete: payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
