import { get } from 'lodash';
import { createSelector } from 'reselect';

const root = (state) => get(state, 'furniture');

export const getById = (state) => get(root(state), 'byId', false);
export const getSelectedType = (state) =>
  get(root(state), 'selectedType', false);
export const getSelectedCategory = (state) =>
  get(root(state), 'selectedCategory', false);
export const getSelectedSubCategory = (state) =>
  get(root(state), 'selectedSubCategory', false);
export const getSelectedFurniture = (state) =>
  get(root(state), 'selectedFurniture', false);
export const getShowDimensions = (state) =>
  get(root(state), 'showDimensions', false);
export const getSearchVal = (state) => get(root(state), 'searchValue', false);
export const getLastUpdate = (state) => get(root(state), 'lastUpdate', false);
export const getShowFurnitureDetails = (state) =>
  get(root(state), 'showFurnitureDetails', false);
export const getLoading = (state) => get(root(state), 'isFetching', false);
export const getError = (state) => get(root(state), 'error', false);
export const getSaveComplete = (state) =>
  get(root(state), 'saveComplete', false);
export const getDeleteComplete = (state) =>
  get(root(state), 'deleteComplete', false);
export const getCopiedModel = (state) => get(root(state), 'copiedModel', false);
export const getCopiedCeilingState = (state) =>
  get(root(state), 'copiedCeilingState', false);

const sort = (furniture) => {
  return Object.values(furniture).sort((a, b) => {
    const textA = a.name ? a.name.toUpperCase() : a.label ? a.label : '';
    const textB = b.name ? b.name.toUpperCase() : b.label ? b.label : '';
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const getFurniture = createSelector(
  [
    getById,
    getSelectedType,
    getSelectedCategory,
    getSelectedSubCategory,
    getSearchVal,
  ],
  (byId, selectedType, category, subCategory, searchVal) => {
    if (!searchVal || searchVal.length < 3) {
      const retFurn = Object.values(byId).filter((furniture) => {
        return (
          furniture.furnitureType?.includes(selectedType) &&
          furniture.category?.includes(category) &&
          furniture.subCategory?.includes(subCategory)
        );
      });

      return sort(retFurn);
    }

    if (searchVal.length >= 3) {
      let altSearchSku = searchVal.trim();
      if (altSearchSku.indexOf(' ') < 1) {
        altSearchSku = altSearchSku.replace(/(\d+)/g, function (_, num) {
          return ' ' + num + ' ';
        });
      }
      const searchSku1 = altSearchSku.trim().toUpperCase();
      const searchSku2 = altSearchSku.trim().replace(' ', '').toUpperCase();

      const retFurn = Object.values(byId).filter((furniture) => {
        return (
          (furniture.name &&
            furniture.name
              .toUpperCase()
              .includes(searchVal.trim().toUpperCase())) ||
          (furniture.label &&
            furniture.label
              .toUpperCase()
              .includes(searchVal.trim().toUpperCase())) ||
          furniture.skus?.find((s) =>
            s.skuNum.toUpperCase().includes(searchSku1),
          ) ||
          furniture.skus?.find((s) =>
            s.skuNum.toUpperCase().includes(searchSku2),
          )
        );
      });

      return sort(retFurn);
    }
  },
);
