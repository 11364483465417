import React, { useContext } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import localforage from 'localforage';

import ButtonLoader from '../../components/ButtonLoader';
import Modal from '../../components/Modal';
import * as MapsSelector from '../../selectors/maps';
import { actions as sidebarActions } from '../../reducers/sidebar';
import { actions as furnitureActions } from '../../reducers/furniture';

import { APIContext } from '../../context/APIContext';
import {
  IMPORT_OPTIONS,
  ASSET_STORE_NAME,
  MAX_POLOAR_ANGLE,
  TOOLBAR_POS,
} from '../../constants';

const ConfirmExitModal = ({
  setShowIntro,
  showModal,
  setSelectionUiPos,
  setSelectedView,
  setCameraState,
  setSelectedRotIndex,
}) => {
  const apiContext = useContext(APIContext);
  const dispatch = useDispatch();
  const { api } = apiContext;

  const ConfirmExitContent = () => (
    <div style={{ whiteSpace: 'pre-line' }}>
      {`Are you sure you want to exit?\nAny unsaved changes will be lost.`}
    </div>
  );

  const ConfirmExitButtons = () => {
    const loading = useSelector(MapsSelector.getLoading);

    const exit = async () => {
      api.clearScene();
      setSelectionUiPos(TOOLBAR_POS);
      setSelectedView('orbit');
      setCameraState({});
      setSelectedRotIndex(2);

      const assetStore = localforage.createInstance({
        name: ASSET_STORE_NAME,
      });

      assetStore.getItem('glb-SHOWROOM').then((value) => {
        const url = URL.createObjectURL(value);
        api.loadModel(url, IMPORT_OPTIONS).then((object) => {
          object.userData.isMap = true;
          object.userData.skuKey = 'SHOWROOM';
          api.addObject(object);
          const maxDistance = api.sceneSphere.radius * 2;
          const radius = api.sceneSphere.radius;
          const target = api.sceneCentre;
          const position = { x: radius, y: radius, z: radius };
          const up = { x: 0, y: 1, z: 0 };

          api.transition(
            {
              type: 'orbit',
              position: position,
              up: up,
              target: target,
              maxDistance: maxDistance,
              maxPolarAngle: MAX_POLOAR_ANGLE,
            },
            1,
          );
        });
      });

      assetStore.getItem('glb-SHOWROOM_ROOF').then((value) => {
        const url = URL.createObjectURL(value);
        api.loadModel(url, IMPORT_OPTIONS).then((object) => {
          object.userData.isMap = true;
          object.userData.skuKey = 'SHOWROOM_ROOF';
          api.addObject(object);
          let ceilingObject = api.getObjectByNameOrId('ceilings');
          if (ceilingObject) {
            ceilingObject.traverse((obj) => {
              obj.visible = false;
            });
          }
        });
      });

      showModal(false);
      setShowIntro(true);

      batch(() => {
        dispatch(sidebarActions.setMenuOpened(false));
        dispatch(sidebarActions.setOpenedFromMap(false));
        dispatch(furnitureActions.setSelectedCategory(''));
        dispatch(furnitureActions.setSelectedType(''));
        dispatch(furnitureActions.setSelectedSubCategory(''));
        dispatch(furnitureActions.setSelectedFurniture(''));
        dispatch(furnitureActions.setShowFurnitureDetails(false));
        dispatch(furnitureActions.setSearchValue(''));
      });
    };

    return (
      <div className="frm__generic">
        <div className="buttonRow">
          <ButtonLoader
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            text="Exit"
            onClick={exit}
            loading={loading}
          />
          <button
            className="btn btnSubmitModal btnNoMargin"
            type="submit"
            onClick={() => showModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      content={<ConfirmExitContent />}
      buttons={<ConfirmExitButtons />}
      overlayStyles="confirmoverwrite"
      wrapperStyles="confirmoverwrite"
      buttonWrapperStyles="confirmoverwrite"
    />
  );
};

ConfirmExitModal.propTypes = {
  setShowIntro: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setSelectionUiPos: PropTypes.func.isRequired,
  setSelectedView: PropTypes.func.isRequired,
  setCameraState: PropTypes.func.isRequired,
  setSelectedRotIndex: PropTypes.func.isRequired,
};
export default ConfirmExitModal;
