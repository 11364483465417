import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const ButtonLoader = (props) => {
  const { className, type, onClick, text, loading } = props;

  return (
    <button
      className={className}
      type={type}
      onClick={onClick}
      disabled={loading}
    >
      {loading && <CircularProgress size={14} />}
      {!loading && text}
    </button>
  );
};

ButtonLoader.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ButtonLoader;
