import React, { createContext, useState } from 'react';

export const APIContext = createContext({});

export const APIProvider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;
  const [api, setApi] = useState(null);

  // Make the context object:
  const apiContext = {
    api,
    setApi,
  };

  // pass the value in provider and return
  return (
    <APIContext.Provider value={apiContext}>{children}</APIContext.Provider>
  );
};

export const { APIConsumer } = APIContext;
