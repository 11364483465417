import { get } from 'lodash';
import { createSelector } from 'reselect';

const root = (state) => get(state, 'furnitureTypes');

export const getAllIds = (state) => get(root(state), 'allIds', false);
export const getById = (state) => get(root(state), 'byId', false);

const addIfExists = (furnTypes, buildingOut, value) => {
  const furnType = Object.values(furnTypes).find(
    (f) => f.name.toUpperCase() === value,
  );
  if (furnType) {
    buildingOut.push(furnType);
  }
  return buildingOut;
};

export const getTypesSorted = createSelector([getById], (byId) => {
  let returnArr = [];
  // Add Categoreis in order from website
  returnArr = addIfExists(byId, returnArr, 'LIVING');
  returnArr = addIfExists(byId, returnArr, 'DINING');
  returnArr = addIfExists(byId, returnArr, 'BED');
  returnArr = addIfExists(byId, returnArr, 'BATH');
  returnArr = addIfExists(byId, returnArr, 'LIGHTING');
  returnArr = addIfExists(byId, returnArr, 'TEXTILES');
  returnArr = addIfExists(byId, returnArr, 'RUGS');
  returnArr = addIfExists(byId, returnArr, 'WINDOWS');
  returnArr = addIfExists(byId, returnArr, 'HARDWARE');
  returnArr = addIfExists(byId, returnArr, 'DECOR');
  returnArr = addIfExists(byId, returnArr, 'ART');
  returnArr = addIfExists(byId, returnArr, 'GIFTS');
  returnArr = addIfExists(byId, returnArr, 'OUTDOOR');
  returnArr = addIfExists(byId, returnArr, 'SALE');

  // Add any other categories in DB to bottom of array
  Object.values(byId).forEach((furnType) => {
    const foundType = returnArr.find(
      (f) => f.name.toUpperCase() === furnType.name.toUpperCase(),
    );
    if (!foundType) {
      returnArr.push(furnType);
    }
  });

  return returnArr;
});
