import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Vector3 } from 'three';
import localforage from 'localforage';
import { isMobile, isIOS } from 'react-device-detect';
import { useToasts } from 'react-toast-notifications';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useLocation } from 'react-router-dom';

import {
  EVOLUTIONAPI,
  MASKS,
  createCircleMesh,
} from '@evolutionv/room-planner-3d';

import * as Stats from 'stats.js';
import { APIContext } from '../../context/APIContext';
import * as UserSelector from '../../selectors/users';
import * as FurnitureSelectors from '../../selectors/furniture';
import ViewChanger from '../ViewChanger';
import MapToolbar from '../MapToolbar';
import ModelToolbar from '../ModelToolbar';
import DPad from '../DPad';
import MassModelLoader from '../MassModelLoader';
import { actions as furnitureActions } from '../../reducers/furniture';
import { actions as sidebarActions } from '../../reducers/sidebar';
import { actions as skuActions } from '../../reducers/sku';
import { useGetDimensions } from '../../hooks/useGetDimensions';
import px from '../../assets/textures/cube/px.hdr';
import nx from '../../assets/textures/cube/nx.hdr';
import py from '../../assets/textures/cube/py.hdr';
import ny from '../../assets/textures/cube/ny.hdr';
import pz from '../../assets/textures/cube/pz.hdr';
import nz from '../../assets/textures/cube/nz.hdr';

import {
  TRANSLATE_MODE,
  TOOLBAR_POS,
  NEW_PASSWORD_REQUIRED,
  ROOM_NAMES,
  IMPORT_OPTIONS,
  ASSET_STORE_NAME,
  DEFAULT_FOV,
  FPC_HEIGHT_OFFSET,
  MAX_POLOAR_ANGLE,
  MODEL_TOOLBAR_HEIGHT,
  MODEL_TOOLBAR_WIDTH,
  PAGE_HOME,
} from '../../constants';

const RoomDesigner = ({
  selectedFurnitureRef,
  selectionUiPos,
  setSelectionUiPos,
  furnitureSize,
  setFurnitureSize,
  selectedView,
  setSelectedView,
  sharedCameraState,
  setCameraState,
  selectedRotIndex,
  setSelectedRotIndex,
  setIsShortCeiling,
  isShortCeiling,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToasts();
  const canvasRef = useRef(null);
  const statsRef = useRef(null);
  const containerRef = useRef(null);
  const transformActiveRef = useRef(false);
  const localApiRef = useRef(null);
  const mouseDownTime = useRef(null);
  const apiContext = useContext(APIContext);
  const { api, setApi } = apiContext;
  const [isLongPress, setIsLongPress] = useState(false);
  const [pressComplete, setPresComplete] = useState(false);
  const [translateMode, setTranslateMode] = useState(TRANSLATE_MODE);
  const [leftDimensionUIPos, setLeftDimensionUIPo] = useState(TOOLBAR_POS);
  const [topDimensionUIPos, setTopDimensionUIPo] = useState(TOOLBAR_POS);
  const [pasteButtonPos, setPasteButtonPos] = useState(TOOLBAR_POS);
  const [roomNamesEnabled, setRoomNamesEnabled] = useState(false);
  const [fovVal, setFovVal] = useState(DEFAULT_FOV);
  const [isTransition, setIsTransition] = useState(false);
  const [attachedLabel, setAttachedLabel] = useState(null);
  const [righClickPos, setRighClickPos] = useState(null);
  const currentUser = useSelector(UserSelector.getUser);
  const showDimension = useSelector(FurnitureSelectors.getShowDimensions);
  const copiedModel = useSelector(FurnitureSelectors.getCopiedModel);
  const copiedCeilingState = useSelector(
    FurnitureSelectors.getCopiedCeilingState,
  );
  const furniture = useSelector(FurnitureSelectors.getById);
  const currSearchVal = useSelector(FurnitureSelectors.getSearchVal);
  const currPointerPos = useRef(null);
  const mouseDownTimeOut = useRef(null);
  const touchDownTimeOut = useRef([]);
  const lastTapTime = useRef(null);
  const isRightClick = useRef(false);
  const markerMesh = useRef(null);
  const copiedModelRef = useRef(null);
  const copiedCeilingStateRef = useRef(null);
  const isShortCeilingRef = useRef(null);
  const furnitureRef = useRef(null);
  const { getDimension } = useGetDimensions();

  const showDebugFeatures =
    process.env.REACT_APP_SHOW_DEBUG_FEATURES.toUpperCase() === 'TRUE';

  const ToolbarTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: 10,
      fontFamily: 'Proxima Nova',
      fontWeight: '500',
      letterSpacing: '1px',
      lineHeight: '12px',
      marginLeft: 3,
    },
  }))(Tooltip);

  const isAllowedDrop = useCallback((intersections, allowedPlacements) => {
    if (
      intersections.length === 0 ||
      localApiRef.current.renderParams.mode === '2d'
    ) {
      return false;
    }

    // Check if it hits the walls, floor (piso), or ceiling
    const floorTestPass = !intersections[0].object.name
      .toLowerCase()
      .includes('piso');
    const wallTestPass = !intersections[0].object.name
      .toLowerCase()
      .includes('wall');
    const ceilingTestPass = !intersections[0].object.name
      .toLowerCase()
      .includes('ceiling');

    if (wallTestPass && floorTestPass && ceilingTestPass) {
      return false;
    }

    if (
      !floorTestPass &&
      !allowedPlacements.find((placement) => placement === 'FLOOR')
    ) {
      // NOT A FLOOR ITEM
      return false;
    }

    if (
      !wallTestPass &&
      !allowedPlacements.find((placement) => placement === 'WALL')
    ) {
      // NOT A WALL ITEM
      return false;
    }

    if (
      !ceilingTestPass &&
      !allowedPlacements.find((placement) => placement === 'CEILING')
    ) {
      // NOT A CEILING ITEM
      return false;
    }

    if (localApiRef.current.isAnnotating()) {
      return false;
    }

    return true;
  }, []);

  const [, drop] = useDrop({
    accept: 'FURNITURE',
    drop: (item, monitor) => onDrop(item, monitor),
    hover: (item, monitor) => {
      const allowedPlacements = item.allowedPlacements;
      const coords = monitor.getClientOffset();
      const pointerPosition = convertPointerPos(coords);
      const cameraState = api.captureCameraState().cameraState;
      let mask = MASKS.MASK_WALL | MASKS.MASK_FLOOR;

      if (cameraState.type === 'fpc') {
        mask |= MASKS.MASK_CEILING;
      }

      const intersections = api.rayCastScreen(pointerPosition, mask);
      const canDrop = isAllowedDrop(intersections, allowedPlacements);
      item.canDrop = canDrop;
      item.coords = coords;
    },
  });

  const getPointerPosition = useCallback((event) => {
    const pointerPos = {
      x: 0,
      y: 0,
    };

    if (
      event.nativeEvent instanceof TouchEvent &&
      event.changedTouches?.length > 0
    ) {
      pointerPos.x = event.changedTouches[0].pageX;
      pointerPos.y = event.changedTouches[0].pageY;
    } else if (event.nativeEvent instanceof MouseEvent) {
      pointerPos.x = event.nativeEvent.clientX;
      pointerPos.y = event.nativeEvent.clientY;
    }

    return convertPointerPos(pointerPos);
  }, []);

  const convertPointerPos = (coordinates) => {
    const canvas = canvasRef.current;
    const canvasBounds = canvas.getBoundingClientRect();
    const pointerPos = {
      x: 0,
      y: 0,
    };
    pointerPos.x =
      ((coordinates.x - canvasBounds.left) /
        (canvasBounds.right - canvasBounds.left)) *
        2 -
      1;
    pointerPos.y =
      -(
        (coordinates.y - canvasBounds.top) /
        (canvasBounds.bottom - canvasBounds.top)
      ) *
        2 +
      1;

    return pointerPos;
  };

  const detectShortCeiling = useCallback(
    (ceilingHiehgt) => {
      if (ceilingHiehgt < 4) {
        setIsShortCeiling(true);
        return true;
      } else {
        setIsShortCeiling(false);
        return false;
      }
    },
    [setIsShortCeiling],
  );

  const handleResize = useCallback(() => {
    const container = containerRef.current;
    setSelectionUiPos(TOOLBAR_POS);
    if (localApiRef && container) {
      localApiRef.current.setSize(
        container.clientWidth,
        container.clientHeight,
      );

      localApiRef.current.selectObject(null, {});
    }
  }, [setSelectionUiPos]);

  const getModelToolBarCoords = useCallback((apiRef, furnitureRef, isLabel) => {
    if (apiRef && furnitureRef) {
      const rootObject = isLabel
        ? furnitureRef
        : apiRef.getRootObject(furnitureRef);
      if (rootObject) {
        let p2d = apiRef.getObjectBoundaryPoint(rootObject, 'right');

        const canvasBounds = canvasRef.current.getBoundingClientRect();

        // Model Toolbar Will be Off Bottom
        if (p2d.y + MODEL_TOOLBAR_HEIGHT >= canvasBounds.bottom) {
          p2d.y = canvasBounds.bottom - MODEL_TOOLBAR_HEIGHT - 10;
        }
        // Model Toolbar Will be Off Right Side of Screen
        if (p2d.x + MODEL_TOOLBAR_WIDTH + 50 >= canvasBounds.right) {
          p2d.x = canvasBounds.right - MODEL_TOOLBAR_WIDTH - 60;
        }
        // Model Toolbar Will be off Top of the canvas
        if (p2d.y < canvasBounds.top) {
          p2d.y = canvasBounds.top;
        }

        return {
          top: p2d.y,
          left: p2d.x,
        };
      }
    }
  }, []);

  const apiTransferEvent = useCallback(() => {
    if (transformActiveRef.current) {
      if (attachedLabel || selectedFurnitureRef.current) {
        setSelectionUiPos(
          getModelToolBarCoords(
            localApiRef.current,
            attachedLabel ? attachedLabel : selectedFurnitureRef.current,
            attachedLabel !== null,
          ),
        );
        setPasteButtonPos(TOOLBAR_POS);
        clearTouchTimeouts();
      }
    }

    transformActiveRef.current = !transformActiveRef.current;
  }, [
    setSelectionUiPos,
    selectedFurnitureRef,
    getModelToolBarCoords,
    attachedLabel,
  ]);

  const copyModel = useCallback(() => {
    if (
      localApiRef.current.selectedObject &&
      containerRef.current?.style?.visibility === 'visible'
    ) {
      const selectObject = localApiRef.current.selectedObject;
      const rootObject = localApiRef.current.getRootObject(selectObject);
      if (rootObject) {
        const copiedData = {
          constraint: rootObject.constraint,
          rotation: rootObject.rotation,
          userData: rootObject.userData,
        };

        batch(() => {
          dispatch(furnitureActions.setCopiedModel(copiedData));
          dispatch(
            furnitureActions.setCopiedCeilingState(isShortCeilingRef.current),
          );
        });

        addToast('Copied to Clipboard', {
          appearance: 'success',
        });
      }
    }
  }, [dispatch, addToast]);

  const pasteModel = useCallback(async () => {
    let mask = MASKS.MASK_WALL | MASKS.MASK_FLOOR;

    if (!canvasRef.current) {
      console.log('no canvas');
      return;
    }

    if (!copiedModelRef.current?.userData?.furnitureId) {
      console.log('no copied');

      addToast('You have not copied anything to your clipboard', {
        appearance: 'error',
      });
      return;
    }

    if (!furnitureRef.current) {
      console.log('no furn arr');
      return;
    }

    const thisFurniture =
      furnitureRef.current[copiedModelRef.current.userData.furnitureId];

    if (!thisFurniture) {
      console.log('no furn');
      return;
    }

    let skuToPaste = thisFurniture.skus.find(
      (s) => s.keyVal === copiedModelRef.current.userData.skuKey,
    );

    if (!skuToPaste) {
      console.log('no sku');
      return;
    }

    const camera = localApiRef.current.captureCameraState();

    if (camera.cameraState.type === 'fpc') {
      mask |= MASKS.MASK_CEILING;
    }

    let intersections = [];
    if (righClickPos) {
      intersections = localApiRef.current.rayCastScreen(righClickPos, mask);
      setRighClickPos(null);
    } else {
      intersections = localApiRef.current.rayCastScreen(
        currPointerPos.current,
        mask,
      );
    }
    setPasteButtonPos(TOOLBAR_POS);
    clearTouchTimeouts();
    const allowedPlacements = thisFurniture.placementType;
    if (!isAllowedDrop(intersections, allowedPlacements)) {
      console.log('cannot paste there');
      addToast('Cannot paste object there', {
        appearance: 'error',
      });
      return;
    }

    const pastedShortCeiling = detectShortCeiling(
      localApiRef.current.ceilingHeightAtPoint(intersections[0].point),
    );

    const assetStore = localforage.createInstance({
      name: ASSET_STORE_NAME,
    });

    if (allowedPlacements.includes('CEILING')) {
      const eightyOption = skuToPaste.options.find(
        (opt) => opt.value === '80"',
      );
      const sixtyOption = skuToPaste.options.find((opt) => opt.value === '60"');
      const oneHundredOption = skuToPaste.options.find(
        (opt) => opt.value === '100"',
      );

      if (copiedCeilingStateRef.current && !pastedShortCeiling) {
        if (oneHundredOption) {
          skuToPaste = thisFurniture.skus.find((s) => {
            return s.options.find((opt) => opt.value === '80"');
          });
        } else if (eightyOption) {
          skuToPaste = thisFurniture.skus.find((s) => {
            return s.options.find((opt) => opt.value === '60"');
          });
        }
      } else if (!copiedCeilingStateRef.current && pastedShortCeiling) {
        if (sixtyOption) {
          skuToPaste = thisFurniture.skus.find((s) => {
            return s.options.find((opt) => opt.value === '80"');
          });
        } else if (eightyOption) {
          skuToPaste = thisFurniture.skus.find((s) => {
            return s.options.find((opt) => opt.value === '100"');
          });
          console.log(skuToPaste);
        }
      }

      if (
        (pastedShortCeiling && sixtyOption) ||
        (!pastedShortCeiling && oneHundredOption)
      ) {
        skuToPaste = thisFurniture.skus.find((s) => {
          return s.options.find((opt) => opt.value === '80"');
        });
      }
    }

    if (!skuToPaste) {
      console.log('no sku becaus of chand');
      return;
    }

    const searchGlbKey = copiedModelRef.current.userData.isCube
      ? `BLANK_BLOB_FLOOR`
      : `glb-${skuToPaste.keyVal}`;

    const item = await assetStore.getItem(searchGlbKey);
    const url = URL.createObjectURL(item);
    let pngUrl = copiedModelRef.current.userData.map2dURL;

    const png = await assetStore.getItem(
      `png-${copiedModelRef.current.userData.skuKey}`,
    );

    if (png) {
      pngUrl = URL.createObjectURL(png);
    }

    localApiRef.current.loadModel(url, IMPORT_OPTIONS).then((object) => {
      object.position.copy(intersections[0].point);
      object.constraint = copiedModelRef.current.constraint;
      object.rotation.copy(copiedModelRef.current.rotation);
      object.userData = {
        ...object.userData,
        ...copiedModelRef.current.userData,
        map2dURL: pngUrl,
        skuKey: skuToPaste.keyVal,
        twoDLabel: skuToPaste.twoDLabel,
      };

      const newRootObject = localApiRef.current.getRootObject(object);
      const p2d = localApiRef.current.getObjectBoundaryPoint(
        newRootObject,
        'right',
      );
      const selectionParams = {
        transformControlParams: {
          mode: TRANSLATE_MODE,
          constraint: object.constraint,
        },
      };

      if (copiedModelRef.current.userData.isCube) {
        const skuSize = skuToPaste.options.find((o) => o.type === 'SIZE');
        if (skuSize) {
          // Parse the string into vector3
          const splitSizes = skuSize.value
            .split(' x ')
            .map((dim) => dim.slice(0, -1)); // Chop off the 'W', 'H', 'L' characters

          if (splitSizes.length === 3)
            object.scale.set(splitSizes[1], splitSizes[2], splitSizes[0]);
        }
      }

      // add new model
      localApiRef.current.addObject(object);
      // remove old model
      // select new model
      localApiRef.current.selectObject(object, selectionParams);
      setSelectionUiPos({
        top: p2d.y,
        left: p2d.x,
      });
      selectedFurnitureRef.current = localApiRef.current.getRootObject(object);
      setTranslateMode(TRANSLATE_MODE);
      batch(() => {
        dispatch(sidebarActions.setMenuOpened(true));
        dispatch(
          furnitureActions.setSelectedFurniture(object.userData.furnitureId),
        );
        dispatch(skuActions.setSkuOptions(skuToPaste.options));
        dispatch(furnitureActions.setShowFurnitureDetails(true));
        dispatch(sidebarActions.setOpenedFromMap(true));
      });
    });
  }, [
    addToast,
    currPointerPos,
    dispatch,
    isAllowedDrop,
    righClickPos,
    selectedFurnitureRef,
    setSelectionUiPos,
    detectShortCeiling,
  ]);

  const onKeyDown = useCallback(
    (event) => {
      if (localApiRef.current) {
        const key = event.which || event.keyCode;
        const ctrl = event.ctrlKey
          ? event.ctrlKey
          : event.metaKey
          ? event.metaKey
          : key === 17
          ? true
          : false; // ctrl detection

        if (key === 86 && ctrl) {
          console.log('PASTE KEY COMBO');
          pasteModel();
        } else if (key === 67 && ctrl) {
          copyModel();
        }
      }
    },
    [copyModel, pasteModel],
  );

  const onZoom = useCallback(() => {
    if (selectedFurnitureRef.current) {
      setSelectionUiPos(
        getModelToolBarCoords(
          localApiRef.current,
          selectedFurnitureRef.current,
        ),
      );
    }
    setLeftDimensionUIPo(TOOLBAR_POS);
    setTopDimensionUIPo(TOOLBAR_POS);
    setPasteButtonPos(TOOLBAR_POS);
    clearTouchTimeouts();

    ROOM_NAMES.map(async (roomName) => {
      const roomDiv = document.getElementById(roomName);
      const roomObject = localApiRef.current.getObjectByNameOrId(roomName);

      if (roomDiv?.style.display === 'block' && roomObject) {
        const p2d = localApiRef.current.getObjectBoundaryPoint(
          roomObject,
          // NOTE(Jesse): This is erroniously the second arg, when it should be
          // the third.  Not sure if this bug is behavior we're relying on or
          // not, so I'm leaving it in here for now.
          //
          // @buggy-leftSide-top-value
          'top',
        );

        roomDiv.style.left = `${p2d.x - 130}px`;
        roomDiv.style.top = `${p2d.y + 70}px`;
      }
    });
  }, [
    localApiRef,
    setSelectionUiPos,
    selectedFurnitureRef,
    getModelToolBarCoords,
  ]);

  const ceilingVisibility = useCallback((bEnable, api) => {
    let ceilingObject = api.getObjectByNameOrId('ceilings');
    if (ceilingObject) {
      ceilingObject.traverse((obj) => {
        obj.visible = bEnable;
      });
    }
  }, []);

  const onDoubleClick = useCallback(
    (event) => {
      clearTouchTimeouts();
      const state = api.captureCameraState();
      if (selectedView === '2D') {
        return;
      }

      if (state.cameraState.type === 'fpc' || isTransition) {
        return;
      }

      const position = getPointerPosition(event);
      const mask = MASKS.MASK_FLOOR | MASKS.MASK_WALL;
      const intersections = api.rayCastScreen(position, mask);
      if (intersections.length > 0) {
        setSelectedView('fpc');
        setIsTransition(true);

        const position = intersections[0].point;
        let target_ = state.cameraState.target;
        let position_ = state.cameraState.position;

        api.renderParams = { mode: '3d' };
        api.setCameraMode('perspective');

        if (target_ && position_) {
          target_.sub(position_);
          target_.normalize();
        }

        if (state.cameraState.type === '3d') {
          target_.set(0, 0, 1);
        }
        const eyeHeight = api.sceneSize.y * FPC_HEIGHT_OFFSET;
        position.y = eyeHeight;
        const finishCallback = () => {
          ceilingVisibility(true, api);
          setIsTransition(false);
        };

        setPasteButtonPos(TOOLBAR_POS);

        api.transition(
          {
            type: 'fpc',
            position: position,
            up: { x: 0, y: 1, z: 0 },
            eyeHeight: eyeHeight,
            target: {
              x: position.x + target_.x,
              y: position.y,
              z: position.z + target_.z,
            },
          },
          1.5,
          finishCallback,
        );
      }
    },
    [
      api,
      getPointerPosition,
      ceilingVisibility,
      isTransition,
      selectedView,
      setSelectedView,
    ],
  );

  const LoadDefaultScene = useCallback(
    async (api) => {
      const assetStore = localforage.createInstance({
        name: ASSET_STORE_NAME,
      });
      const baseUrl = process.env.REACT_APP_ASSET_BASE_URL
        ? process.env.REACT_APP_ASSET_BASE_URL
        : '';

      const currShowRoom = await assetStore.getItem('glb-SHOWROOM');
      const currCeiling = await assetStore.getItem('glb-SHOWROOM_ROOF');
      let showRoomurl = '';
      let ceilingUrl = '';

      if (currShowRoom) {
        showRoomurl = URL.createObjectURL(currShowRoom);
      } else {
        const showRoomGlb = await fetch(`${baseUrl}/data/room/Showroom.glb`);
        const showRoomBlob = await showRoomGlb.blob();
        showRoomurl = URL.createObjectURL(showRoomBlob);
      }

      if (currCeiling) {
        ceilingUrl = URL.createObjectURL(currCeiling);
      } else {
        const showRoomGlb = await fetch(
          `${baseUrl}/data/room/Showroom-Roof.glb`,
        );
        const showRoomBlob = await showRoomGlb.blob();
        ceilingUrl = URL.createObjectURL(showRoomBlob);
      }

      api.loadModel(showRoomurl, IMPORT_OPTIONS).then((object) => {
        object.userData.isMap = true;
        object.userData.skuKey = 'SHOWROOM';
        api.addObject(object);
        const maxDistance = api.sceneSphere.radius * 2;
        const radius = api.sceneSphere.radius;
        const target = api.sceneCentre;
        const position = { x: radius, y: radius, z: radius };
        const up = { x: 0, y: 1, z: 0 };

        api.transition(
          {
            type: 'orbit',
            position: position,
            up: up,
            target: target,
            maxDistance: maxDistance,
            maxPolarAngle: MAX_POLOAR_ANGLE,
          },
          1,
        );

        api.lightParams = { enabled: true, castShadow: true };
        api.miniMapParams = { enable: true };
      });

      api.loadModel(ceilingUrl, IMPORT_OPTIONS).then((object) => {
        object.userData.isMap = true;
        object.userData.skuKey = 'SHOWROOM_ROOF';
        api.addObject(object);

        ceilingVisibility(false, api);
      });
    },
    [ceilingVisibility],
  );

  useEffect(() => {
    furnitureRef.current = furniture;
  }, [furniture]);

  useEffect(() => {
    copiedModelRef.current = copiedModel;
    copiedCeilingStateRef.current = copiedCeilingState;
  }, [copiedModel, copiedCeilingState]);

  useEffect(() => {
    isShortCeilingRef.current = isShortCeiling;
  }, [isShortCeiling]);

  useEffect(() => {
    if (
      (location.pathname !== PAGE_HOME && containerRef.current) ||
      (!currentUser?.loggedIn && containerRef.current)
    ) {
      containerRef.current.style.visibility = 'hidden';
    } else if (containerRef.current) {
      containerRef.current.style.visibility = 'visible';
    }
  }, [currentUser, location.pathname]);

  if (location.pathname !== PAGE_HOME && containerRef.current) {
    containerRef.current.style.visibility = 'hidden';
  } else if (containerRef.current) {
    containerRef.current.style.visibility = 'visible';
  }

  useEffect(() => {
    const canvas = canvasRef.current;

    if (
      canvas &&
      currentUser?.loggedIn &&
      currentUser?.userObject?.challengeName !== NEW_PASSWORD_REQUIRED &&
      !api
    ) {
      const hdrUrls = [px, nx, py, ny, pz, nz];
      const newApi = new EVOLUTIONAPI(canvas, hdrUrls);
      newApi.play();

      if (process.env.REACT_APP_SHOW_DEBUG_FEATURES.toUpperCase() === 'TRUE') {
        const stats = new Stats();
        stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
        statsRef.current.appendChild(stats.dom);
        const statElem = statsRef.current.firstChild;
        statElem.style.top = '770px';
        statElem.style.left = '70px';
        statElem.style.right = '0px';
        statElem.style.width = '80px';
        newApi.addEventListener('updateEvent', (event) => {
          stats.update();
        });
        // REMOVING GUI CAUSES BUGS
        // const ui = new EditorGUI(newApi, canvas);
        //console.log('temp ui =>', ui);
      }

      LoadDefaultScene(newApi);
      window.addEventListener('resize', handleResize);
      window.addEventListener('keydown', onKeyDown);
      canvas.addEventListener('wheel', onZoom);
      newApi.addEventListener('transformEvent', apiTransferEvent);
      setApi(newApi);
      localApiRef.current = newApi;
    } else if (!canvas) {
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('keydown', onKeyDown);
        canvas.removeEventListener('wheel', onZoom);
      };
    }
  }, [
    api,
    setApi,
    currentUser,
    apiTransferEvent,
    handleResize,
    onZoom,
    LoadDefaultScene,
    onKeyDown,
  ]);

  useEffect(() => {
    if (pressComplete && api) {
      if (isLongPress) {
        if (selectedFurnitureRef.current) {
          setSelectionUiPos(
            getModelToolBarCoords(api, selectedFurnitureRef.current),
          );
        } else if (attachedLabel) {
          setSelectionUiPos(getModelToolBarCoords(api, attachedLabel, true));
        }
        setIsLongPress(false);
      }
      setPresComplete(false);
    }
  }, [
    api,
    attachedLabel,
    isLongPress,
    pressComplete,
    setSelectionUiPos,
    selectedFurnitureRef,
    getModelToolBarCoords,
  ]);

  const createMarkerObject = () => {
    if (!markerMesh.current) {
      markerMesh.current = createCircleMesh(0.4);
      markerMesh.current.position.copy(api.sceneCentre);
      api.addObject(markerMesh.current);
      markerMesh.current.layers.mask = MASKS.MASK_NONE;
      markerMesh.current.visible = true;
    }
  };

  const onContextMenu = (event) => {
    event.stopPropagation();
  };

  const onPointerMove = (event) => {
    if (isMobile && !isIOS) {
      return;
    } else if (isIOS) {
      clearTouchTimeouts();
      return;
    }
    clearTouchTimeouts();
    const position = getPointerPosition(event);
    currPointerPos.current = position;

    if (api) {
      createMarkerObject();
      if (
        selectedFurnitureRef.current !== null ||
        api.renderParams.mode === '2d'
      ) {
        markerMesh.current.visible = false;
        api.needsUpdate();
        return;
      }

      const cameraState = api.captureCameraState().cameraState;

      let mask = MASKS.MASK_FLOOR | MASKS.MASK_WALL;

      if (cameraState.type === 'fpc') {
        mask = MASKS.MASK_FLOOR | MASKS.MASK_WALL | MASKS.MASK_CEILING;
      }

      const intersections = api.rayCastScreen(position, mask);

      if (intersections.length > 0) {
        markerMesh.current.visible = true;
        const normal = intersections[0].face?.normal;
        if (normal) {
          const target = new Vector3();
          const offset = new Vector3().copy(normal).multiplyScalar(0.03);
          offset.add(intersections[0].point);
          target.copy(intersections[0].point).add(normal);
          markerMesh.current.position.copy(offset);
          markerMesh.current.lookAt(target);

          const scale = Math.max(
            Math.min(intersections[0].distance / 10, 1),
            0.1,
          );
          markerMesh.current.scale.set(scale, scale, scale);
        }
      } else {
        markerMesh.current.visible = false;
      }
      api.needsUpdate();
    }
  };

  const onPointerDown = useCallback(
    (event) => {
      const camera = api.captureCameraState();
      let mask = MASKS.MASK_ALL & ~MASKS.MASK_CEILING;
      if (camera.cameraState.type === 'fpc') {
        mask = MASKS.MASK_ALL;
      }

      const pointerPosition = getPointerPosition(event);
      const pickedObject = api.pickObject(pointerPosition, mask);

      const rootObject = api.getRootObject(pickedObject);
      const params = api.objectSelectionParams;
      const mode =
        params && params.transformControlParams
          ? params.transformControlParams.mode
          : '';

      if (
        (selectedFurnitureRef.current === rootObject &&
          rootObject &&
          !rootObject.userData.isWallObject &&
          !rootObject.userData.isCeilingObject &&
          mode !== 'rotate') ||
        (pickedObject && pickedObject.userData && pickedObject.userData.isLabel)
      ) {
        const gizmo = api.sceneManager_.trcontrols_;
        gizmo.enabled = true;
        gizmo.axis = 'XZ';
        gizmo.mode = 'translate';
        document.addEventListener('pointermove', gizmo.onPointerMove, false);
        const pointer = gizmo.getPointer(event);
        gizmo.pointerDown(pointer);
      }

      mouseDownTime.current = Date.now();
      setRoomNamesEnabled(false);

      if (attachedLabel) {
        setIsLongPress(true);
      } else {
        setSelectionUiPos(TOOLBAR_POS);
      }
      clearTouchTimeouts();

      if (event.button === 2) {
        isRightClick.current = true;
      } else {
        isRightClick.current = false;
      }

      if (!transformActiveRef.current) {
        mouseDownTimeOut.current = setTimeout(() => {
          setIsLongPress(true);
        }, 200);
      }

      setLeftDimensionUIPo(TOOLBAR_POS);
      setTopDimensionUIPo(TOOLBAR_POS);
      setPasteButtonPos(TOOLBAR_POS);
    },
    [
      setSelectionUiPos,
      api,
      getPointerPosition,
      selectedFurnitureRef,
      attachedLabel,
    ],
  );

  const onPointerUp = useCallback(
    (event) => {
      const delta = Date.now() - mouseDownTime.current;

      if (isMobile) {
        const now = new Date().getTime();
        const timesince = now - lastTapTime.current;

        if (timesince < 500 && timesince > 100) {
          onDoubleClick(event);
        }

        lastTapTime.current = new Date().getTime();
      }

      clearTimeout(mouseDownTimeOut.current);

      if (
        delta > 200 ||
        api.measurementParams.enable ||
        api.renderParams.mode === '2d'
      ) {
        setPresComplete(true);
        return;
      }

      if (isRightClick.current) {
        setPasteButtonPos({
          position: 'absolute',
          left: event.nativeEvent.clientX,
          top: event.nativeEvent.clientY,
          cursor: 'pointer',
        });
        const rightClickPointerPos = getPointerPosition(event);
        setRighClickPos(rightClickPointerPos);
        return;
      }

      setPasteButtonPos(TOOLBAR_POS);
      clearTouchTimeouts();

      const pointerPosition = getPointerPosition(event);
      const camera = api.captureCameraState();

      let mask = MASKS.MASK_ALL & ~MASKS.MASK_CEILING;
      if (camera.cameraState.type === 'fpc') {
        mask = MASKS.MASK_ALL;
      }

      const pickedObject = api.pickObject(pointerPosition, mask);
      const rootObject = api.getRootObject(pickedObject);

      if (pickedObject && rootObject.constraint) {
        const furnitureId = rootObject.userData.furnitureId;
        const thisFurniture = furniture[furnitureId];
        const skuNum = rootObject.userData.sku;
        const skuKey = rootObject.userData.skuKey;
        const sku = thisFurniture.skus.find(
          (s) => s.skuNum === skuNum && s.keyVal === skuKey,
        );

        setSelectionUiPos(getModelToolBarCoords(api, pickedObject));
        setAttachedLabel(null);
        setTranslateMode(TRANSLATE_MODE);
        api.selectObject(pickedObject, {
          transformControlParams: {
            mode: TRANSLATE_MODE,
            constraint: rootObject.constraint,
          },
        });

        selectedFurnitureRef.current = rootObject;

        setLeftDimensionUIPo(TOOLBAR_POS);
        setTopDimensionUIPo(TOOLBAR_POS);
        setPasteButtonPos(TOOLBAR_POS);
        clearTouchTimeouts();

        const dimensions = getDimension(
          api,
          pickedObject,
          sku,
          thisFurniture.placementType,
        );
        setFurnitureSize(dimensions);

        const intersections = api.rayCastScreen(pointerPosition, mask);
        detectShortCeiling(api.ceilingHeightAtPoint(intersections[0].point));

        batch(() => {
          dispatch(sidebarActions.setMenuOpened(true));
          dispatch(furnitureActions.setSelectedFurniture(furnitureId));
          dispatch(skuActions.setSkuOptions(sku.options));
          dispatch(furnitureActions.setShowFurnitureDetails(true));
          dispatch(sidebarActions.setOpenedFromMap(true));
        });
      } else if (
        pickedObject &&
        pickedObject.userData &&
        pickedObject.userData.isLabel
      ) {
        setSelectionUiPos(getModelToolBarCoords(api, pickedObject, true));
        setTranslateMode(TRANSLATE_MODE);
        api.selectObject(pickedObject, {
          transformControlParams: {
            mode: TRANSLATE_MODE,
            constraint: 'y',
          },
        });

        setLeftDimensionUIPo(TOOLBAR_POS);
        setTopDimensionUIPo(TOOLBAR_POS);
        setPasteButtonPos(TOOLBAR_POS);
        clearTouchTimeouts();
        setAttachedLabel(pickedObject);

        batch(() => {
          dispatch(furnitureActions.setSelectedFurniture(''));
          dispatch(furnitureActions.setShowFurnitureDetails(false));
          dispatch(sidebarActions.setOpenedFromMap(false));
        });
      } else {
        setSelectionUiPos(TOOLBAR_POS);
        setAttachedLabel(null);

        api.selectObject(pickedObject, {});

        setLeftDimensionUIPo(TOOLBAR_POS);
        setTopDimensionUIPo(TOOLBAR_POS);
        setPasteButtonPos(TOOLBAR_POS);
        clearTouchTimeouts();

        selectedFurnitureRef.current = null;

        if (currSearchVal.length < 1) {
          dispatch(furnitureActions.setShowFurnitureDetails(false));
        }

        batch(() => {
          dispatch(furnitureActions.setSelectedFurniture(''));
          dispatch(sidebarActions.setOpenedFromMap(false));
        });
      }
      api.needsUpdate();
    },
    [
      api,
      dispatch,
      getPointerPosition,
      getDimension,
      onDoubleClick,
      selectedFurnitureRef,
      setSelectionUiPos,
      furniture,
      currSearchVal,
      getModelToolBarCoords,
      setFurnitureSize,
      detectShortCeiling,
    ],
  );

  const onDrop = useCallback(
    async (item, monitor) => {
      const furnitureId = item.id;
      const sku = item.sku;
      const allowedPlacements = item.allowedPlacements;
      const allowStacking = item.allowStacking;
      const isStackable = item.isStackable;
      const nonPrintable = item.nonPrintable;
      const skuOptions = sku.options;
      let pngUrl = sku.png;
      let glbUrl = sku.file;
      const coords = monitor.getClientOffset();
      const pointerPosition = convertPointerPos(coords);
      const camera = api.captureCameraState();
      let mask = MASKS.MASK_WALL | MASKS.MASK_FLOOR;
      let customFurnitureScale = false;
      const assetStore = localforage.createInstance({
        name: ASSET_STORE_NAME,
      });

      const twoDLabel = sku.twoDLabel || sku.label;
      const twoDLabelColor = item.twoDLabelColor;
      console.log('twoDLabelColor', twoDLabelColor);
      let glbBlob = null;

      if (!glbUrl || glbUrl.length < 1) {
        glbBlob = await assetStore.getItem('BLANK_BLOB_FLOOR');
        customFurnitureScale = true;
      } else {
        glbBlob = await assetStore.getItem(`glb-${sku.keyVal}`);
      }

      if (glbBlob) glbUrl = URL.createObjectURL(glbBlob);

      const pngBlob = await assetStore.getItem(`png-${sku.keyVal}`);
      if (pngBlob) pngUrl = URL.createObjectURL(pngBlob);

      setRoomNamesEnabled(false);

      if (camera.cameraState.type === 'fpc') {
        mask |= MASKS.MASK_CEILING;
      }

      const intersections = api.rayCastScreen(pointerPosition, mask);

      if (!isAllowedDrop(intersections, allowedPlacements)) {
        return;
      }

      detectShortCeiling(api.ceilingHeightAtPoint(intersections[0].point));

      api.loadModel(glbUrl, IMPORT_OPTIONS).then((object) => {
        object.position.copy(intersections[0].point);
        const pickedObject = intersections[0].object;

        if (customFurnitureScale) {
          const skuSize = sku.options.find((o) => o.type === 'SIZE');
          if (skuSize) {
            // Parse the string into vector3
            const splitSizes = skuSize.value
              .split(' x ')
              .map((dim) => dim.slice(0, -1)); // Chop off the 'W', 'H', 'L' characters

            if (splitSizes.length === 3)
              object.scale.set(
                Number(splitSizes[1]),
                Number(splitSizes[2]),
                Number(splitSizes[0]),
              );
          }

          object.bbox = api.getBoundingBox(object);
        }

        object.userData = {
          ...object.userData,
          furnitureId,
          sku: sku.skuNum,
          skuKey: sku.keyVal,
          map2dURL: pngUrl,
          isCube: customFurnitureScale,
          isStackable,
          allowStacking,
          nonPrintable,
          twoDLabel,
          twoDLabelColor,
        };

        object.name = sku.keyVal;

        if (!api.measurementParams.enable) {
          const dimensions = getDimension(api, object, sku, allowedPlacements);
          setFurnitureSize(dimensions);
          setSelectionUiPos(getModelToolBarCoords(api, object));

          selectedFurnitureRef.current = api.getRootObject(object);
        }

        if (pickedObject.name.toLowerCase().includes('piso')) {
          object.constraint = 'y';
        }

        if (pickedObject.name.toLowerCase().includes('ceiling')) {
          object.constraint = 'y';
          object.userData.isCeilingObject = true;
        }

        if (pickedObject.name.toLowerCase().includes('wall')) {
          const pickNormal = intersections[0].face.normal;
          object.userData.isWallObject = true;

          if (Math.abs(Math.abs(pickNormal.x) - 1) < 1e-5) {
            let angle = Math.sign(pickNormal.x) < 0 ? Math.PI : 0;
            object.rotateY(angle + Math.PI);
            object.constraint = 'x';
          }

          if (Math.abs(Math.abs(pickNormal.z) - 1) < 1e-5) {
            let angle = (-Math.PI / 2) * Math.sign(pickNormal.z);
            object.rotateY(angle + Math.PI);
            object.constraint = 'z';
          }
        }

        api.addObject(object);

        const selectionParams = {
          transformControlParams: {
            mode: TRANSLATE_MODE,
            constraint: object.constraint,
          },
        };
        if (!api.measurementParams.enable) {
          api.selectObject(object, selectionParams);
        }
        setTranslateMode(TRANSLATE_MODE);
      });

      batch(() => {
        dispatch(skuActions.setSkuOptions(skuOptions));
        dispatch(furnitureActions.setSelectedFurniture(furnitureId));
        dispatch(furnitureActions.setShowDimensions(false));
      });

      setLeftDimensionUIPo(TOOLBAR_POS);
      setTopDimensionUIPo(TOOLBAR_POS);
      setPasteButtonPos(TOOLBAR_POS);
      clearTouchTimeouts();
    },
    [
      dispatch,
      api,
      getDimension,
      isAllowedDrop,
      selectedFurnitureRef,
      setSelectionUiPos,
      getModelToolBarCoords,
      setFurnitureSize,
      detectShortCeiling,
    ],
  );

  const touchstart = (event) => {
    event.persist();
    touchDownTimeOut.current.push(
      setTimeout(() => {
        setPasteButtonPos({
          position: 'absolute',
          left: event.changedTouches[0].pageX - 50,
          top: event.changedTouches[0].pageY - 50,
          cursor: 'pointer',
        });
        const rightClickPointerPos = getPointerPosition(event);
        setRighClickPos(rightClickPointerPos);
      }, 2000),
    );
  };

  const clearTouchTimeouts = () => {
    touchDownTimeOut.current.forEach((t) => {
      clearTimeout(t);
    });
  };

  const onTouchMove = () => {
    // clear touch onlongpress timeout. Hide Paste Button
    setPasteButtonPos(TOOLBAR_POS);
    clearTouchTimeouts();
  };

  const touchend = () => {
    clearTouchTimeouts();
  };

  return (
    <>
      <div ref={drop}>
        <div
          className="room_designer_container"
          key="room_designer_container"
          ref={containerRef}
        >
          <div ref={statsRef} />
          <canvas
            id="evolutionCanvas"
            key="evolutionCanvas"
            className="evolutionCanvas"
            ref={canvasRef}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onDoubleClick={onDoubleClick}
            onPointerMove={onPointerMove}
            onTouchMove={onTouchMove}
            onContextMenu={onContextMenu}
            onTouchStart={touchstart}
            onTouchEnd={touchend}
          />
          {showDimension && (
            <>
              {!furnitureSize.square &&
                !furnitureSize.diameter &&
                furnitureSize.length !== '0' && (
                  <div>
                    <div
                      className="furniture-dimension"
                      style={leftDimensionUIPos}
                    >
                      {furnitureSize.length}
                    </div>
                  </div>
                )}
              {!furnitureSize.square &&
                !furnitureSize.diameter &&
                furnitureSize.width !== '0' && (
                  <div>
                    <div
                      className="furniture-dimension"
                      style={topDimensionUIPos}
                    >
                      <span>{furnitureSize.width}</span>
                    </div>
                  </div>
                )}
              {furnitureSize.square && (
                <div>
                  <div
                    className="furniture-dimension"
                    style={topDimensionUIPos}
                  >
                    <span>{furnitureSize.square}</span>
                  </div>
                </div>
              )}
              {furnitureSize.diameter && (
                <div>
                  <div
                    className="furniture-dimension"
                    style={topDimensionUIPos}
                  >
                    <span>{furnitureSize.diameter}</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {location.pathname === PAGE_HOME && (
        <>
          <ViewChanger
            key="ViewChanger"
            setSelectionUiPos={setSelectionUiPos}
            selectedFurnitureRef={selectedFurnitureRef}
            setLeftDimensionUIPo={setLeftDimensionUIPo}
            setTopDimensionUIPo={setTopDimensionUIPo}
            setRoomNamesEnabled={setRoomNamesEnabled}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            isTransition={isTransition}
            setIsTransition={setIsTransition}
            cameraState={sharedCameraState}
            setCameraState={setCameraState}
            setFovVal={setFovVal}
            selectedRotIndex={selectedRotIndex}
            setPasteButtonPos={setPasteButtonPos}
          />
          <DPad
            setSelectionUiPos={setSelectionUiPos}
            selectedFurnitureRef={selectedFurnitureRef}
          />
          <MapToolbar
            key="MapToolbar"
            canvasRef={canvasRef}
            setSelectionUiPos={setSelectionUiPos}
            selectedFurnitureRef={selectedFurnitureRef}
            setLeftDimensionUIPo={setLeftDimensionUIPo}
            setTopDimensionUIPo={setTopDimensionUIPo}
            containerRef={containerRef}
            roomNamesEnabled={roomNamesEnabled}
            setRoomNamesEnabled={setRoomNamesEnabled}
            fovVal={fovVal}
            setFovVal={setFovVal}
            selectedRotIndex={selectedRotIndex}
            setSelectedRotIndex={setSelectedRotIndex}
            setPasteButtonPos={setPasteButtonPos}
            selectedView={selectedView}
          />
          <ModelToolbar
            key="ModelToolbar"
            selectionUiPos={selectionUiPos}
            setSelectionUiPos={setSelectionUiPos}
            selectedFurnitureRef={selectedFurnitureRef}
            translateMode={translateMode}
            setTranslateMode={setTranslateMode}
            setLeftDimensionUIPo={setLeftDimensionUIPo}
            setTopDimensionUIPo={setTopDimensionUIPo}
            selectedView={selectedView}
            attachedLabel={attachedLabel}
            isShortCeiling={isShortCeiling}
          />
          {pasteButtonPos.left > 0 && (
            <div style={pasteButtonPos} className="iconContainer">
              <ToolbarTooltip title="PASTE" placement="right">
                <div className="icon">
                  <AssignmentIcon
                    className="copy"
                    fontSize="small"
                    key="doCopy"
                    onClick={pasteModel}
                  />
                </div>
              </ToolbarTooltip>
            </div>
          )}
          {showDebugFeatures && <MassModelLoader />}
        </>
      )}
    </>
  );
};

RoomDesigner.propTypes = {
  selectedFurnitureRef: PropTypes.object.isRequired,
  selectionUiPos: PropTypes.object.isRequired,
  setSelectionUiPos: PropTypes.func.isRequired,
  furnitureSize: PropTypes.object.isRequired,
  setFurnitureSize: PropTypes.func.isRequired,
};

export default RoomDesigner;
