import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import localforage from 'localforage';

import { actions as userActions } from '../../reducers/user';
import { actions as mapActions } from '../../reducers/maps';
import { actions as furnitureActions } from '../../reducers/furniture';

import { APIContext } from '../../context/APIContext';
import * as UserSelector from '../../selectors/users';
import NotAllowedOfflineModal from '../../scenes/Modals/notAllowedOffline';
import {
  MAX_POLOAR_ANGLE,
  OFFLINE_ERROR_MYACCOUNT,
  OFFLINE_ERROR_SAVE,
  OFFLINE_ERROR_SKU_LIST,
  PAGE_HOME,
  PAGE_MY_ACCOUNT,
  PAGE_SKULIST,
  TOOLBAR_POS,
  ASSET_STORE_NAME,
  IMPORT_OPTIONS,
} from '../../constants';

import logo from '../../assets/images/restoration-hardware-logo-large.jpg';

const Header = ({
  openSaveModal,
  openConfirmExitModal,
  selectedFurnitureRef,
  setSelectionUiPos,
  setSelectedView,
  selectedView,
  sharedCameraState,
  setCameraState,
  setSelectedRotIndex,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const apiContext = useContext(APIContext);
  const { api } = apiContext;
  const [showNotAllowedOffline, setShowNotAllowedOffline] = useState(false);
  const [offlineErrorText, setOfflineErrorText] = useState('');
  const currentUser = useSelector(UserSelector.getUser);

  const handleSaveClick = () => {
    if (navigator.onLine) {
      dispatch(mapActions.error(null));
      dispatch(mapActions.setSaveComplete(false));
      openSaveModal(true);
    } else {
      setOfflineErrorText(OFFLINE_ERROR_SAVE);
      setShowNotAllowedOffline(true);
    }
  };

  const resetPlan = () => {
    api.selectObject(null, {});
    dispatch(furnitureActions.setSelectedFurniture(''));
    selectedFurnitureRef.current = null;
    setSelectionUiPos(TOOLBAR_POS);
    if (selectedView === '2D' && sharedCameraState['orbit3d']) {
      api.setCameraMode('perspective');
      api.renderParams = { mode: '3d' };
      sharedCameraState['orbit3d'].cameraState.maxPolarAngle = MAX_POLOAR_ANGLE;
      api.transition(sharedCameraState['orbit3d'].cameraState, 1);
      setSelectedView('orbit');
    }
  };

  const handleSkuLinkClick = (e) => {
    if (!navigator.onLine) {
      setOfflineErrorText(OFFLINE_ERROR_SKU_LIST);
      setShowNotAllowedOffline(true);
      e.preventDefault();
    } else if (api) {
      resetPlan();
    }
  };

  const handleAccountClick = (e) => {
    if (!navigator.onLine) {
      setOfflineErrorText(OFFLINE_ERROR_MYACCOUNT);
      setShowNotAllowedOffline(true);
      e.preventDefault();
    } else if (api) {
      resetPlan();
    }
  };

  const handleLogoutClick = async () => {
    dispatch(userActions.logOutRequest());
    api.clearScene();
    setSelectionUiPos(TOOLBAR_POS);
    setSelectedView('orbit');
    setCameraState({});
    setSelectedRotIndex(2);
    const assetStore = localforage.createInstance({
      name: ASSET_STORE_NAME,
    });

    assetStore.getItem('glb-SHOWROOM').then((value) => {
      const url = URL.createObjectURL(value);
      api.loadModel(url, IMPORT_OPTIONS).then((object) => {
        object.userData.isMap = true;
        object.userData.skuKey = 'SHOWROOM';
        api.addObject(object);
        const maxDistance = api.sceneSphere.radius * 2;
        const radius = api.sceneSphere.radius;
        const target = api.sceneCentre;
        const position = { x: radius, y: radius, z: radius };
        const up = { x: 0, y: 1, z: 0 };

        api.transition(
          {
            type: 'orbit',
            position: position,
            up: up,
            target: target,
            maxDistance: maxDistance,
            maxPolarAngle: MAX_POLOAR_ANGLE,
          },
          1,
        );

        api.lightParams = { enabled: true, castShadow: true };
        api.miniMapParams = { enable: true };
      });
    });

    assetStore.getItem('glb-SHOWROOM_ROOF').then((value) => {
      const url = URL.createObjectURL(value);
      api.loadModel(url, IMPORT_OPTIONS).then((object) => {
        object.userData.isMap = true;
        object.userData.skuKey = 'SHOWROOM_ROOF';
        api.addObject(object);
        let ceilingObject = api.getObjectByNameOrId('ceilings');
        if (ceilingObject) {
          ceilingObject.traverse((obj) => {
            obj.visible = false;
          });
        }
      });
    });
  };

  const onPointerDown = (event) => {
    //This is to stop first person from navigating on click.
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      <header className="header" onPointerDown={onPointerDown}>
        <div
          className="header__logoWrapper"
          onClick={() => {
            if (location.pathname !== PAGE_HOME) {
              history.push(PAGE_HOME);
            }
          }}
        >
          <img className="header__logoWrapper--logo" src={logo} alt="" />
          <div className="header__logoWrapper--store">
            <h1>RH DALLAS</h1>
            <h3>3133 Knox St Dallas, TX 75205</h3>
          </div>
        </div>
        <div className="header__buttonWrapper">
          <Link
            to={PAGE_SKULIST}
            onClick={handleSkuLinkClick}
            className={`btn btn__link ${
              location.pathname === PAGE_SKULIST ? 'active' : ''
            }`}
          >
            SKU LIST
          </Link>
          {currentUser?.loggedIn && (
            <Link
              to={PAGE_MY_ACCOUNT}
              onClick={handleAccountClick}
              className={`btn btn__link ${
                location.pathname === PAGE_MY_ACCOUNT ? 'active' : ''
              }`}
            >
              MY ACCOUNT
            </Link>
          )}
          {location.pathname === PAGE_HOME && (
            <button className="btn" onClick={() => openConfirmExitModal(true)}>
              Exit Planner
            </button>
          )}
          {location.pathname === PAGE_HOME && (
            <button className="btn btnSave" onClick={handleSaveClick}>
              save
            </button>
          )}
          {location.pathname !== PAGE_HOME && (
            <Link to={PAGE_HOME} className="btn">
              back to planner
            </Link>
          )}
          {location.pathname === PAGE_MY_ACCOUNT && currentUser?.loggedIn && (
            <button className="btn btnExit" onClick={handleLogoutClick}>
              LOGOUT
            </button>
          )}
        </div>
      </header>
      {showNotAllowedOffline && (
        <NotAllowedOfflineModal
          showModal={setShowNotAllowedOffline}
          text={offlineErrorText}
        />
      )}
    </>
  );
};

Header.propTypes = {
  openSaveModal: PropTypes.func,
  openConfirmExitModal: PropTypes.func,
};

Header.defaultProps = {
  openSaveModal: (f) => f,
  openConfirmExitModal: (f) => f,
};

export default Header;
