const NAME = 'EDIT_SKU';

export const types = {
  REQUEST: `${NAME}/REQUEST`,
  REQUEST_SUCCESS: `${NAME}/REQUEST_SUCCESS`,
  COLORS_REQUEST: `${NAME}/COLORS_REQUEST`,
  SET_COLORS: `${NAME}/SET_COLORS`,
  SHAPES_REQUEST: `${NAME}/SHAPES_REQUEST`,
  SET_SHAPES: `${NAME}/SET_SHAPES`,
  UPLOAD_ICON_REQUEST: `${NAME}/UPLOAD_ICON_REQUEST`,
  UPLOAD_ICON: `${NAME}/UPLOAD_ICON`,
  ERROR: `${NAME}/ERROR`
};

export const actions = {
  request: (colorId = '', shapeId = '') => ({ type: types.REQUEST, payload: { colorId, shapeId } }),
  requestSuccess: ({ colors, shapes }) => ({ type: types.REQUEST_SUCCESS, payload: { colors, shapes } }),
  colorsRequest: ({ page = '' }) => ({ type: types.COLORS_REQUEST, payload: { page } }),
  setColors: user => ({ type: types.SET_COLORS, payload: user }),
  shapesRequest: () => ({ type: types.SHAPES_REQUEST }),
  setShapes: () => ({ type: types.SET_SHAPES }),
  uploadIconRequest: (id, file) => ({ type: types.UPLOAD_ICON_REQUEST, payload: { id, file } }),
  uploadIcon: ({ icon }) => ({ type: types.UPLOAD_ICON, payload: { icon } }),
  error: error => ({ type: types.ERROR, error })
};

const initialState = {
  colors: {
    byId: {},
    allIds: [],
    isFetching: false
  },
  shapes: {
    byId: {},
    allIds: [],
    isFetching: false
  },
  icon: '',
  isFetching: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.REQUEST_SUCCESS:
      return {
        ...state,
        icon: '',
        ...payload,
        isFetching: false
      };
    case types.COLORS_REQUEST:
      return {
        ...state,
        colors: {
          ...state.colors,
          isFetching: true
        }
      };
    case types.SET_COLORS:
      return {
        ...state,
        colors: {
          ...state.colors,
          ...payload,
          isFetching: false
        }
      };
    case types.SHAPES_REQUEST:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          isFetching: true
        }
      };
    case types.SET_SHAPES:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          ...payload,
          isFetching: false
        }
      };
    case types.UPLOAD_ICON_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.UPLOAD_ICON:
      return {
        ...state,
        ...payload,
        isFetching: false
      };
    case types.ERROR:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
