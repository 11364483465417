const NAME = 'SUBCATEGORIES';

export const types = {
  GET_REQUEST: `${NAME}/GET_REQUEST`,
  SET_SUB_CATEGORIES: `${NAME}/SET_CATEGORIES`,
  ERROR: `${NAME}/ERROR`,
};

export const actions = {
  getRequest: () => ({ type: types.GET_REQUEST }),
  setSubCategories: (categories) => ({
    type: types.SET_SUB_CATEGORIES,
    payload: categories,
  }),
  error: (error) => ({ type: types.ERROR, error }),
};

const initialState = {
  byId: {},
  allIds: [],
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_SUB_CATEGORIES:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case types.ERROR:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
