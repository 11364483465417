import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal';
import floppyDisk from '../../assets/images/icons/png/floppy-disk-light.png';
import syncArrowClock from '../../assets/images/icons/png/synchronize-arrow-clock-light.png';
import { SAVE_MENU, SAVE_AS_NEW, SAVE_OVERWRITE } from '../../constants';
import floorImage from '../../assets/images/rh-map.jpg';
import ConfirmOverwriteModal from './confirmOverwrite';
import ConcurrentSaveModal from './conccurentSave';

import ButtonLoader from '../../components/ButtonLoader';
import { actions as mapActions } from '../../reducers/maps';
import * as MapsSelector from '../../selectors/maps';
import { APIContext } from '../../context/APIContext';

const SaveModal = ({ showModal, setShowIntroModal }) => {
  const [saveOptions, setSaveOptions] = useState(SAVE_MENU);
  const mapsAllIds = useSelector(MapsSelector.getAllIds);
  const mapsById = useSelector(MapsSelector.getById);
  const apiContext = useContext(APIContext);
  const { api } = apiContext;

  const SaveHeader = () => {
    const dispatch = useDispatch();

    const handleBackClick = () => {
      setSaveOptions(SAVE_MENU);
      dispatch(mapActions.error({}));
    };

    const handleExitClick = () => {
      setShowIntroModal(false);
      showModal(false);
    };

    return (
      <>
        {saveOptions !== SAVE_MENU && (
          <button className="backBtn" onClick={handleBackClick}>
            back
          </button>
        )}
        <button onClick={() => handleExitClick()} className="btnClose" />
      </>
    );
  };

  const SAVE_CONTENT = {
    [SAVE_MENU]: (
      <>
        <h2>save this plan</h2>
        <h3>gallery planner</h3>
      </>
    ),
    [SAVE_AS_NEW]: <h2>save as new</h2>,
    [SAVE_OVERWRITE]: (
      <>
        <h2>overwrite previous plan</h2>
        <h3>select a plan to save over</h3>
      </>
    ),
  };

  const SaveContent = () => <>{SAVE_CONTENT[saveOptions]}</>;

  const SaveButtons = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(mapActions.setSaveComplete(false));
    }, [dispatch]);

    return (
      <>
        <button onClick={() => setSaveOptions(SAVE_AS_NEW)}>
          <img src={floppyDisk} alt="" />
          <span>save as new</span>
        </button>

        <button onClick={() => setSaveOptions(SAVE_OVERWRITE)}>
          <img src={syncArrowClock} alt="" />
          <span>overwrite</span>
          <span>previous plan</span>
        </button>
      </>
    );
  };

  const SavePlanName = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const error = useSelector(MapsSelector.getError);
    const loading = useSelector(MapsSelector.getLoading);
    const saveComplete = useSelector(MapsSelector.getSaveComplete);
    const planNamRef = useRef('');

    const savePlan = async () => {
      if (!planNamRef.current.value) {
        dispatch(mapActions.error({ message: 'Plan Name is Required' }));
      } else {
        let start = performance.now();
        const currentScene = await api.exportScene();
        console.log(`Export Scene: ${performance.now() - start}`);
        start = performance.now();
        //const snapshot = api.getSnapShot2D(PNG_SNAP_QUALITY);

        console.log(`2D Snapshot: ${performance.now() - start}`);
        const mapToSave = {
          name: planNamRef.current.value,
          city: 'Dallas',
          scene: currentScene,
        };

        dispatch(mapActions.postRequest(mapToSave));
      }
    };

    useEffect(() => {
      if (saveComplete) {
        showModal(false);
        addToast('Saved Successfully', { appearance: 'success' });
        dispatch(mapActions.setSaveComplete(false));
        dispatch(mapActions.error({}));
        setShowIntroModal(false);
      }
    }, [dispatch, addToast, saveComplete]);

    return (
      <div className="form">
        <input
          type="text"
          placeholder="enter plan name"
          className="name"
          ref={planNamRef}
          disabled={loading}
        />
        <div className="error">{error?.message}</div>
        <ButtonLoader
          className="btnSave"
          type="submit"
          onClick={savePlan}
          text="save"
          loading={loading}
        />
      </div>
    );
  };

  const SavePlans = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [showConfirm, setShowConfirm] = useState(false);
    const [showConcurrent, setShowConcurrent] = useState(false);

    const saveComplete = useSelector(MapsSelector.getSaveComplete);
    const error = useSelector(MapsSelector.getError);
    const concurrentWrite = useSelector(MapsSelector.getConcurrentWrite);

    const startOverwrite = (planId) => {
      const mapToOverwrite = mapsById[planId];
      dispatch(mapActions.setMapToOverwrite(mapToOverwrite));
      setShowConfirm(true);
    };

    useEffect(() => {
      if (saveComplete && !concurrentWrite) {
        setShowConfirm(false);
        showModal(false);
        addToast('Save Successful', {
          appearance: 'success',
        });
        dispatch(mapActions.setSaveComplete(false));
        setShowIntroModal(false);
        dispatch(mapActions.error({}));
      }
    }, [saveComplete, dispatch, addToast, concurrentWrite]);

    useEffect(() => {
      if (error && error.length > 0) {
        addToast('Error saving map. Please try again.', {
          appearance: 'error',
        });
        dispatch(mapActions.error(null));
      }
    }, [dispatch, addToast, error]);

    useEffect(() => {
      if (concurrentWrite) {
        setShowConcurrent(true);
      }
      setShowConfirm(false);
      dispatch(mapActions.setSaveComplete(false));
    }, [concurrentWrite, dispatch]);

    return (
      <div className="wrapper">
        <div className="content">
          {mapsAllIds.map((id) => {
            const { name, city, creator, createdOn, updatedAt } = mapsById[id];
            return (
              <div
                key={id}
                className="planItem planItemNotLoading"
                onClick={() => startOverwrite(id)}
              >
                <div className="thumbnail">
                  <img
                    className="floorPlanImg"
                    src={floorImage}
                    alt=""
                    title=""
                  />
                </div>
                <div className="info">
                  <div className="infoWrapper">
                    <span className="nameItem">{name}</span>
                    <span className="localItem">{city}</span>
                    <span className="createdByItem">
                      created by <small>{creator}</small>
                    </span>
                    <span className="createdOnItem">
                      created on{' '}
                      <small>{format(new Date(createdOn), 'MM/dd/yy')}</small>
                    </span>
                    <span className="lastUpdatedItem">
                      last updated{' '}
                      <small>{format(new Date(updatedAt), 'MM/dd/yy')}</small>
                    </span>
                    {/* TEMP Remove editors for POC.

                  <span className="editorsItem">
                    <button className="editBtn" />
                    <strong>editors</strong>
                    <p>{editors.join(', ')}</p>
                  </span>
                  */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {showConfirm && (
          <ConfirmOverwriteModal
            setShowConfirm={setShowConfirm}
            showModal={showModal}
          />
        )}
        {showConcurrent && (
          <ConcurrentSaveModal showModal={setShowConcurrent} />
        )}
      </div>
    );
  };

  const CONTENT_MODAL = {
    [SAVE_MENU]: (
      <Modal
        header={<SaveHeader />}
        content={<SaveContent />}
        buttons={<SaveButtons />}
        wrapperStyles="savingDialog"
      />
    ),
    [SAVE_AS_NEW]: (
      <Modal
        header={<SaveHeader />}
        content={<SaveContent />}
        planName={<SavePlanName />}
        wrapperStyles="savingDialog"
        backButton
      />
    ),
    [SAVE_OVERWRITE]: (
      <Modal
        header={<SaveHeader />}
        content={<SaveContent />}
        plans={<SavePlans />}
        wrapperStyles="savingDialog"
        backButton
      />
    ),
  };

  return <>{CONTENT_MODAL[saveOptions]}</>;
};

SaveModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  setShowIntroModal: PropTypes.func.isRequired,
};

export default SaveModal;
