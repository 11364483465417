const NAME = 'USER';

export const types = {
  LOG_IN_REQUEST: `${NAME}/LOG_IN_REQUEST`,
  PASSWORD_REQUEST: `${NAME}/PASSWORD_REQUEST`,
  SET_USER: `${NAME}/SET_USER`,
  SET_REQUEST_CODE: `${NAME}/SET_REQUEST_CODE`,
  LOG_OUT_REQUEST: `${NAME}/LOG_OUT_REQUEST`,
  LOG_OUT: `${NAME}/LOG_OUT`,
  ERROR: `${NAME}/ERROR`,
  FORGOT_PASSWORD_REQUEST: `${NAME}/FORGOT_PASSWORD_REQUEST`,
  RESET_PASSWORD_REQUEST: `${NAME}/RESET_PASSWORD_REQUEST`,
  SET_SENT_CODE: `${NAME}/SET_SENT_CODE`,
  SET_FORGOT_PASSWORD_EMAIL: `${NAME}/SET_FORGOT_PASSWORD_EMAIL`,
  UPDATE_NAME_REQUEST: `${NAME}/UPDATE_NAME_REQUEST`,
  CHANGE_PASSWORD_REQUEST: `${NAME}/CHANGE_PASSWORD_REQUEST`,
  NAME_UPDATE_COMPLETE: `${NAME}/NAME_UPDATE_COMPLETE`,
  PASSWORD_UPDATE_COMPLETE: `${NAME}/PASSWORD_UPDATE_COMPLETE`,
  REFRESH_AUTH_REQUEST: `${NAME}/REFRESH_AUTH_REQUEST`,
};

export const actions = {
  loginRequest: ({ email, password }) => ({
    type: types.LOG_IN_REQUEST,
    payload: { email, password },
  }),
  setPasswordRequest: ({ currentUser, password, name }) => ({
    type: types.PASSWORD_REQUEST,
    payload: { currentUser, password, name },
  }),
  fogotPasswordRequest: (email) => ({
    type: types.FORGOT_PASSWORD_REQUEST,
    payload: { email },
  }),
  resetPasswordRequest: (email, code, password) => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload: { email, code, password },
  }),
  changePasswordRequest: (oldPassword, password) => ({
    type: types.CHANGE_PASSWORD_REQUEST,
    payload: { oldPassword, password },
  }),
  updateNameRequest: (name) => ({
    type: types.UPDATE_NAME_REQUEST,
    payload: { name },
  }),
  setUser: (user) => ({ type: types.SET_USER, payload: user }),
  setRequestCode: (showRequestCode) => ({
    type: types.SET_REQUEST_CODE,
    payload: showRequestCode,
  }),
  setChangingPass: (showChangePassword) => ({
    type: types.SET_SENT_CODE,
    payload: showChangePassword,
  }),
  setForgotPassEmail: (forgotPassEmail) => ({
    type: types.SET_FORGOT_PASSWORD_EMAIL,
    payload: forgotPassEmail,
  }),
  setNameUpdateComplete: (nameUpdateComplete) => ({
    type: types.NAME_UPDATE_COMPLETE,
    payload: nameUpdateComplete,
  }),
  setPasswordUpdateComplete: (passwordUpdateComplete) => ({
    type: types.PASSWORD_UPDATE_COMPLETE,
    payload: passwordUpdateComplete,
  }),

  logOutRequest: () => ({ type: types.LOG_OUT_REQUEST }),
  refreshAuthRequest: () => ({ type: types.REFRESH_AUTH_REQUEST }),
  logOut: () => ({ type: types.LOG_OUT }),
  setError: (error) => ({ type: types.ERROR, payload: error }),
};

const initialState = {
  user: { currentUser: null, loggedIn: false },
  error: {},
  forgotPassEmail: '',
  loggedIn: false,
  isFetching: false,
  showRequestCode: false,
  showChangePassword: false,
  passwordUpdateComplete: false,
  nameUpdateComplete: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOG_IN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_USER:
      return {
        ...state,
        user: payload,
        isFetching: false,
      };
    case types.PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.LOG_OUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.REFRESH_AUTH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.LOG_OUT:
      return {
        ...initialState,
        isFetching: false,
      };
    case types.ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    case types.NAME_UPDATE_COMPLETE:
      return {
        ...state,
        nameUpdateComplete: payload,
      };
    case types.PASSWORD_UPDATE_COMPLETE:
      return {
        ...state,
        passwordUpdateComplete: payload,
      };
    case types.SET_REQUEST_CODE:
      return {
        ...state,
        showRequestCode: payload,
      };
    case types.SET_SENT_CODE:
      return {
        ...state,
        showChangePassword: payload,
      };
    case types.SET_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgotPassEmail: payload,
        isFetching: false,
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.UPDATE_NAME_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};
