import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { actions as furnitureActions } from '../../reducers/furniture';

import * as FurnitureTypeSelectors from '../../selectors/furnitureTypes';
import * as CategorySelectors from '../../selectors/categories';
import * as SubCategorySelectors from '../../selectors/subCategories';
import * as FurnitureSelectors from '../../selectors/furniture';

const MenuCategory = ({ openedSubMenu, setOpenedSubMenu }) => {
  const dispatch = useDispatch();
  const furnitureTypesById = useSelector(FurnitureTypeSelectors.getById);
  const categoriesSorted = useSelector(CategorySelectors.getSorted);
  const categoriesById = useSelector(CategorySelectors.getById);
  const subCategoriesById = useSelector(SubCategorySelectors.getById);
  const selectedFurnitureType = useSelector(FurnitureSelectors.getSelectedType);

  const handleMenuClick = (id) => {
    setOpenedSubMenu({
      ...openedSubMenu,
      [id]: !openedSubMenu[id],
    });
  };

  const handleSubMenuClick = (id, idSub) => {
    dispatch(furnitureActions.setSelectedCategory(id));
    dispatch(furnitureActions.setSelectedSubCategory(idSub));
  };

  const subSubCats = (catId) => {
    const subCategories = categoriesById[catId].subCategories.map((idSub) => {
      return subCategoriesById[idSub];
    });

    const sorted = Object.values(subCategories).sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return sorted.map((subCat) => {
      return (
        <span
          key={subCat.id}
          id={subCat.id}
          onClick={() => handleSubMenuClick(catId, subCat.id)}
        >
          {subCat.name}
        </span>
      );
    });
  };

  return (
    <>
      <div className="sidebar__subMenuWrapper--search">
        <div className="result">
          <span>{furnitureTypesById[selectedFurnitureType].name}</span>
        </div>
      </div>
      <div className="sidebar__subMenuWrapper--items">
        {categoriesSorted.map((cat) => {
          const { name } = cat;
          return (
            <div key={cat.id} id={cat.id} className="item-wrapper">
              <span
                className={`item subItem ${
                  openedSubMenu[cat.id] ? 'active' : ''
                }`}
                onClick={() => handleMenuClick(cat.id)}
              >
                {name}
              </span>
              <div className={`options ${openedSubMenu[cat.id] ? 'show' : ''}`}>
                {subSubCats(cat.id)}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

MenuCategory.propTypes = {
  openedSubMenu: PropTypes.shape({
    1: PropTypes.bool,
    2: PropTypes.bool,
    3: PropTypes.bool,
    4: PropTypes.bool,
    5: PropTypes.bool,
    6: PropTypes.bool,
    7: PropTypes.bool,
    8: PropTypes.bool,
    9: PropTypes.bool,
  }).isRequired,
  setOpenedSubMenu: PropTypes.func.isRequired,
};

export default MenuCategory;
